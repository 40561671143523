import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { Link } from 'components/link'
import { format, product } from 'utils'

const Product = (props) => {
  const { doc = {} } = props
  const { promotion_id, market_id, name, value, status, start_at, finish_at } =
    doc

  const getText = (label, text) => {
    return (
      <Text>
        <span>{label}</span>
        {text}
      </Text>
    )
  }

  const date = product.getDate(start_at, finish_at)
  const money = format.toDigi(value)
  const css = status !== 'active' ? 'inactive' : ''
  const text = name.length > 80 ? name.substring(0, 80) + '...' : name
  return (
    <PageView className={css}>
      {getText('รายละเอียดโปรโมชั่น:', text)}
      {getText('วันที่จัดโปรโมชั่น:', date)}
      <Row>
        <Col span={18}>{getText('จำนวนส่วนลด:', money + ' บาท')}</Col>
        <Col span={6}>
          <Link to={`/market/${market_id}/promotion/${promotion_id}/edit`}>
            <Detail>รายละเอียด</Detail>
          </Link>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  &.inactive {
    background-color: ${(p) => p.theme.color_level.grey.light};
  }
`

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};

  span {
    font-weight: bold;
    margin-right: 8px;
  }
`

const Detail = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  text-align: right;
  text-decoration: underline;
`

export default Product
