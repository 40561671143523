import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, error, firebase, line } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  email: '',
  name: '',
  surname: '',
}

const localLineId = 'Ua500db59cc5d6a4195b49dc87acbba66'
let state
let checkingLine = false
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      user: cloneDeep(original),
    })

    state = this
    http.setMember(this)
  }

  reset() {
    runInAction(() => {
      state.user = cloneDeep(original)
    })
  }

  async getCurrentUser() {
    return await firebase.onAuthStateChanged()
  }

  async checkLoginUser() {
    try {
      const user = await this.getCurrentUser()

      if (user) {
        await this.getUserProfile({ user })
      }
    } catch (e) {
      console.log('check user:', e.message)
    }
  }

  async getUserProfile(params = {}) {
    const { user } = params

    const token = await user.getIdToken()
    await http.setToken(token)

    const url = `${config.api}/v1/user/profile`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.user = data
    })
  }

  async logout() {
    await firebase.logout()
    runInAction(() => {
      state.user = {}
    })
  }

  async login(id) {
    if (checkingLine) {
      return {}
    }
    checkingLine = true
    error.isNull(id, 'เกิดข้อผิดพลาด account id ของคุณ')
    const url = `${config.api}/v1/public/account/login-line/${id}`

    const json = {}
    const res = await http.post(url, { json })
    const { user, token } = res.body || {}
    error.isNull(user, 'เกิดข้อผิดพลาด account ของคุณ')
    error.isNull(token, 'เกิดข้อผิดพลาด account ของคุณ')

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    checkingLine = false
    runInAction(() => {
      state.user = user
    })

    return user
  }

  async updateProfile(json = {}) {
    const url = `${config.api}/v1/user/profile`

    const res = await http.put(url, { json })
    const user = res.body || {}

    runInAction(() => {
      state.user = user
    })
  }

  setProfile(doc) {
    runInAction(() => {
      state.user = doc
    })
  }

  async lineLogin({ redirect, type, option, navigate } = {}) {
    const { origin = '' } = window.location
    await firebase.logout()

    if (origin === 'http://localhost:2240') {
      const user = await this.login(localLineId)
      const name = user.name || ''
      if (name === '') {
        navigate('/register')
      } else {
        navigate(redirect || '/dashboard')
      }
    } else {
      line.login({ redirect, type, option })
    }
  }

  async checkLineProfile() {
    const resp = await line.getProfile()
    const { profile = {}, setting = {} } = resp || {}
    console.log('line profile', profile)
    console.log('setting', setting)
    const user = await this.login(profile.userId)
    return { user, setting }
  }
}

export default new Member()
