import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Checker } from 'components/input'

import Label from './Label'
import ContentView from './ContentView'
import Line from './Line'

const BookingType = (props) => {
  const { doc = {}, onChange } = props

  const onCh = (isCheck, name) => {
    doc[name] = isCheck ? 'yes' : 'no'
    onChange(doc)
  }

  const content = list.map((it, i) => {
    const { name, value } = it
    return (
      <Col key={i} lg={12} md={12} xs={24}>
        <Line>
          <Checker
            label={name}
            name={value}
            value={doc[value] === 'yes'}
            onChange={onCh}
          />
        </Line>
      </Col>
    )
  })
  return (
    <PageView>
      <Label text="ประเภทการจอง" />
      <ContentView>
        <Row>{content}</Row>
      </ContentView>
    </PageView>
  )
}

const list = [
  { name: 'รายวัน', value: 'booking_daily' },
  { name: 'รายเดือน', value: 'booking_monthly' },
  { name: 'บูธอีเวนท์', value: 'booking_round' },
]

const PageView = styled.div`
  width: 100%;
`

export default BookingType
