import styled from 'styled-components'

const Line = (props) => {
  const { children } = props
  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  padding-bottom: 16px;
`

export default Line
