import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { ErrorDialog, ConfirmDialog } from 'dialog'
import { CloneUnitDrawer } from 'drawer'
import { Copy } from 'icon'
import { SaveBtn, Row, Col } from 'components/display'
import { Input, BigInput } from 'components/input'
import { validator, message } from 'utils'

import FloorImage from './FloorImage'
import Label from './Label'
import rules from './rules'

const Header = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [clone, setClone] = useState(undefined)
  const [err, setErr] = useState('')
  const [validate, setValidate] = useState({})

  const navigate = useNavigate()

  const { isReady, onEditPosition } = props

  const { floorplan = {} } = props.floorplan.toJS()
  const { unit = {} } = props.unit.toJS()

  const { market_id, floorplan_id } = floorplan
  const { name, unit_id } = unit

  const onSave = async () => {
    try {
      const checker = validator.process(rules, unit)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setLoading(true)
      const res =
        (await props.unit.updateUnit(
          { market_id, floorplan_id, unit_id },
          unit
        )) || {}
      message.success()
      if (res.unit_id) {
        navigate(`/market/${market_id}/unit/${floorplan_id}/${res.unit_id}`)
      }
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onCloneUnit = async (json = {}) => {
    try {
      setSaving(true)
      const doc = await props.unit.cloneUnit({ unit_id }, json)
      if (doc) {
        const link = `/market/${market_id}/unit/${floorplan_id}/${doc.unit_id}`
        navigate(link)
        return
      }

      onCloseClone()
    } catch (e) {
      setErr(e.message)
    }
    setSaving(false)
  }

  const onDelete = async () => {
    try {
      setDeleting(true)

      await props.unit.deleteUnit({ unit_id })
      const link = `/market/${market_id}/floor-plan/${floorplan_id}`
      navigate(link)
    } catch (e) {
      setErr(e.message)
      setDeleting(false)
    }
  }

  const onCloseError = () => setErr('')
  const onCloseClone = () => setClone(undefined)
  const onClone = () => setClone(unit)
  const onDeleteConfirm = () => setDeleteConfirm(true)
  const onCloseDeleteConfirm = () => setDeleteConfirm(false)

  const onChange = (val, n) => {
    unit[n] = val
    props.unit.setUnit(unit)
  }

  const copy = unit_id ? (
    <Button onClick={onClone}>
      <Copy />
    </Button>
  ) : undefined

  return (
    <PageView>
      <Row align="middle" style={{ paddingBottom: '8px' }}>
        <Col span={12} position="left">
          <Label text="ข้อมูลล็อก" padding={false} />
        </Col>
        <Col span={12} position="right">
          {copy}
        </Col>
      </Row>

      <Row>
        <Col span={12} position="left">
          <FloorImage
            visible={isReady}
            img_url={floorplan.img_url}
            doc={unit}
          />
        </Col>
        <Col span={12} position="right">
          <BigInput
            label="หมายเลขล็อก"
            name="name"
            value={name}
            limit={7}
            onChange={onChange}
            invalid={validate['name']}
          />
        </Col>
      </Row>
      <EditBtn onClick={onEditPosition}>แก้ไขตำแหน่ง</EditBtn>
      <Label text="ขนาดพื้นที่" />
      <Row>
        <Col span={12} position="left">
          <Input
            label="กว้าง (ม.)"
            name="be_wide"
            value={unit.be_wide}
            onChange={onChange}
            invalid={validate['be_wide']}
            isNumber={true}
          />
        </Col>
        <Col span={12} position="right">
          <Input
            label="ลึก (ม.)"
            name="be_long"
            value={unit.be_long}
            onChange={onChange}
            invalid={validate['be_long']}
            isNumber={true}
          />
        </Col>
      </Row>

      <Input
        label="ความสูงจำกัด (ม.) (ถ้ามี)"
        name="be_tall"
        value={unit.be_tall}
        onChange={onChange}
        isNumber={true}
      />

      <SaveBtn
        loading={loading}
        onClick={onSave}
        deleting={deleting}
        onDelete={unit_id ? onDeleteConfirm : undefined}
        text={{ delete: 'ลบล็อกนี้' }}
      />
      <ErrorDialog error={err} onClose={onCloseError} />

      <CloneUnitDrawer
        loading={saving}
        visible={clone !== undefined}
        doc={clone}
        onClose={onCloseClone}
        onConfirm={onCloneUnit}
      />

      <ConfirmDialog
        visible={deleteConfirm}
        title="ยืนยันการลบล็อก"
        message="คุณต้องการที่จะลบล็อก ใช่หรือไม่?"
        onClose={onCloseDeleteConfirm}
        onConfirm={onDelete}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 10px;
`

const Button = styled.span`
  float: right;
  padding-left: 5px;
  cursor: pointer;
`

const EditBtn = styled.span`
  width: 100%;
  font-size: 14px;
  margin-bottom: 24px;
  color: ${(p) => p.theme.color_level.grey.high};
  text-decoration: underline;
  cursor: pointer;
`

export default inject('floorplan', 'unit')(observer(Header))
