import styled from 'styled-components'

const Space = (props) => {
  return <View />
}

const View = styled.div`
  padding-top: 8px;
`

export default Space
