import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { Input } from 'components/input'
import { format } from 'utils'

const per_lock = 5
const per_admin = 50

const Calculator = (props) => {
  const [num, setNum] = useState(50)
  const [admin, setAdmin] = useState(2)

  const onNum = (val) => setNum(val)
  const onAdmin = (val) => setAdmin(val)

  const getCost = () => {
    const c = num > 50 ? num - 50 : 0
    const cost = num <= 10 ? 0 : c * per_lock + 500

    const admin_cost = admin > 2 ? (admin - 2) * per_admin : 0

    const total = cost + admin_cost
    return { cost, admin: admin_cost, total }
  }

  const getRow = (label, value) => {
    const val = value === 0 ? 'ฟรี' : format.toDigi(value) + ' บาท'
    return (
      <Row align="middle">
        <Col {...colSummary} position="left">
          <Text>{label}</Text>
        </Col>
        <Col {...colSummary} position="right">
          <Value>{val}</Value>
        </Col>
      </Row>
    )
  }

  const summary = getCost()
  return (
    <PageView>
      <Label>คำนวนค่าบริการของท่าน</Label>
      <Content>
        <Row>
          <Col {...col} position="center-responsive">
            <Input
              label="จำนวนล็อก"
              value={num}
              onChange={onNum}
              isNumber={true}
            />
          </Col>
          <Col {...col} position="right-responsive">
            <Input
              label="จำนวนผู้ดูแลระบบ"
              value={admin}
              onChange={onAdmin}
              isNumber={true}
            />
          </Col>
        </Row>
      </Content>

      <Label>สรุปค่าใช้จ่าย</Label>
      {getRow(`ค่าบริการล็อก ${num} ล็อก`, summary.cost)}
      {getRow(`ค่าบริการผู้ดูแลระบบ ${admin} คน`, summary.admin)}
      {getRow('รวมทั้งหมดต่อเดือน', summary.total)}
    </PageView>
  )
}

const col = {
  lg: 12,
  md: 12,
  xs: 24,
}

const colSummary = {
  lg: 12,
  md: 12,
  xs: 12,
}

const PageView = styled.div`
  width: 100%;
`

const Label = styled.div`
  font-weight: bold;
  margin-top: 20px;
`

const Content = styled.div`
  margin: 16px 0px;
`

const Text = styled.div`
  font-size: 16px;
`

const Value = styled.div`
  text-align: right;
  font-weight: bold;
`

export default Calculator
