import styled from 'styled-components'
import { Row } from 'antd'

import { Edit, DeleteV2 } from 'icon'
import { Col } from 'components/display'
import { market, format } from 'utils'

const Price = (props) => {
  const { doc = {}, onEdit, onDelete } = props

  const bookingType = market.getBookingType(doc)
  return (
    <Row align="middle">
      <Col span={14} position="left">
        <PriceValue>{format.toDigi(doc.price)}</PriceValue>
        <TextPrice>{bookingType}</TextPrice>
      </Col>
      <Col span={10} position="right">
        <Right>
          <span onClick={onEdit}>
            <Edit />
          </span>
          <Btn onClick={onDelete}>
            <DeleteV2 />
          </Btn>
        </Right>
      </Col>
    </Row>
  )
}

const Right = styled.div`
  text-align: right;

  span {
    cursor: pointer;
  }
`

const PriceValue = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 1.2;
`

const TextPrice = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color.red};
  line-height: 1.2;
`

const Btn = styled.span`
  margin-left: 30px;
`

export default Price
