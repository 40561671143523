import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Checker, Input, SelectList } from 'components/input'

import Label from './Label'
import ContentView from './ContentView'
import Line from './Line'

const Place = (props) => {
  const {
    doc = {},
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props

  const nearby = doc.nearby || ''
  const shopper = doc.shopper || ''
  const nearby_list = doc.nearby_list || []

  const onToggle = (isCheck, name, i) => {
    if (isCheck) {
      nearby_list.push(name)
    } else {
      const index = nearby_list.findIndex((txt) => txt === name)
      if (index !== -1) {
        nearby_list.splice(index, 1)
      }
    }

    doc.nearby_list = nearby_list
    onChange(doc)
  }

  const onOther = (isCheck) => {
    doc.nearby_other = isCheck ? 'yes' : 'no'
    onChange(doc)
  }

  const onCh = (val, name) => {
    doc[name] = val
    onChange(doc)

    delete validate[name]

    onValidate(validate)
  }

  const getShopperMenu = () => {
    if (shopper === '') {
      return shopper_list
    }

    const menu = shopper_list.map((it) => it)
    menu.push({ name: shopper, value: shopper })
    return menu
  }

  const content = list.map((it, i) => {
    const { name, value } = it
    const isCheck = nearby_list.find((txt) => txt === value)
    return (
      <Col key={i} lg={8} md={12} xs={24}>
        <Line>
          <Checker
            label={name}
            name={value}
            value={isCheck}
            onChange={onToggle}
          />
        </Line>
      </Col>
    )
  })

  const isCheck = doc.nearby_other === 'yes'
  const index = shopper_list.findIndex((it) => it.value === doc.shopper)
  const shopper_menu = index === -1 ? getShopperMenu() : shopper_list
  return (
    <PageView>
      <Label text="แหล่งใกล้เคียง" />
      <ContentView>
        <Row>
          {content}
          <Col lg={8} md={12} xs={24}>
            <OtherLine>
              <Checker value={isCheck} onChange={onOther} />
              <Input
                label="อื่นๆ โปรดระบุ"
                name="nearby"
                value={nearby}
                disabled={!isCheck}
                onChange={onCh}
              />
            </OtherLine>
          </Col>
        </Row>

        <Line>
          <SelectList
            placeholder="จำนวนคนเดิน / วัน"
            menu={shopper_menu}
            name="shopper"
            value={shopper === '' ? undefined : shopper}
            onChange={onCh}
            invalid={validate['shopper']}
          />
        </Line>

        <Line>
          <Input
            label="จุดเด่น / กลุ่มลูกค้า"
            name="interesting"
            value={doc.interesting}
            onChange={onCh}
            invalid={validate['interesting']}
          />
        </Line>
      </ContentView>
    </PageView>
  )
}

const list = [
  'ออฟฟิศ',
  'ชุมชน ที่พักอาศัย',
  'แหล่งท่องเที่ยว',
  'มหาวิทยาลัย',
  'โรงงาน',
].map((name) => {
  return {
    name,
    value: name,
  }
})

const shopper_list = [
  'ไม่ระบุ',
  'น้อยกว่า 500',
  '500-1,000',
  '1,000-3,000',
  '3,000-5,000',
  'มากกว่า 5,000',
].map((name) => {
  return {
    name,
    value: name,
  }
})

const PageView = styled.div`
  width: 100%;
`

const OtherLine = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  column-gap: 16px;
`

export default Place
