import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import { BackHeader, Content } from 'components/display'
import OrderInfo from 'pages/booking/info'
import NoteDrawer from 'pages/booking/note'
import ReasonDrawer from 'pages/booking/reason'

import Button from './Button'

const OrderPage = (props) => {
  const [loading, setLoading] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [approving, setApproving] = useState(false)
  const [note, setNote] = useState(false)
  const [err, setErr] = useState('')
  const [reasonType, setReasonType] = useState('')

  const { market_id, code } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { doc = {} } = props.order.toJS()
  const { setting = {} } = props.marketSetting.toJS()

  const onLoad = useCallback(
    async (c) => {
      try {
        setLoading(true)
        const booking = await props.order.getOrderInfo(c)
        const { status, market_id } = booking || {}

        if (status === 'completed') {
          await props.marketSetting.getSetting(market_id)
        }
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.order]
  )

  useEffect(() => {
    onLoad(code)
  }, [onLoad, code])

  const onCloseError = () => setErr('')
  const onCloseReason = () => setReasonType('')
  const onBack = () => {
    const { source, status } = queryString.parse(location.search)
    if (source) {
      navigate(source)
    } else {
      if (market_id) {
        const s = status ? `?status=${status}` : ''
        navigate(`/market/${market_id}/booking${s}`)
      } else {
        navigate('/booking')
      }
    }
  }

  const onApprove = () => setNote(true)
  const onCloseNote = () => setNote(false)
  const onOffer = () => {
    const link = market_id
      ? `/market/${doc.market_id}/booking/${code}/offer`
      : `/booking/${doc.market_id}/${code}/offer?source=/booking/${code}`
    navigate(link)
  }
  const onReject = () => setReasonType('reject')

  const onRejectOrder = async (text) => {
    try {
      setReasonType('')
      setRejecting(true)
      await props.order.rejectOrder(doc.order_id, text)
    } catch (e) {
      setErr(e.message)
    }
    setRejecting(false)
  }

  const onApproveOrder = async (text) => {
    try {
      onCloseNote()
      setApproving(true)
      await props.order.approveOrder(doc.order_id, text)
    } catch (e) {
      setErr(e.message)
    }
    setApproving(false)
  }

  const onSaveReason = (text) => onRejectOrder(text)

  const isOwner = setting.bank_type === 'owner'
  const { payment_at, status } = doc
  const isReceive = isOwner && ['completed', 'cancelled'].includes(status)
  return (
    <PageView>
      <BackHeader onClick={onBack} title="รายละเอียดการจอง" />
      <Content loading={loading} isHeader={true}>
        <OrderInfo isReceive={isReceive} onOffer={onOffer} />
        <Button
          status={doc.status}
          rejecting={rejecting}
          approving={approving}
          payment_at={payment_at}
          onReject={onReject}
          onApprove={onApprove}
        />
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
      <NoteDrawer
        visible={note}
        onClose={onCloseNote}
        onSave={onApproveOrder}
      />
      <ReasonDrawer
        visible={reasonType !== ''}
        option={reasonType}
        onClose={onCloseReason}
        onSave={onSaveReason}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 60px;
`

export default inject('order', 'marketSetting')(observer(OrderPage))
