import styled from 'styled-components'

import { Link } from 'components/link'
import { OpenTag } from 'components/market/components'
import { Next } from 'icon'
import { helper } from 'utils'

import BookingType from './BookingType'

const Card = (props) => {
  const { item } = props
  const { market_id, name, img_list } = item
  const url = `/market/${market_id}?source=/market`
  return (
    <Link to={url}>
      <PageView>
        <Image src={helper.getUrl(img_list)} />
        <Info>
          <Title>{name}</Title>
          <OpenTag item={item} />
          <Space />
          <BookingType item={item} />
        </Info>
        <Next />
      </PageView>
    </Link>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: #ffffff;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Image = styled.img`
  width: 120px;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
`

const Info = styled.div`
  flex: auto;
  padding: 0px 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const Space = styled.div`
  padding-top: 8px;
`

export default Card
