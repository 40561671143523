import React from 'react'
import styled from 'styled-components'

const OpenTag = (props) => {
  const getDayName = (name) => {
    switch (name) {
      case 'monday':
        return <Day key={name}>จ</Day>
      case 'tuesday':
        return <Day key={name}>อ</Day>
      case 'wednesday':
        return <Day key={name}>พ</Day>
      case 'thursday':
        return <Day key={name}>พฤ</Day>
      case 'friday':
        return <Day key={name}>ศ</Day>
      case 'saturday':
        return <Weekend key={name}>ส</Weekend>
      default:
        return <Weekend key={name}>อา</Weekend>
    }
  }

  const getName = (name) => {
    switch (name) {
      case 'monday':
        return 'จ'
      case 'tuesday':
        return 'อ'
      case 'wednesday':
        return 'พ'
      case 'thursday':
        return 'พฤ'
      case 'friday':
        return 'ศ'
      case 'saturday':
        return 'ส'
      default:
        return 'อา'
    }
  }

  const getNextDay = (name) => {
    switch (name) {
      case 'monday':
        return 'tuesday'
      case 'tuesday':
        return 'wednesday'
      case 'wednesday':
        return 'thursday'
      case 'thursday':
        return 'friday'
      case 'friday':
        return 'saturday'
      case 'saturday':
        return 'sunday'
      default:
        return ''
    }
  }

  const inList = (name, list) => {
    const i = list.findIndex((it) => it.name === name)
    return i !== -1
  }

  const isWeekend = (list) => {
    return inList('saturday', list) && inList('sunday', list)
  }

  const isSeq = (list) => {
    let nextDay
    for (const item of list) {
      if (nextDay && item.name !== nextDay) {
        return false
      }
      nextDay = getNextDay(item.name)
    }
    return true
  }

  const { item = {} } = props
  const open_list = item.open_list || []
  let content = []
  const len = open_list.length

  if (len === 7) {
    content = <Range>ทุกวัน</Range>
  } else if (len === 1) {
    content = getDayName(open_list[0].name)
  } else if (len === 2 && isWeekend(open_list)) {
    content = <RangeWeekend>ส - อา</RangeWeekend>
  } else if (len > 0) {
    if (isSeq(open_list)) {
      const start = getName(open_list[0].name)
      const end = getName(open_list[len - 1].name)
      content = (
        <Range>
          {start} - {end}
        </Range>
      )
    } else {
      for (const item of open_list) {
        content.push(getDayName(item.name))
      }
    }
  }

  return <Tag>{content}</Tag>
}

const Tag = styled.div`
  font-size: 10px;
  display: flex;
`

const Range = styled.div`
  color: white;
  background-color: ${(p) => p.theme.color.yellow};
  border-radius: 12px;
  width: 42px;
  text-align: center;
`

const RangeWeekend = styled(Range)`
  background-color: ${(p) => p.theme.color.green};
`

const Day = styled.div`
  color: white;
  background-color: ${(p) => p.theme.color.yellow};
  border-radius: 50%;
  min-width: 18px;
  padding: 1px 2px;
  text-align: center;
  margin-right: 4px;
`

const Weekend = styled(Day)`
  background-color: ${(p) => p.theme.color.green};
`

export default OpenTag
