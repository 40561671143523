import styled from 'styled-components'

import BaseDialog from './BaseDialog'

const ImgDialog = (props) => {
  const { id = 'display-img', visible, img, onClose } = props

  return (
    <BaseDialog visible={visible} onClose={onClose}>
      <Content id={id}>
        <Image src={img} />
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 80vw;
  height: 70vh;
  display: flex;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 6px;
`

export default ImgDialog
