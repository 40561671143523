import styled from 'styled-components'

import { SelectList } from 'components/input'

const Filter = (props) => {
  const { filter = {}, roundMenu, onFilter = () => {} } = props

  const onRoundType = (val, name, tag) => {
    filter.round = val
    filter.round_start_at = tag.start_at
    filter.round_finish_at = tag.finish_at
    onFilter(filter)
  }

  return (
    <PageView>
      <SelectList
        placeholder="รอบขาย"
        value={filter.round}
        menu={roundMenu}
        onChange={onRoundType}
        clear={false}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default Filter
