import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Checker } from 'components/input'

import Label from './Label'
import ContentView from './ContentView'
import Line from './Line'

const MarketType = (props) => {
  const { doc = {}, onChange } = props
  const type_list = doc.market_type_list || []

  const { market_type_list = [] } = props.marketType.toJS()

  const onCh = (isCheck, id) => {
    if (isCheck) {
      type_list.push({ market_type_id: id })
    } else {
      const index = type_list.findIndex((it) => it.market_type_id === id)
      if (index !== -1) {
        type_list.splice(index, 1)
      }
    }

    doc.market_type_list = type_list
    onChange(doc)
  }

  const content = market_type_list.map((it, i) => {
    const obj = type_list.find(
      (typ) => typ.market_type_id === it.market_type_id
    )
    return (
      <Col key={i} lg={12} md={12} xs={24}>
        <Line>
          <Checker
            label={it.name}
            name={it.market_type_id}
            value={!!obj}
            onChange={onCh}
          />
        </Line>
      </Col>
    )
  })

  return (
    <PageView>
      <Label text="ประเภทตลาด" />
      <ContentView>
        <Row>{content}</Row>
      </ContentView>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('marketType')(observer(MarketType))
