import styled from 'styled-components'

import { Next, Back } from 'icon'
import { timer } from 'utils'

import DropdownList from './DropdownList'

export const renderHeader = (props) => {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props

  const today = timer.get()
  const current = date ? timer.get(date) : today

  return (
    <HeaderView>
      <Icon onClick={decreaseMonth}>
        <Back
          size={size}
          color={prevMonthButtonDisabled ? color.inactive : color.active}
        />
      </Icon>
      <ContentView>
        <MonthView>
          <DropdownList
            menu={month_list}
            value={current.months()}
            onChange={changeMonth}
          />
        </MonthView>
        <YearView>
          <DropdownList
            menu={year_list}
            value={current.year()}
            onChange={changeYear}
          />
        </YearView>
      </ContentView>
      <Icon onClick={increaseMonth}>
        <Next
          size={size}
          color={nextMonthButtonDisabled ? color.inactive : color.active}
        />
      </Icon>
    </HeaderView>
  )
}

const size = 0.8
const color = {
  active: '#494949',
  inactive: '#8A8A8A',
}

const year = timer.get().year()
const min = year - 80
const max = year + 5
const year_list = []
for (let i = min; i < max; i++) {
  year_list.push({
    name: `${i + 543}`,
    value: i,
  })
}

const month_list = [
  { name: 'มกราคม', value: 0 },
  { name: 'กุมภาพันธ์', value: 1 },
  { name: 'มีนาคม', value: 2 },
  { name: 'เมษายน', value: 3 },

  { name: 'พฤษภาคม', value: 4 },
  { name: 'มิถุนายน', value: 5 },
  { name: 'กรกฎาคม', value: 6 },
  { name: 'สิงหาคม', value: 7 },

  { name: 'กันยายน', value: 8 },
  { name: 'ตุลาคม', value: 9 },
  { name: 'พฤศจิกายน', value: 10 },
  { name: 'ธันวาคม', value: 11 },
]

const HeaderView = styled.div`
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
  width: 100%;
  height: 44px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ContentView = styled.div`
  z-index: 1;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MonthView = styled.div`
  width: 60%;
`

const YearView = styled.div`
  width: 36%;
`

const Icon = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
`
