/* eslint-disable */

const Logo = (props) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="22" fill="#00C767" />
      <path
        d="M8.64297 29.5L14.571 14.044H19.347L25.275 29.5H20.571L19.275 26.212H14.619L13.347 29.5H8.64297ZM15.219 22.972H18.675L16.947 18.436L15.219 22.972ZM29.0469 29.5V18.22L26.4789 18.604V14.836L33.5589 13.804V29.5H29.0469Z"
        fill="white"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
