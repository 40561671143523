import styled from 'styled-components'

import { Row, Col } from 'components/display'

import { col } from './col'

const Header = (props) => {
  return (
    <PageView>
      <Row>
        <Col {...col.name} position="left">
          <Text>ล็อก</Text>
        </Col>
        <Col {...col.detail} position="center">
          <Text>รอบ</Text>
        </Col>
        <Col {...col.type} position="right">
          <Text>สินค้าผู้เช่า</Text>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const Text = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.blue};
  font-size: 14px;
  border-radius: 12px;
  text-align: center;
  padding: 2px 0px;
`

export default Header
