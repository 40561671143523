import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Down } from 'icon'
import { PrimaryBtn } from 'components/button'
import { Col } from 'components/display'
import { format } from 'utils'

import Order from './Order'

const BookingMenu = (props) => {
  const [visible, setVisible] = useState(false)

  const { loading, list = [], total = 0, onBook } = props

  const onToggle = () => setVisible(!visible)

  if (list.length === 0) {
    return <div />
  }

  let direction
  let label
  let col = { lg: 14, md: 14, xs: 10 }
  if (visible) {
    label = 'ราคารวมทั้งสิ้น'
    col = { lg: 16, md: 16, xs: 12 }
  } else {
    label = `เลือก ${list.length} รายการ`
    direction = (
      <Col lg={2} md={2} xs={2}>
        <Button onClick={onToggle}>
          <Down />
        </Button>
      </Col>
    )
  }

  return (
    <PageView>
      <Content>
        <Order visible={visible} onClose={onToggle} />
        <Row align="middle">
          {direction}
          <Col {...col} position="center">
            <Label>{label}</Label>
            <Price>
              {format.toDigi(total)}
              <span>บาท</span>
            </Price>
          </Col>

          <Col lg={8} md={8} xs={12} position="right">
            <PrimaryBtn loading={loading} text="เสนอรายการ" onClick={onBook} />
          </Col>
        </Row>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  padding: 24px;
  z-index: 5;
`

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Price = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 26px;

  span {
    font-size: 14px;
    margin-left: 4px;
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export default BookingMenu
