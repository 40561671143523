/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(88 * size)}`
  const height = `${Math.ceil(88 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1705_7877)">
        <path
          d="M44 0C42.988 0 42.1666 0.821391 42.1666 1.83339V9.16678C42.1666 10.1786 42.988 11 44 11C45.012 11 45.8334 10.1786 45.8334 9.16661V1.83339C45.8334 0.821391 45.012 0 44 0Z"
          fill="white"
        />
        <path
          d="M73.3334 29.3334H66C64.988 29.3334 64.1666 30.1548 64.1666 31.1668C64.1666 32.1788 64.988 33 66 33H73.3334C74.3454 33 75.1668 32.1786 75.1668 31.1666C75.1668 30.1546 74.3454 29.3334 73.3334 29.3334Z"
          fill="white"
        />
        <path
          d="M22 29.3334H14.6666C13.6546 29.3334 12.8332 30.1548 12.8332 31.1668C12.8332 32.1788 13.6546 33 14.6666 33H22C23.012 33 23.8334 32.1786 23.8334 31.1666C23.8334 30.1546 23.012 29.3334 22 29.3334Z"
          fill="white"
        />
        <path
          d="M66.0366 9.13C65.3216 8.415 64.1593 8.415 63.4443 9.13L58.2596 14.3146C57.5446 15.0296 57.5446 16.192 58.2596 16.907C58.619 17.2626 59.0882 17.4424 59.5576 17.4424C60.027 17.4424 60.4963 17.2628 60.852 16.907L66.0366 11.7224C66.7516 11.0074 66.7516 9.845 66.0366 9.13Z"
          fill="white"
        />
        <path
          d="M29.7404 14.3146L24.5556 9.13C23.8406 8.415 22.6782 8.415 21.9632 9.13C21.2482 9.845 21.2482 11.0074 21.9632 11.7224L27.1442 16.907C27.5036 17.2626 27.9728 17.4424 28.4422 17.4424C28.9116 17.4424 29.3808 17.2628 29.7402 16.907C30.4554 16.192 30.4554 15.0296 29.7404 14.3146Z"
          fill="white"
        />
        <path
          d="M63.8074 53.2986C61.1674 49.775 56.2248 48.928 52.5544 51.37L49.5 53.405V31.1666C49.5 28.1342 47.0324 25.6666 44 25.6666C40.9676 25.6666 38.5 28.1342 38.5 31.1666V36.9782C37.928 36.7766 37.3084 36.6666 36.6666 36.6666C34.1366 36.6666 31.9952 38.3862 31.361 40.7182C30.7304 40.4726 30.0484 40.3332 29.3334 40.3332C26.8034 40.3332 24.662 42.0528 24.0278 44.3848C23.397 44.1394 22.715 44 22 44C18.9676 44 16.5 46.4676 16.5 49.5V64.1666C16.5 73.2636 23.903 80.6666 33 80.6666H39.16C42.7496 80.6666 46.1964 79.1486 48.6236 76.5086L63.4626 60.3166C65.2484 58.3624 65.395 55.4144 63.8074 53.2986ZM60.7566 57.8344L45.9176 74.0264C44.187 75.9184 41.723 77 39.16 77H33C25.9234 77 20.1666 71.2434 20.1666 64.1666V49.5C20.1666 48.488 20.988 47.6666 22 47.6666C23.012 47.6666 23.8334 48.488 23.8334 49.5V51.3334C23.8334 52.3454 24.6548 53.1668 25.6668 53.1668C26.6788 53.1668 27.5 52.3454 27.5 51.3334V45.8334C27.5 44.8214 28.3214 44 29.3334 44C30.3454 44 31.1668 44.8214 31.1668 45.8334V51.3334C31.1668 52.3454 31.9882 53.1668 33.0002 53.1668C34.0122 53.1668 34.8336 52.3454 34.8336 51.3334V42.1668C34.8336 41.1548 35.655 40.3334 36.667 40.3334C37.679 40.3334 38.5 41.1546 38.5 42.1666V51.3332C38.5 52.3452 39.3214 53.1666 40.3334 53.1666C41.3454 53.1666 42.1668 52.3452 42.1668 51.3332V31.1666C42.1668 30.1546 42.9882 29.3332 44.0002 29.3332C45.0122 29.3332 45.8336 30.1546 45.8336 31.1666V56.8332C45.8336 57.5116 46.2112 58.1276 46.8052 58.4466C47.3992 58.7656 48.1252 58.729 48.6862 58.355L54.5896 54.417C56.6392 53.0566 59.3966 53.526 60.8742 55.495C61.402 56.199 61.3544 57.1854 60.7566 57.8344Z"
          fill="white"
        />
        <path
          d="M47.6666 77H22C20.988 77 20.1666 77.8214 20.1666 78.8334V86.1668C20.1666 87.1788 20.988 88.0002 22 88.0002C23.012 88.0002 23.8334 87.1788 23.8334 86.1668V80.6668H45.8334V86.1668C45.8334 87.1788 46.6548 88.0002 47.6668 88.0002C48.6788 88.0002 49.5 87.1786 49.5 86.1666V78.8332C49.5 77.8214 48.6786 77 47.6666 77Z"
          fill="white"
        />
        <path
          d="M44 18.3334C36.9234 18.3334 31.1666 24.09 31.1666 31.1668C31.1666 32.1788 31.988 33.0002 33 33.0002C34.012 33.0002 34.8334 32.1788 34.8334 31.1668C34.8334 26.114 38.9474 22 44 22C49.0526 22 53.1666 26.114 53.1666 31.1666C53.1666 32.1786 53.988 33 55 33C56.012 33 56.8334 32.1786 56.8334 31.1666C56.8334 24.09 51.0766 18.3334 44 18.3334Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1705_7877">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
/* eslint-enable */
export default Logo
