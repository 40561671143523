import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import Loading from 'components/loading'
import { BackHeader, Content } from 'components/display'
import { helper, timer, market } from 'utils'

import Filter from './Filter'
import Header from './Header'
import Product from './Product'
import BookingMenu from './Menu'
import SettingDrawer from './setting'
import ReasonDrawer from './reason'

const BookingOffer = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [visibleSetting, setVisibleSetting] = useState(false)
  const [visibleReason, setVisibleReason] = useState(false)
  const [filter, setFilter] = useState({})
  const [find, setFind] = useState('')
  const [err, setErr] = useState('')

  const { market_id, code } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { doc = {}, setting = {}, offer = {} } = props.order.toJS()
  const { ready_product_list } = props.product.toJS()
  const { booking_type } = doc

  const onLoad = useCallback(
    async (id, c) => {
      try {
        setLoading(true)

        await Promise.all([
          props.order.getOrderInfo(c),
          props.order.getMarketSetting(id),
        ])
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.order]
  )

  useEffect(() => {
    onLoad(market_id, code)
  }, [onLoad, market_id, code])

  const getKey = useCallback((val = {}) => {
    const { start_at, finish_at, round_start_at, round_finish_at } = val
    const t = 'DDMMYY'
    if (booking_type === 'round') {
      const s = round_start_at ? round_start_at.format(t) : ''
      const f = round_finish_at ? round_finish_at.format(t) : ''
      return `${s}-${f}`
    }

    const s = start_at ? start_at.format(t) : ''
    const f = finish_at ? finish_at.format(t) : ''
    return `${s}-${f}`
  }, [])

  useEffect(() => {
    if (!doc.market_id || !setting.market_id) {
      return
    }
    // init select date
    const open_list = doc.open_list || []
    const days_list = helper.getDateList(open_list)

    const limit = market.getDateLimitation({
      setting,
      days_list,
    })

    const newFilter = {
      start_at: timer.get(doc.start_at),
      finish_at: timer.get(doc.finish_at),
      booking_type,
      limit,
      days_list,
    }

    const round_list = setting.round_list || []
    if (round_list.length > 0) {
      const menuItem = round_list[0]
      newFilter.round_start_at = menuItem.tag.start_at
      newFilter.round_finish_at = menuItem.tag.finish_at
      newFilter.round = menuItem.value
    }

    newFilter.key = getKey(newFilter)
    setFilter(newFilter)
  }, [doc.market_id, setting.market_id, market, getKey])

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    if (source) {
      navigate(source)
    } else {
      navigate(`/market/${market_id}/booking/${code}`)
    }
  }

  const getProductList = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.product.getRangeBookingList(params)
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.product]
  )

  useEffect(() => {
    const { booking_type } = filter
    let start_at = filter.start_at
    let finish_at = filter.finish_at

    switch (booking_type) {
      case 'daily':
        break
      case 'round':
        start_at = filter.round_start_at
        finish_at = filter.round_finish_at
        break
      default:
        finish_at = timer.getFinishMonth(start_at)
    }

    if (market_id && start_at && finish_at) {
      getProductList({ market_id, booking_type, start_at, finish_at })
    }
  }, [getProductList, market_id, filter])

  const onCloseError = () => setErr('')
  const onFind = (val) => setFind(val)

  const onFilter = (val) => {
    val.key = getKey(val)
    setFilter({ ...val })
  }
  const onOpenSetting = () => setVisibleSetting(true)
  const onCloseSetting = () => setVisibleSetting(false)
  const onSaveSetting = (data) => {
    onCloseSetting()

    doc.offer_setting = data
    props.order.setOrder(doc)
  }

  const onOpenReason = () => setVisibleReason(true)
  const onCloseReason = () => setVisibleReason(false)

  const onOfferOrder = async (reason) => {
    try {
      setProcessing(true)
      onCloseReason()

      const { order_id, offer_setting } = doc
      const { list, total } = offer
      await props.order.offerOrder({
        order_id,
        offer_setting,
        reason,
        list,
        total,
      })
      setProcessing(false)
      onBack()
    } catch (e) {
      setErr(e.message)
      setProcessing(false)
    }
  }

  const getFindList = () => {
    if (find === '') {
      return ready_product_list
    }

    const text = find.toLowerCase()
    return ready_product_list.filter((it) => {
      const name = (it.name || '').toLowerCase()
      return name.includes(text)
    })
  }

  const product_list = getFindList()
  let last = undefined
  const { order_id } = doc
  const content = product_list.map((it, i) => {
    const item = (
      <Product
        key={i}
        last={last}
        order_id={order_id}
        filter={filter}
        doc={it}
        selected={offer}
      />
    )
    last = it
    return item
  })

  const round_list = setting.round_list || []

  return (
    <PageView>
      <BackHeader onClick={onBack} title="ล็อกที่เสนอใหม่" />
      <Content isHeader={true}>
        <Filter
          find={find}
          filter={filter}
          bookingType={booking_type}
          roundMenu={round_list}
          onFilter={onFilter}
          onFind={onFind}
          onSetting={onOpenSetting}
        />
        <Header />
        <Loading loading={loading}>{content}</Loading>
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
      <BookingMenu
        loading={processing}
        list={offer.list}
        total={offer.total}
        onBook={onOpenReason}
      />
      <SettingDrawer
        visible={visibleSetting}
        setting={doc.offer_setting}
        onClose={onCloseSetting}
        onSave={onSaveSetting}
      />
      <ReasonDrawer
        visible={visibleReason}
        onClose={onCloseReason}
        onSave={onOfferOrder}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 60px;
`

export default inject('order', 'product')(observer(BookingOffer))
