import { forwardRef } from 'react'
import styled, { useTheme } from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker'
import th from 'date-fns/locale/th'

import { Calendar } from 'icon'
import { format, timer } from 'utils'

import { renderHeader } from './Header'
import Invalid from './Invalid'

registerLocale('th', th)

const toDate = (txt) => {
  const t = txt.trim()
  if (t === '') return ''

  const date = format.toThaiDate(txt, 'MM/DD/YYYY')
  return date
}

const Picker = (props) => {
  const theme = useTheme()
  const {
    placeholder,
    disabled = false,
    value,
    min,
    max,
    days_list,
    readonly = false,
    invalid,
    name,
    onChange = () => {},
    onRender,
  } = props

  const getValue = (val) => {
    if (typeof val === 'string' || val instanceof String) {
      return timer.get(val).toDate()
    } else if (val) {
      return timer.get(val).toDate()
    }

    return val
  }

  const onValue = (val) => {
    const v1 = val ? timer.get(val) : null
    onChange(v1, name)
  }

  const onFilterDate = (date) => {
    const day = date.getDay()
    return days_list.includes(day)
  }

  const val = getValue(value)
  const css = value ? 'fill' : 'empty'
  const readonlyCss = readonly ? 'picker-readonly' : ''
  const invalidCss = invalid ? 'invalid' : ''

  const color = invalid ? theme.color.error : undefined
  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const text = onRender ? onRender(value) : toDate(value)
    const display =
      text === '' ? (
        <Placeholder className={`${invalidCss}`}>{placeholder}</Placeholder>
      ) : (
        text
      )
    return (
      <Text className={`${css}`} onClick={onClick} ref={ref}>
        {display}

        <Icon>
          <Calendar color={color} />
        </Icon>
      </Text>
    )
  })

  CustomInput.displayName = 'input'

  return (
    <PageView className={`${css} ${readonlyCss} ${invalidCss}`}>
      <Label className={css}>{placeholder}</Label>
      <DatePicker
        locale="th"
        selected={val}
        disabled={disabled || readonly}
        minDate={min}
        maxDate={max}
        className={css}
        onChange={onValue}
        customInput={<CustomInput />}
        renderCustomHeader={renderHeader}
        filterDate={days_list ? onFilterDate : undefined}
      />
      <Invalid invalid={invalid} />
    </PageView>
  )
}

const PageView = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    padding: 4px 11px;
    background-color: white;
  }

  .react-datepicker-wrapper > .react-datepicker__input-container {
    width: 100%;
    height: 44px;
    border: 0px;
    outline: none;
    padding: 0px;
  }

  .react-datepicker-popper {
    z-index: 5;
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.medium};
  position: absolute;
  left: 10px;
  top: 2px;
  z-index: 1;
  transition: all 0.3s ease;

  &.empty {
    display: none;
  }
`

const Icon = styled.div`
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 10px;

  ${(p) => p.theme.media.mobile_xs} {
    right: 2px;
  }
`

const Placeholder = styled.div`
  color: ${(p) => p.theme.color_level.grey.medium};
  font-size: 14px;
  &.invalid {
    color: ${(p) => p.theme.color.red};
  }
`

const Text = styled.div`
  position: relative;
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
  width: 100%;
  height: 44px;
  border: 0px;
  outline: none;
  padding: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.fill {
    padding-top: 10px;
  }
`

export default Picker
