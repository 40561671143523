import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import image from 'img'
import { Marget } from 'icon'
import { ErrorDialog } from 'dialog'
import { Meta, Content } from 'components/display'
import { PrimaryBtn } from 'components/button'

import LoginDialog from './LoginDialog'

const Home = (props) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [err, setErr] = useState('')
  const navigate = useNavigate()

  const onClose = () => setVisible(false)
  const onCloseError = () => setErr('')

  const onLogin = async () => {
    try {
      setLoading(true)
      setErr('')

      const pathname = window.location.pathname
      const search = window.location.search || ''
      const path = `${pathname}${search}`
      console.log('auth path:', path)
      const redirect = path === '/' ? '/dashboard' : path
      console.log('auth redirect:', redirect)
      await props.member.lineLogin({ redirect, navigate })

      setLoading(false)
    } catch (e) {
      setErr(e.message)
      setLoading(false)
    }
  }

  return (
    <PageView>
      <Meta
        title="Market - จัดล็อกตลาดนัดพื้นที่เช่าทำเลขายของบูธอีเวนท์"
        description="จัดล็อกตลาดนัดพื้นที่เช่าทำเลขายของบูธอีเวนท์"
      />

      <Content>
        <Title>ลงพื้นที่ให้เช่ากับ</Title>
        <Marget size={1.8} />
        <Description>เรื่องเช่า ให้เราช่วย</Description>
        <Button>
          <PrimaryBtn
            text="เปิดให้เช่าพื้นที่"
            onClick={() => setVisible(true)}
          />
        </Button>
      </Content>
      <LoginDialog
        visible={visible}
        loading={loading}
        onClose={onClose}
        onLogin={onLogin}
      />

      <ErrorDialog
        message_list={['ไม่สามารถเข้าสู่ระบบได้', 'กรุณาลองใหม่อีกครั้ง']}
        error={err}
        onClose={onCloseError}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
  text-align: center;
  background-image: url(${image.landing});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 28px;
  font-weight: bold;
  padding-top: 10%;
  margin-bottom: 20px;
`

const Description = styled.div`
  color: ${(props) => props.theme.color.grey};
  font-size: 18px;
  margin-top: 20px;
`

const Button = styled.div`
  width: 220px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
`

export default inject('member')(observer(Home))
