import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Input } from 'components/input'
import { Delete } from 'icon'

const OpenTime = (props) => {
  const { item = {}, index, onRemove = () => {}, onChange = () => {} } = props

  const onCh = (val, name) => {
    item[name] = val
    onChange(item, index)
  }

  const remove = () => onRemove(index)

  return (
    <PageView>
      <Row align="middle">
        <Col span={10} position="left">
          <Input
            label="เวลาเริ่ม"
            name="start_at"
            value={item.start_at}
            onChange={onCh}
            isNumber={true}
            pattern={item.start_at.length === 3 ? 'x:xx' : 'xx:xx'}
            charPattern=":"
          />
        </Col>
        <Col span={10} position="center">
          <Input
            label="เวลาสิ้นสุด"
            name="finish_at"
            value={item.finish_at}
            onChange={onCh}
            isNumber={true}
            pattern={item.finish_at.length === 3 ? 'x:xx' : 'xx:xx'}
            charPattern=":"
          />
        </Col>

        <Col span={4} position="right">
          <Btn onClick={remove}>
            <Delete size={1.2} />
          </Btn>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`
const Btn = styled.span`
  width: 40px;
  height: 100%;
  display: flex;
`

export default OpenTime
