import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import { Next } from 'icon'
import { market } from 'utils'
import Menu from 'components/menu'
import { Link } from 'components/link'
import { OpenTag } from 'components/market/components'
import { Meta, BackHeader, Content, Row, Col } from 'components/display'

const Market = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const { market_id } = useParams()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.market.getMarketInfo(market_id),
        props.market.getMarketCounter(market_id),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.market, market_id])

  useEffect(() => {
    onInit()
  }, [onInit])

  const { doc = {}, counter = {} } = props.market.toJS()

  const onCloseError = () => setErr('')
  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/dashboard'
    navigate(path)
  }

  const renderCard = (counter, text, to) => {
    return (
      <Link to={to}>
        <Card>
          <CardInfo>
            {counter}
            {text}
          </CardInfo>
          <Next />
        </Card>
      </Link>
    )
  }

  const link = `/market/${market_id}`

  const menu_list = [
    { label: 'ข้อมูลตลาด', link: `${link}/information` },
    { label: 'แผนผังพื้นที่', link: `${link}/floor-plan` },
    { label: 'ตั้งค่าการจอง', link: `${link}/setting` },
    { label: 'ล็อกที่เปิดจอง', link: `${link}/product` },
    { label: 'รายการจอง', link: `${link}/booking` },
    { label: 'แจ้งประกาศ', link: `${link}/announce` },
    { label: 'โปรโมชั่นและส่วนลด', link: `${link}/promotion` },
  ]

  return (
    <PageView>
      <Meta title="Market - รายละเอียดตลาด" description="รายละเอียดตลาด" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Row>
          <Col span={15}>
            <Name>{doc.name}</Name>
            <MarketOpen>
              <OpenTag item={doc} />
            </MarketOpen>
            <Time>{market.toOpenTime(doc)}</Time>
          </Col>
          <Col span={9} position="right">
            <Right>
              <Counter>
                ล็อกทั้งหมด
                <span>{counter.unit_total || 0}</span>
              </Counter>
            </Right>
          </Col>
        </Row>

        <Row>
          <Col span={12} position="left">
            {renderCard(
              <Ready>{counter.ready_round}</Ready>,
              'ล็อกว่าง',
              `${link}/unit`
            )}
          </Col>
          <Col span={12} position="right">
            {renderCard(
              <NewBooking>{counter.new_order}</NewBooking>,
              'รายการจองใหม่',
              `${link}/booking`
            )}
          </Col>
        </Row>

        <Menu menu={menu_list} />
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Name = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 24px;
  line-height: 1;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Time = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  margin-top: 4px;
`

const MarketOpen = styled.div`
  width: 100%;
`

const Right = styled.div`
  float: right;
`

const Counter = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  display: flex;
  align-items: center;

  span {
    color: white;
    font-weight: bold;
    background-color: ${(props) => props.theme.color.green};
    border-radius: 16px;
    padding: 6px 14px;
    margin-left: 12px;
  }
`

const Card = styled.div`
  width: 100%;
  height: 96px;
  color: ${(props) => props.theme.color_level.grey.high};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  svg {
    flex: 25px;
  }
`

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: calc(100% - 25px);
`

const TextCard = styled.div`
  font-size: 48px;
  line-height: 1;
`

const NewBooking = styled(TextCard)`
  color: ${(props) => props.theme.color.green};
`

const Ready = styled(TextCard)`
  color: ${(props) => props.theme.color.blue};
`

export default inject('market')(observer(Market))
