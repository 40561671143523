import styled from 'styled-components'

import Loading from 'components/loading'

const Content = (props) => {
  const { children, loading = false, isHeader = false } = props
  if (isHeader) {
    return (
      <PageHeaderView>
        <Loading loading={loading}>{children}</Loading>
      </PageHeaderView>
    )
  }

  return (
    <PageView>
      <Loading loading={loading}>{children}</Loading>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 45px 24px 24px 24px;
  margin: 0 auto;
`

const PageHeaderView = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 75px 24px 24px 24px;
  margin: 0 auto;
`
// max-width: 1440px;
export default Content
