/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#305FED' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_333_1319)">
        <path
          d="M18.6011 6.74989C19.9217 6.74989 20.997 5.67464 20.997 4.35407C20.997 3.0335 19.9227 1.95825 18.6011 1.95825C17.2806 1.95825 16.2053 3.0335 16.2053 4.35407C16.2053 5.67464 17.2806 6.74989 18.6011 6.74989ZM18.6011 2.91657C19.3937 2.91657 20.0386 3.56151 20.0386 4.35407C20.0386 5.14663 19.3946 5.79157 18.6011 5.79157C17.8086 5.79157 17.1636 5.14663 17.1636 4.35407C17.1636 3.56151 17.8096 2.91657 18.6011 2.91657Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M22.7747 14.7185L22.1604 9.80798C22.0119 8.61104 20.9894 7.70923 19.7837 7.70923H17.4176C17.1512 7.70923 16.8829 7.75617 16.6193 7.8501C16.3701 7.93829 16.2398 8.21235 16.328 8.46154C16.4171 8.71072 16.6902 8.84198 16.9394 8.75285C17.0995 8.6963 17.2605 8.66755 17.4176 8.66755H19.7837C20.5073 8.66755 21.1197 9.20899 21.2097 9.9268L21.824 14.8373C21.8413 14.9753 21.8001 15.1095 21.7081 15.2139C21.6161 15.3184 21.4887 15.3759 21.3487 15.3759H20.997C20.745 15.3759 20.537 15.5694 20.5188 15.8205L20.0713 22.0842H17.1302L16.7852 17.2571C16.767 16.9935 16.535 16.7865 16.2734 16.8134C16.0098 16.8316 15.8105 17.0616 15.8297 17.3252L16.2063 22.597C16.2255 22.8471 16.4344 23.0416 16.6845 23.0416H20.5179C20.7689 23.0416 20.9779 22.8471 20.9951 22.598L21.4427 16.3314C21.8202 16.3065 22.1739 16.134 22.4259 15.8484C22.699 15.5388 22.8265 15.1277 22.7747 14.7185Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M7.50564 16.9226C7.23826 16.8977 7.01307 17.1028 6.99389 17.3663L6.65657 22.0832H3.71445L3.26789 15.8195C3.24965 15.5694 3.04076 15.3749 2.7887 15.3749H2.43701C2.29708 15.3749 2.16964 15.3174 2.07764 15.2129C1.98564 15.1085 1.94444 14.9753 1.96169 14.8363L2.576 9.92582C2.66512 9.20801 3.2775 8.66657 4.002 8.66657H6.36812C6.47544 8.66657 6.5895 8.68288 6.72943 8.72025C6.98531 8.7835 7.24787 8.634 7.31499 8.37813C7.38206 8.12225 7.22874 7.85968 6.97286 7.79257C6.75149 7.73507 6.55981 7.70825 6.36718 7.70825H4.00195C2.79639 7.70825 1.77477 8.61006 1.62527 9.807L1.01101 14.7175C0.960204 15.1266 1.0867 15.5378 1.35983 15.8473C1.61189 16.1329 1.96551 16.3054 2.34308 16.3303L2.79064 22.5969C2.80883 22.847 3.01776 23.0416 3.26789 23.0416H7.1012C7.35227 23.0416 7.5612 22.847 7.5794 22.5969L7.94834 17.4343C7.96752 17.1708 7.7682 16.9418 7.50564 16.9226Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M5.18451 6.74989C6.50508 6.74989 7.58033 5.67464 7.58033 4.35407C7.58033 3.0335 6.50603 1.95825 5.18451 1.95825C3.86395 1.95825 2.7887 3.0335 2.7887 4.35407C2.7887 5.67464 3.86395 6.74989 5.18451 6.74989ZM5.18451 2.91657C5.97707 2.91657 6.62201 3.56151 6.62201 4.35407C6.62201 5.14663 5.97801 5.79157 5.18451 5.79157C4.39295 5.79157 3.74701 5.14663 3.74701 4.35407C3.74701 3.56151 4.39295 2.91657 5.18451 2.91657Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M16.1124 14.6438L15.477 9.07781C15.3266 7.7505 14.2753 6.75 13.0333 6.75H10.5867C9.3466 6.75 8.29623 7.7505 8.14485 9.07781L7.50947 14.6438C7.45678 15.1096 7.60053 15.5724 7.90335 15.9117C8.16497 16.204 8.51092 16.3746 8.88754 16.3956L9.41654 23.5563C9.43473 23.8064 9.64367 24 9.89379 24H13.7271C13.9782 24 14.1871 23.8064 14.2044 23.5563L14.7334 16.3956C15.109 16.3746 15.4559 16.2049 15.7176 15.9117C16.0223 15.5714 16.1652 15.1096 16.1124 14.6438ZM15.0046 15.2734C14.949 15.3348 14.8302 15.4392 14.6538 15.4392H14.2887C14.0377 15.4392 13.8287 15.6328 13.8105 15.8829L13.2825 23.0417H10.3394L9.81235 15.8829C9.79317 15.6328 9.58522 15.4392 9.33415 15.4392H8.96997C8.7946 15.4392 8.67479 15.3348 8.61922 15.2734C8.49753 15.1364 8.44003 14.9466 8.46204 14.7521L9.09742 9.18611C9.19323 8.34374 9.83436 7.70836 10.5886 7.70836H13.0352C13.7885 7.70836 14.4296 8.34374 14.5264 9.18611L15.1618 14.7521C15.1838 14.9466 15.1263 15.1364 15.0046 15.2734Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M11.8104 5.79168C13.131 5.79168 14.2062 4.71643 14.2062 3.39586C14.2062 2.07529 13.132 1 11.8104 1C10.4899 1 9.41461 2.07525 9.41461 3.39582C9.41461 4.71639 10.4899 5.79168 11.8104 5.79168ZM11.8104 1.95832C12.603 1.95832 13.2479 2.60326 13.2479 3.39582C13.2479 4.18837 12.603 4.83332 11.8104 4.83332C11.0179 4.83332 10.3729 4.18837 10.3729 3.39582C10.3729 2.60331 11.0189 1.95832 11.8104 1.95832Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_333_1319">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
/* eslint-enable */
export default Logo
