import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Link } from 'components/link'
import { product } from 'utils'

const OrderCard = (props) => {
  const { item, source = '/' } = props
  const {
    market_name,
    code,
    status,
    start_at,
    finish_at,
    due_payment_at,
    list = [],
  } = item

  const { text, color } = product.getStatus({ status, due_payment_at })
  const date = product.getDate(start_at, finish_at)
  const link = `/booking/${code}?source=${source}`

  const keys = {}
  for (const item of list) {
    keys[item.name] = true
  }
  const names = Object.keys(keys)
  const content = names.map((name, i) => {
    return <Name key={i}>{name}</Name>
  })

  return (
    <Link to={link}>
      <PageView>
        <Row>
          <Col lg={16} md={14} xs={12}>
            <Title>{market_name}</Title>
            <NameList>{content}</NameList>
            <Date>{date}</Date>
          </Col>
          <Col lg={8} md={10} xs={12}>
            <Information>
              <Code style={{ backgroundColor: color }}>
                {code.toUpperCase()}
              </Code>
              <Status style={{ color }}>{text}</Status>
            </Information>
          </Col>
        </Row>
      </PageView>
    </Link>
  )
}

const PageView = styled.div`
  background-color: white;
  width: 100%;
  padding: 22px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
`

const NameList = styled.div`
  width: 100%;
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  padding: 8px 0px;
`

const Name = styled.div`
  width: fit-content;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 8px;
  padding: 0px 5px;
  font-size: 14px;
  font-weight: 700;
`

const Date = styled.div`
  font-size: 14px;
  font-weight: 700;
  width: 100%;
`

const Information = styled.div`
  width: 100%;
  text-align: right;
`

const Code = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-color: #f55759;
  border-radius: 18px;
  padding: 2px 25px;
`

const Status = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #f55759;
  margin-top: 8px;
`

export default OrderCard
