import { useState } from 'react'
import styled from 'styled-components'

import { ImgDialog } from 'dialog'
import { Dot } from 'components/display'
import { format, product } from 'utils'

import Title from './Title'

const Contact = (props) => {
  const [image, setImage] = useState(undefined)

  const { order = {} } = props

  const onClose = () => setImage(undefined)
  const getText = (label, text) => {
    return (
      <Text>
        {label}
        <span>{text}</span>
      </Text>
    )
  }

  const { name, mobile } = order

  const shopInfo = order.shop_info || {}
  const img_list = shopInfo.img_list || []
  const productType = product.toProductType(shopInfo)
  const shopName = shopInfo.shop_name === '' ? 'ไม่ระบุ' : shopInfo.shop_name
  const content = img_list.map((img, i) => {
    const click = () => setImage(img)
    return <Image src={img} key={i} onClick={click} />
  })

  return (
    <PageView>
      <Title text="ข้อมูลผู้เช่า" />
      {getText('ผู้เช่า:', name)}
      {getText('เบอร์ติดต่อ:', format.toMobile(mobile))}
      <Dot />
      <Space />
      <Title text="ข้อมูลร้านค้า" />
      {getText('สินค้า:', shopInfo.name)}
      {getText('หมวดหมู่:', productType)}
      {getText('ร้าน:', shopName)}
      <ImgList>{content}</ImgList>
      <ImgDialog visible={image !== undefined} img={image} onClose={onClose} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px 0px 10px 0px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  span {
    float: right;
    font-weight: normal;
  }
`

const Space = styled.div`
  margin-bottom: 10px;
`

const ImgList = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
`

const Image = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 4px;
`

export default Contact
