import styled from 'styled-components'

import { Add } from 'icon'

const AddBtn = (props) => {
  const { onAdd = () => {} } = props

  const onAddFloor = () => {
    const input = document.getElementById('floorplan')
    if (input) {
      input.value = null
      input.click()
    }
  }

  const onChange = (evt) => {
    const files = evt.target.files
    if (files.length === 0) return

    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onAdd(reader.result, file)
    })
    reader.readAsDataURL(file)
  }

  return (
    <PageView>
      <Btn onClick={onAddFloor}>
        <Add />
        <Text>กดเพื่อเลือกรูป</Text>
        <Text>แผนผังตลาด</Text>
      </Btn>
      <Picker
        id="floorplan"
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={onChange}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  margin-bottom: 16px;
`

const Btn = styled.div`
  width: 100%;
  height: 134px;
  background-color: ${(p) => p.theme.color_level.blue.low};
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    margin-bottom: 12px;
  }
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  color: ${(p) => p.theme.color.disable};
`

const Picker = styled.input`
  display: none;
`

export default AddBtn
