/* eslint-disable */

const Logo = (props) => {
  return (
    <svg
      width="51"
      height="24"
      viewBox="0 0 51 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51 12L31 0.452994V23.547L51 12ZM0 14H3.1875V10H0V14ZM9.5625 14H15.9375V10H9.5625V14ZM22.3125 14H28.6875V10H22.3125V14ZM35.0625 14H41.4375V10H35.0625V14Z"
        fill="white"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
