import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useNavigate } from 'react-router-dom'

import { validator, message } from 'utils'
import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content, SaveBtn } from 'components/display'
import { MarketInfo } from 'components/market'
import Loading from 'components/loading'

import Menu from '../Menu'
import PageView from '../PageView'
import rules from './rules'

const MarketEditor = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validate, setValidate] = useState({})
  const [err, setErr] = useState('')

  const { market_id } = useParams()
  const navigate = useNavigate()

  const { doc = {} } = props.market.toJS()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await props.market.getMarketInfo(market_id)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.market, market_id])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onCloseError = () => setErr('')
  const onBack = () => {
    navigate(`/market/${market_id}/information`)
  }

  const onSave = async () => {
    try {
      const checker = validator.process(rules, doc)
      if (checker.invalid) {
        setValidate(checker.errors)
        validator.focus(checker.errors)
        return
      }

      setSaving(true)

      await props.market.updateOther(doc)
      message.success()
    } catch (e) {
      setErr(e.message)
    }

    setSaving(false)
  }

  const onChange = (data) => {
    props.market.setMarket(data)
  }

  const onValidate = (valid = {}) => {
    setValidate({ ...valid })
  }

  const { img_list = [] } = doc

  return (
    <PageView>
      <Meta
        loading={loading}
        title={'Market - แก้ไขข้อมูลอื่นๆตลาด'}
        description={doc.keywords}
        url={img_list.length === 0 ? undefined : img_list[0]}
      />
      <BackHeader color="white" onClick={onBack} />
      <Content isHeader={true}>
        <Menu id={market_id} selected="other" />
        <Loading loading={loading}>
          <MarketInfo
            doc={doc}
            onChange={onChange}
            validate={validate}
            onValidate={onValidate}
          />

          <SaveBtn
            loading={saving}
            disabled={validator.isError(validate)}
            onClick={onSave}
          />
        </Loading>
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

export default inject('market')(observer(MarketEditor))
