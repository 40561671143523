import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  setting: {
    open_type: 'month',
    next_value: 0.5,
    approve_type: 'auto',
  },
}

let state
export class Order extends BaseStore {
  constructor() {
    super()
    this.observable({
      order: {
        page: 1,
        per_page: 20,
        list: [],
      },
      all_order: {
        page: 1,
        per_page: 20,
        total: 0,
        list: [],
      },
      counter: {
        create: 0,
        payment: 0,
        offer: 0,
        completed: 0,
        cancelled: 0,
      },
      doc: {},
      setting: cloneDeep(original.setting),
      offer: {
        total: 0,
        list: [],
      },
    })
    state = this
  }

  async getOrderInfo(code) {
    const { doc = {} } = this.toJS()
    if (doc.code === code) {
      return doc
    }

    const url = `${config.api}/v1/user/market/order/${code}/code`
    const resp = await http.get(url)
    const data = resp.body || {}

    this.setOrder(data)
    runInAction(() => {
      state.offer = {
        total: 0,
        list: [],
      }
    })

    return data
  }

  async getOrderList({ market_id, status, per_page = 20, page = 1 }) {
    const q = `?per_page=${per_page}&&page=${page}`
    const url = `${config.api}/v1/user/market/order-market/${market_id}/${status}/status${q}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.order = {
        page,
        per_page,
        list,
      }
    })
  }

  async countOrder({ market_id }) {
    const url = `${config.api}/v1/user/market/order-market/${market_id}/counter`
    const res = await http.get(url)

    const doc = res.body || {}
    runInAction(() => {
      state.counter = {
        create: doc.create || 0,
        payment: doc.payment || 0,
        offer: doc.offer || 0,
        completed: doc.completed || 0,
        cancelled: doc.cancelled || 0,
      }
    })
  }

  async getAllOrderList({ per_page = 10, page = 1 } = {}) {
    const q = `?per_page=${per_page}&&page=${page}`
    const url = `${config.api}/v1/user/market/order-market/all/pending${q}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.all_order.page = page
      state.all_order.per_page = per_page
      state.all_order.list = list
    })
  }

  async countAllOrder() {
    const url = `${config.api}/v1/user/market/order-market/all/pending/counter`
    const res = await http.get(url)

    const { total } = res.body || {}
    runInAction(() => {
      state.all_order.total = total || 0
    })
  }

  async approveOrder(order_id, market_note = '') {
    const url = `${config.api}/v1/user/market/order-market/${order_id}/approve`
    const res = await http.put(url, { json: { market_note } })

    const { doc = {} } = this.toJS()
    const { status } = res.body || {}
    doc.status = status

    this.setOrder(doc)
  }

  async rejectOrder(order_id, reason = '') {
    const url = `${config.api}/v1/user/market/order-market/${order_id}/reject`
    const res = await http.put(url, { json: { reason } })

    const { doc = {} } = this.toJS()
    const { status, market_note } = res.body || {}
    doc.status = status
    doc.market_note = market_note || reason

    this.setOrder(doc)
  }

  setOrder(data) {
    runInAction(() => {
      state.doc = data
    })
  }

  setOffer({ list = [] }) {
    let total = 0
    for (const item of list) {
      const { total_price, deposit_type, deposit } = item
      const val = deposit_type === 'yes' ? deposit || 0 : 0
      total += total_price + val
    }
    runInAction(() => {
      state.offer = {
        total,
        list,
      }
    })
  }

  isInvalid(product, list, start_at, finish_at) {
    const start = timer.toStart(start_at)
    const finish = timer.toStart(finish_at)
    const { product_id } = product
    for (const item of list) {
      if (item.product_id === product_id) {
        const s = timer.toStart(item.start_at)
        const f = timer.toStart(item.finish_at)
        const inStart = timer.isBetween(start, s, f)
        const inFinish = timer.isBetween(finish, s, f)

        const in2Start = timer.isBetween(s, start, finish)
        const in2Finish = timer.isBetween(f, start, finish)

        if (inStart || inFinish || in2Start || in2Finish) {
          return 'unavailable'
        }
      }
    }

    return undefined
  }

  addOffer(product, option = {}) {
    const { offer = {} } = this.toJS()

    const list = offer.list || []
    let total = offer.total || 0

    const {
      detail,
      round,
      price,
      total_price,
      days,
      key,
      start_at,
      finish_at,
    } = option
    const {
      market_id,
      floorplan_id,
      unit_id,
      product_id,
      market_open_id,
      name,

      booking_type,
      type_clothes,
      type_drink,
      type_food,
      type_services,
      type_things,
      deposit_type,
      deposit,
    } = product

    const item = {
      market_id,
      floorplan_id,
      unit_id,
      product_id,
      market_open_id,
      name,

      detail,
      round,
      booking_type,
      type_clothes,
      type_drink,
      type_food,
      type_services,
      type_things,

      price,
      total_price,
      days,
      key,
      start_at,
      finish_at,
      deposit_type,
      deposit,
    }

    list.push(item)

    const val = deposit_type === 'yes' ? deposit || 0 : 0
    offer.total = total + total_price + val
    offer.list = list

    runInAction(() => {
      state.offer = offer
    })
  }

  removeOffer(product = {}, { key }) {
    const { offer = {} } = this.toJS()

    const { product_id } = product
    const list = offer.list || []
    const i = list.findIndex(
      (it) => it.product_id === product_id && it.key === key
    )
    if (i === -1) {
      return
    }

    let total = offer.total || 0
    const item = list[i]
    const { total_price, deposit_type, deposit } = item
    list.splice(i, 1)

    const val = deposit_type === 'yes' ? deposit || 0 : 0
    total = total - total_price - val

    offer.list = list
    offer.total = total

    runInAction(() => {
      state.offer = offer
    })
  }

  async offerOrder({ order_id, reason, offer_setting, total, list }) {
    const url = `${config.api}/v1/user/market/order-market/${order_id}/offer`
    const json = {
      market_note: reason,
      offer_setting,
      total,
      list,
    }
    const res = await http.put(url, { json })

    const data = res.body || {}

    runInAction(() => {
      state.doc = data
      state.offer = {
        total: 0,
        list: [],
      }
    })
  }

  async getMarketSetting(market_id) {
    const { setting } = this.toJS()
    if (setting.market_id === +market_id) {
      return
    }
    const url = `${config.api}/v1/public/market/setting/full/${market_id}`
    const res = await http.get(url)

    const data = res.body || {}
    const saleSetting = data.setting || {}
    const round_list = saleSetting.round_list || []
    saleSetting.start_at = timer.get(saleSetting.start_at)
    saleSetting.original_start_at = timer.get(saleSetting.original_start_at)
    saleSetting.finish_at = timer.get(saleSetting.finish_at)
    saleSetting.set_finish_at = timer.get(saleSetting.set_finish_at)
    saleSetting.round_list = round_list.map((it) => {
      const tag = it.tag || {}
      it.tag = {
        start_at: timer.get(tag.start_at),
        finish_at: timer.get(tag.finish_at),
      }
      return it
    })

    runInAction(() => {
      state.setting = saleSetting
    })
  }
}

export default new Order()
