import React from 'react'
import styled from 'styled-components'

const Line = (props) => {
  const { children } = props

  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default Line
