import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  bank_code: validator.getRuleString('ธนาคาร', 'choose'),
  account_name: validator.getRuleString('ชื่อบัญชี'),
  account_no: validator.getRuleString('หมายเลขบัญชี'),
  contact_phone: validator.getRuleSizeString('เบอร์โทร', 10, 11),
  contact_name: validator.getRuleString('ชื่อผู้ติดต่อ'),
}).options({ allowUnknown: true })

export default role
