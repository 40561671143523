import styled from 'styled-components'

import { Link } from 'components/link'

const url =
  'https://hlo-drive.sgp1.digitaloceanspaces.com/marget/web/no-content.svg'

const NoMarket = (props) => {
  return (
    <PageView>
      <Image src={url} />
      <Title>คุณยังไม่มีตลาด</Title>
      <Detail>มาร่วมเป็นเจ้าของพื้นที่</Detail>
      <Detail>เพียงแค่เพิ่มข้อมูลอีกเล็กน้อย</Detail>
      <Link to="/new-market">
        <Button>สร้างตลาด</Button>
      </Link>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};

  text-align: center;
  margin-bottom: 16px;
  padding: 20px;
`

const Image = styled.img`
  width: 220px;
  height: auto;
  margin-left: 50px;
  object-fit: cover;
  margin-top: 20px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-top: 22px;
  color: ${(p) => p.theme.color.blue};
`

const Detail = styled.div`
  font-size: 14px;
  line-height: 1.2;
  color: ${(p) => p.theme.color_level.grey.high};
`
const Button = styled.div`
  width: 214px;
  height: 48px;
  color: white;
  background-color: ${(p) => p.theme.color.blue};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default NoMarket
