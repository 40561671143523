import styled from 'styled-components'

import { Delete } from 'icon'

const Picture = (props) => {
  const { img, onClick = () => {}, onRemove, tag } = props

  const onDelete = () => onRemove(tag)
  const onSelect = () => onClick(tag)

  const btn = onRemove ? (
    <Btn onClick={onDelete}>
      <Delete />
    </Btn>
  ) : undefined

  return (
    <ContentView>
      {btn}
      <Image onClick={onSelect} src={img} />
    </ContentView>
  )
}

const ContentView = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
`

const Btn = styled.span`
  position: absolute;
  top: -16px;
  right: -12px;
  cursor: pointer;
  padding: 5px;
`

const Image = styled.img`
  width: 100%;
  height: 150px;
  background: ${(p) => p.theme.color.disable};
  border-radius: 4px;
  object-fit: contain;
`

export default Picture
