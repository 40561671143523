import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Market, Booking, Profile } from 'icon'

const UserMenu = (props) => {
  const { visible, selected } = props
  const navigate = useNavigate()

  if (visible === false) {
    return <span />
  }

  const getColor = (txt) => {
    return selected === txt ? '#305FED' : '#494949'
  }

  const getLink = (txt, link) => {
    return selected === txt ? undefined : () => navigate(link)
  }

  const marketColor = getColor('market')
  const bookingColor = getColor('booking')
  const profileColor = getColor('account')

  return (
    <Wrapper>
      <ContentView>
        <Btn onClick={getLink('market', '/dashboard')}>
          <Market color={marketColor} />
          <Label style={{ color: marketColor }}>ตลาด</Label>
        </Btn>
        <Btn onClick={getLink('booking', '/booking')}>
          <Booking color={bookingColor} />
          <Label style={{ color: bookingColor }}>การจอง</Label>
        </Btn>
        <Btn onClick={getLink('account', '/account')}>
          <Profile color={profileColor} />
          <Label style={{ color: profileColor }}>บัญชีของฉัน</Label>
        </Btn>
      </ContentView>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10;
`

const ContentView = styled.div`
  width: 100%;
  max-width: 800px;
  height: 90px;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
`

const Btn = styled.div`
  text-align: center;
  width: 100px;
  cursor: pointer;
`

const Label = styled.div`
  font-size: 12px;
`

export default UserMenu
