import styled from 'styled-components'

import { Change, DeleteV2 } from 'icon'

const PopupMenu = (props) => {
  const { no = 1, onEdit, onEditOrder, onDelete } = props

  return (
    <PageView>
      <Item onClick={onEdit}>
        <Change color="white" /> เปลี่ยนรูปผัง
      </Item>
      <Dot />
      <Item onClick={onEditOrder}>
        <No>{no}</No> เปลี่ยนลำดับ
      </Item>
      <Dot />
      <Item onClick={onDelete}>
        <DeleteV2 color="white" /> ลบรูปผัง
      </Item>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: white;
  padding: 4px 8px;
`

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
  font-size: 14px;
`

const Dot = styled.div`
  border: 0px dashed white;
  border-bottom-width: 1px;
  margin: 8px 0px;
`

const No = styled.div`
  color: ${(p) => p.theme.color.blue};
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
`

export default PopupMenu
