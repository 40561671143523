import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content, Row, Col } from 'components/display'
import { FindInput } from 'components/input'
import { DatePicker } from 'components/date'
import { timer } from 'utils'

import Header from './Header'
import Product from './Product'

const getMinDate = () => {
  return timer.getDate().subtract(3, 'days').toDate()
}

const getMaxDate = () => {
  return timer.getDate().add(6, 'months').toDate()
}

const ProductList = (props) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [limit] = useState({
    min: getMinDate(),
    max: getMaxDate(),
  })
  const [date, setDate] = useState(timer.getDate())
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id } = useParams()

  const { is_all, product_list } = props.product.toJS()

  const getProduct = useCallback(async () => {
    try {
      setLoading(true)
      await props.product.getProductList({ market_id, date })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.product, market_id, date])

  useEffect(() => {
    getProduct()
  }, [getProduct])

  const onCloseError = () => setErr('')
  const onBack = () => navigate(`/market/${market_id}`)
  const onFilter = (val) => setFilter(val)

  const onAllChange = async (val) => {
    try {
      setLoading(true)
      const list = filter === '' ? product_list : getFilterList()

      const status = val ? 'active' : 'inactive'
      await props.product.setAllProductStatus({ market_id, date, status }, list)
      await props.product.getProductList({ market_id, date })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onChange = async (val, { product_id } = {}) => {
    try {
      const status = val ? 'active' : 'inactive'
      await props.product.setProductStatus({ product_id, date, status })
    } catch (e) {
      setErr(e.message)
    }
  }

  const getFilterList = () => {
    if (filter === '') {
      return product_list
    }

    const text = filter.toLowerCase()
    return product_list.filter((it) => {
      const name = (it.unit_name || '').toLowerCase()
      return name.includes(text)
    })
  }

  const list = getFilterList()
  const content = list.map((it, i) => {
    return <Product key={i} doc={it} onChange={onChange} />
  })
  return (
    <PageView>
      <Meta title="Market - จัดการล็อก" description="จัดการล็อก" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Row align="middle">
          <Col lg={18} md={18} xs={12}>
            <Title>ล็อกที่เปิดจอง</Title>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <DatePicker
              placeholder="วันที่ขาย"
              value={date}
              onChange={(val) => setDate(val)}
              min={limit.min}
              max={limit.max}
            />
          </Col>
        </Row>

        <FindInput
          placeholder="ค้นหาหมายเลขล็อก"
          value={filter}
          onChange={onFilter}
        />

        <Header
          isCheck={is_all}
          disabled={list.length === 0}
          onChange={onAllChange}
        />
        {content}
      </Content>

      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 18px;
`

export default inject('product')(observer(ProductList))
