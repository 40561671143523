import styled from 'styled-components'

import { Link } from 'components/link'
import { product } from 'utils'

import Header from './Header'
import Information from './Information'

const Card = (props) => {
  const { item = {}, status } = props
  const { market_id, code } = item
  const { text, color } = product.getStatus(item)
  return (
    <Link to={`/market/${market_id}/booking/${code}?status=${status}`}>
      <PageView>
        <Header code={code} color={color} />
        <Information item={item} text={text} color={color} />
      </PageView>
    </Link>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: #ffffff;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 15px 19px;
`

export default Card
