import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { FindInput } from 'components/input'

import Unit from './Unit'

const UnitList = (props) => {
  const [filter, setFilter] = useState('')

  const { doc = {}, unit_list = [] } = props
  const isReadonly = doc.status !== 'active'

  const onFilter = (val) => setFilter(val)
  const getFilterList = () => {
    if (filter === '') {
      return unit_list
    }

    const keyword = filter.toLowerCase()
    return unit_list.filter((it) => {
      const name = (it.name || '').toLowerCase()
      return name.includes(keyword)
    })
  }

  const list = getFilterList()
  const content = list.map((it, i) => {
    return <Unit key={i} isReadonly={isReadonly} unit={it} doc={doc} />
  })

  return (
    <PageView>
      <Label>ล็อกให้เช่า</Label>
      <FindInput
        placeholder="ค้นหาหมายเลขล็อก"
        value={filter}
        onChange={onFilter}
      />
      <ContentView>{content}</ContentView>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
`

const Label = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
`

const ContentView = styled.div`
  width: 100%;
`

export default inject('promotion')(observer(UnitList))
