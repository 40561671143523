import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { SelectList } from 'components/input'
import { market } from 'utils'

import Label from '../Label'

const OpenTime = (props) => {
  const [menu, setMenu] = useState([])

  const { doc = {}, validate, onChange } = props
  const marketItem = props.floorplan.toJS().market

  useEffect(() => {
    const open_time_list = marketItem.open_time_list || []

    const list = open_time_list.map((it) => {
      const name = market.toOpenTimeItem(it)
      return { name, value: it.market_open_id }
    })
    setMenu(list)
  }, [marketItem.market_id])

  const onOpenTime = (val) => {
    doc.market_open_id = val
    onChange(doc)
  }

  return (
    <PageView>
      <Label text="รอบเวลา" padding={false} />
      <Info>(กรุณาเพิ่มครั้งละ 1 รอบ กรณีล็อกนี้มีหลายรอบเวลา)</Info>
      <SelectList
        placeholder="เลือกเวลา"
        menu={menu}
        name="market_open_id"
        value={doc.market_open_id}
        onChange={onOpenTime}
        invalid={validate['market_open_id']}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const Info = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default inject('floorplan')(observer(OpenTime))
