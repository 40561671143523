import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { MsgDialog } from 'dialog'
import { Row, Col } from 'components/display'
import { Checker } from 'components/input'
import { format, market } from 'utils'
import { Edit } from 'icon'

import PriceDrawer from './price'
import { col } from './col'

const Product = (props) => {
  const [visible, setVisible] = useState(false)
  const [err, setErr] = useState('')
  const { last = {}, order_id, filter = {}, doc = {}, selected = {} } = props

  const { list = [] } = selected
  const isName = last.unit_id !== doc.unit_id
  const openTime = market.toOpenTimeItem(doc)
  const order = doc.order || {}
  const { key } = filter
  console.log('product:', doc)
  const index = list.findIndex(
    (it) => it.product_id === doc.product_id && it.key === key
  )
  const isCheck = index > -1
  const isActive = doc.status === 'active' || order_id === order.order_id

  const onCloseError = () => setErr('')

  const getStatus = () => {
    const shop_info = doc.shop_info || {}
    return <ShopName>{shop_info.name}</ShopName>
  }

  const getReady = () => {
    return <Ready>ว่าง</Ready>
  }

  const onCheck = (val, name, tag) => {
    if (val) {
      const { price, total_price, days } = tag
      const { start_at, finish_at } = filter
      const invalid = props.order.isInvalid(doc, list, start_at, finish_at)

      if (invalid === 'unavailable') {
        setErr('รอบและวันที่ขายมีอยู่แล้ว')
        return
      }

      const round = market.toOpenTimeItem(doc)
      const detail = market.toProductType(doc)
      props.order.addOffer(doc, {
        round,
        detail,
        price,
        total_price,
        days,
        key,
        start_at,
        finish_at,
      })
    } else {
      props.order.removeOffer(doc, { key })
    }
  }

  const getPrice = () => {
    if (isCheck) {
      return list[index]
    }
    const { start_at, finish_at, days_list } = filter
    const price = market.toPrice(doc, filter)
    const calcPrice = market.calcPrice(
      doc,
      start_at,
      finish_at,
      price,
      days_list
    )
    calcPrice.price = price
    return calcPrice
  }

  const getChecker = (price) => {
    return <Checker value={isCheck} onChange={onCheck} tag={price} />
  }

  const onClose = () => setVisible(false)
  const onVisible = () => setVisible(true)
  const onSave = (val) => {
    list[index].price_type = 'offer_price'
    list[index].total_price = val
    props.order.setOffer({ list })
    onClose()
  }

  const name = isName ? <Name>{doc.name}</Name> : ''
  const content = isActive ? getReady() : getStatus()
  const tag = isActive
    ? getPrice(isCheck, index)
    : { total_price: order.total_price }
  const check = isActive ? getChecker(tag) : undefined
  const btn = isCheck ? (
    <span onClick={onVisible}>
      <Edit size={0.8} />
    </span>
  ) : undefined

  return (
    <PageView>
      <Row>
        <Col {...col.checker} position="left">
          {check}
        </Col>
        <Col {...col.name} position="center">
          {name}
        </Col>
        <Col {...col.detail} position="center">
          <Time>{openTime}</Time>
        </Col>
        <Col {...col.type} position="center">
          {content}
        </Col>
        <Col {...col.price} position="right">
          <Price>
            {format.toDigi(tag.total_price)}
            {btn}
          </Price>
        </Col>
      </Row>
      <PriceDrawer
        visible={visible}
        value={tag.total_price}
        onClose={onClose}
        onSave={onSave}
      />
      <MsgDialog message={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Name = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.green};
  font-size: 14px;
  font-weight: bold;
  border-radius: 12px;
  padding: 1px 8px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
`

const Time = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  font-weight: bold;
`

const ShopName = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  font-weight: bold;
`

const Ready = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.green};
  font-size: 14px;
  border-radius: 12px;
  padding: 1px 8px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
`

const Price = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
`

export default inject('order')(observer(Product))
