import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Product extends BaseStore {
  constructor() {
    super()
    this.observable({
      is_all: false,
      product_list: [],
      ready_product_list: [],
    })
    state = this
  }

  checkIsAll(list = []) {
    const i = list.findIndex((it) => it.status !== 'active')

    return i === -1
  }

  async getProductList(query = {}) {
    const { market_id, date } = query

    const str = date.format('DDMMYYYY')
    const url = `${config.api}/v1/user/market/product/${market_id}/${str}/available`
    const res = await http.get(url)

    const list = res.body || []
    const is_all = this.checkIsAll(list)

    runInAction(() => {
      state.is_all = is_all
      state.product_list = list
    })
  }

  async setAllProductStatus({ market_id, date, status }, product_list = []) {
    const str = date.format('DDMMYYYY')
    const url = `${config.api}/v1/user/market/product/${market_id}/${str}/all-status/${status}`
    await http.put(url, { json: { product_list } })
  }

  async setProductStatus({ product_id, date, status }) {
    const str = date.format('DDMMYYYY')
    const url = `${config.api}/v1/user/market/product/${product_id}/${str}/status/${status}`
    await http.put(url, {})

    const product_list = this.toJS().product_list || []
    const i = product_list.findIndex((it) => it.product_id === product_id)

    if (i > -1) {
      product_list[i].status = status
      const is_all = this.checkIsAll(product_list)
      runInAction(() => {
        state.is_all = is_all
        state.product_list = product_list
      })
    }
  }

  async getBookingProductList(query = {}) {
    const { market_id, date } = query

    const t = 'DDMMYYYY'
    const str = date.format(t)
    const url = `${config.api}/v1/user/market/product/${market_id}/${str}/booking`
    const res = await http.get(url)

    const list = res.body.unit_list || []

    runInAction(() => {
      state.ready_product_list = list
    })
  }

  async getRangeBookingList(query = {}) {
    const { market_id, booking_type, start_at, finish_at } = query

    const t = 'DDMMYYYY'
    const s = start_at.format(t)
    const f = finish_at.format(t)
    const q = `?booking_type=${booking_type}`
    const url = `${config.api}/v1/user/market/product/${market_id}/${s}/${f}/booking${q}`
    const res = await http.get(url)

    const list = res.body.unit_list || []

    runInAction(() => {
      state.ready_product_list = list
    })
  }
}

export default new Product()
