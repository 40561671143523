import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Dot, Col } from 'components/display'
import { BookingTypeName } from 'components/market/components'
import { format, market } from 'utils'
import { ConfirmDialog } from 'dialog'

import Price from './Price'

const Product = (props) => {
  const [visible, setVisible] = useState(false)

  const { doc = {}, onEdit, onDelete } = props

  const onEditProduct = () => onEdit(doc)
  const onClose = () => setVisible(false)
  const onConfirm = () => onDelete(doc)

  const getText = (label, text) => {
    return (
      <Text>
        <span>{label}</span>
        {text}
      </Text>
    )
  }

  const openTime = market.toOpenTimeItem(doc)
  const productType = market.toProductType(doc)
  const banProduct = market.toBanProduct(doc)
  const deposit =
    doc.deposit_type === 'yes' ? `มี (${format.toDigi(doc.deposit)})` : 'ไม่มี'

  return (
    <Card>
      <Row>
        <Col span={12} position="left">
          <TextTime>รอบ</TextTime>
        </Col>
        <Col span={12} position="right">
          <Right>
            <BookingTypeName booking_type={doc.booking_type} />
          </Right>
        </Col>
      </Row>
      <Time>{openTime}</Time>

      <Dot css={{ margin: '24px 0px' }} />

      <Detail>
        {getText('โซน', productType)}
        {getText('มัดจำ/ประกัน', deposit)}
        {getText('ห้ามนำเข้า', banProduct)}
      </Detail>

      <Price
        doc={doc}
        onEdit={onEditProduct}
        onDelete={() => setVisible(true)}
      />
      <ConfirmDialog
        visible={visible}
        title="ยืนยันการลบรอบเวลา"
        message="คุณต้องการที่จะลบรอบเวลา ใช่หรือไม่?"
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </Card>
  )
}

const Card = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Right = styled.div`
  text-align: right;
`

const TextTime = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.grey};
  line-height: 1.2;
`

const Time = styled.div`
  width: 100%;
  font-size: 32px;
  color: ${(p) => p.theme.color.green};
  font-weight: bold;
  line-height: 1.2;
`

const Detail = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const Text = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};

  span {
    font-weight: bold;
    margin-right: 8px;
  }
`

export default Product
