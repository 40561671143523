import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'
import { Input } from 'components/input'
import { PrimaryBtn } from 'components/button'

const Price = (props) => {
  const [price, setPrice] = useState(0)

  const { visible, value, onClose, onSave = () => {} } = props

  useEffect(() => {
    if (visible) {
      setPrice(value)
    }
  }, [visible])

  const onChange = (v) => setPrice(v)
  const onClick = () => onSave(+price)

  return (
    <BaseDrawer
      visible={visible}
      title="เสนอราคาใหม่"
      height="400px"
      onClose={onClose}
    >
      <PageView>
        <Note>
          <Input
            label="ราคาใหม่"
            value={price}
            onChange={onChange}
            isNumber={true}
          />
        </Note>

        <Button>
          <PrimaryBtn text="ตกลง" onClick={onClick} />
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Note = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Button = styled.div`
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
  padding-top: 16px;
`

export default Price
