import { forwardRef } from 'react'
import styled, { useTheme } from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker'
import th from 'date-fns/locale/th'

import { Calendar } from 'icon'
import { format, timer } from 'utils'

import Invalid from './Invalid'
import { renderHeader } from './Header'

registerLocale('th', th)

const toDate = (txt) => {
  const t = txt.trim()
  if (t === '') return ''

  const date = format.toThaiDate(txt, 'MM/DD/YYYY')
  return date
}

const Picker = (props) => {
  const theme = useTheme()
  const {
    placeholder,
    disabled = false,
    start_at,
    finish_at,
    min,
    max,
    days_list,
    readonly = false,
    invalid,
    name,
    onChange = () => {},
  } = props

  const getValue = (val) => {
    if (typeof val === 'string' || val instanceof String) {
      return timer.get(val).toDate()
    } else if (val) {
      return timer.get(val).toDate()
    }

    return val
  }

  const onValue = ([s1, s2]) => {
    const s = s1 ? timer.get(s1) : null
    const f = s2 ? timer.get(s2) : null
    onChange({ start_at: s, finish_at: f }, name)
  }

  const onFilterDate = (date) => {
    const day = date.getDay()
    return days_list.includes(day)
  }

  const onCalendarClose = () => {
    if (start_at && !finish_at) {
      onChange({ start_at, finish_at: start_at }, name)
    }
  }

  const start = getValue(start_at)
  const finish = getValue(finish_at)
  const css = start || finish ? 'fill' : 'empty'
  const readonlyCss = disabled || readonly ? 'picker-readonly' : ''
  const invalidCss = invalid ? 'invalid' : ''

  const color = invalid ? theme.color.error : undefined

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const list = (value || '').split('-')
    const start = list.length > 0 ? toDate(list[0]) : ''
    const finish = list.length > 1 ? toDate(list[1]) : ''
    const text =
      start === finish || finish === '' ? start : `${start} - ${finish}`
    return (
      <Text className={`${css}`} onClick={onClick} ref={ref}>
        {text}

        <Icon>
          <Calendar color={color} />
        </Icon>
      </Text>
    )
  })

  CustomInput.displayName = 'range-input'

  return (
    <PageView className={`${css} ${readonlyCss} ${invalidCss}`}>
      <Label className={css}>{placeholder}</Label>
      <DatePicker
        locale="th"
        selectsRange={true}
        startDate={start}
        endDate={finish}
        placeholderText={placeholder}
        disabled={disabled || readonly}
        className={css}
        minDate={min}
        maxDate={max}
        onChange={onValue}
        customInput={<CustomInput />}
        renderCustomHeader={renderHeader}
        onCalendarClose={onCalendarClose}
        filterDate={days_list ? onFilterDate : undefined}
      />
      <Invalid invalid={invalid} />
    </PageView>
  )
}

const PageView = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    padding: 4px 11px;
    background-color: white;
  }

  .react-datepicker-wrapper > .react-datepicker__input-container {
    width: 100%;
    height: 44px;
    border: 0px;
    outline: none;
    padding: 0px;
  }

  .react-datepicker-popper {
    z-index: 5;
  }

  .invalid {
    border-color: ${(p) => p.theme.color.red};
  }

  &.picker-readonly > .react-datepicker-wrapper {
    background-color: #f5f6f9;
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.medium};
  position: absolute;
  left: 10px;
  top: 2px;
  z-index: 1;
  transition: all 0.3s ease;
  cursor: pointer;

  &.empty {
    top: 16px;
    color: ${(p) => p.theme.color_level.grey.high};
  }
`

const Icon = styled.div`
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 10px;
`

const Text = styled.div`
  position: relative;
  font-size: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
  width: 100%;
  height: 44px;
  border: 0px;
  outline: none;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.fill {
    padding-top: 10px;
  }
`

export default Picker
