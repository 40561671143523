import styled from 'styled-components'

const BookingType = (props) => {
  const { item = {} } = props

  const tag_list = [
    { name: 'booking_daily', text: 'รายวัน', color: '#F55759' },
    { name: 'booking_monthly', text: 'รายเดือน', color: '#577AF5' },
    { name: 'booking_round', text: 'บูธอีเวนท์', color: '#00C767' },
  ]

  const content = []
  for (const tag of tag_list) {
    if (item[tag.name] === 'yes') {
      const backgroundColor = tag.color
      content.push(
        <Tag key={tag.name} style={{ backgroundColor }}>
          {tag.text}
        </Tag>
      )
    }
  }

  return <TagLine>{content}</TagLine>
}

const TagLine = styled.div`
  color: white;
  font-size: 12px;
  text-align: left;
`

const Tag = styled.span`
  margin-right: 4px;
  border-radius: 4px;
  padding: 0px 4px;
`

export default BookingType
