import styled from 'styled-components'

const Pin = (props) => {
  const { text, size = 20, style = {}, onClick } = props
  const s = `${size}px`
  style.width = s
  style.height = s

  return (
    <PinView style={style} onClick={onClick}>
      {text}
    </PinView>
  )
}

const PinView = styled.span`
  position: absolute;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 50%;
  opacity: 0.7;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

export default Pin
