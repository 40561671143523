import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { RadioItem, Input } from 'components/input'

import Label from '../Label'

const BookingType = (props) => {
  const { doc = {}, validate = {}, onChange } = props

  const onEnable = () => {
    doc.deposit_type = 'yes'
    onChange(doc)
  }

  const onDisable = () => {
    doc.deposit_type = 'no'
    onChange(doc)
  }

  const onPrice = (val) => {
    doc.deposit = val
    onChange(doc)
  }

  const isDeposit = doc.deposit_type === 'yes'
  return (
    <PageView>
      <Label text="มัดจำประกัน" padding={false} />
      <BodyContent>
        <Row align="middle">
          <Col span={10} position="left">
            <RadioItem
              label="มี"
              name="deposit_type"
              value={isDeposit}
              onChange={onEnable}
            />
          </Col>
          <Col span={14} position="right">
            <Input
              label="ระบุจำนวน (บาท)"
              name="deposit"
              value={doc.deposit}
              disabled={!isDeposit}
              onChange={onPrice}
              isNumber={true}
              invalid={validate['deposit']}
            />
          </Col>
        </Row>

        <RadioItem
          label="ไม่มี"
          name="deposit_type"
          value={!isDeposit}
          onChange={onDisable}
        />
      </BodyContent>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const BodyContent = styled.div`
  width: 100%;
  padding-top: 12px;
`

export default BookingType
