import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  name: validator.getRuleString('หมายเลขล็อก'),
  be_wide: validator.getRuleString('กว้าง (ม.)'),
  be_long: validator.getRuleString('ลึก (ม.)'),
}).options({ allowUnknown: true })

export default role
