import { useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Row, Col } from 'components/display'
import { PrimaryBtn } from 'components/button'
import { ErrorDialog } from 'dialog'
import Loading from 'components/loading'
import { message } from 'utils'

import Label from '../Label'
import ProductInfo from '../ProductInfo'
import Product from './Product'

const original = {
  booking_type: 'daily',
  deposit_type: 'no',
}

const ProductList = (props) => {
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState(undefined)
  const [err, setErr] = useState('')

  const { market_id, unit_id } = props
  const { product_list } = props.unit.toJS()

  const onAdd = () => setProduct({ ...original })
  const onClose = () => setProduct(undefined)
  const onCloseError = () => setErr('')

  const onSave = async () => {
    try {
      setLoading(true)
      const { product_id } = product || {}
      await props.unit.updateProduct(
        { market_id, unit_id, product_id },
        product
      )
      message.success()
      onClose()
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onDelete = async (val) => {
    try {
      setLoading(true)
      const { product_id } = val || {}
      await props.unit.deleteProduct({ product_id })
      onClose()
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onEdit = (val) => setProduct({ ...val })

  const content = product_list.map((it, i) => {
    return <Product key={i} doc={it} onEdit={onEdit} onDelete={onDelete} />
  })

  return (
    <PageView>
      <Row align="middle">
        <Col lg={19} md={18} xs={16} position="left">
          <Label text="รอบเวลาเช่า" />
        </Col>
        <Col lg={5} md={6} xs={8} position="right">
          <PrimaryBtn text="เพิ่มรอบ" onClick={onAdd} />
        </Col>
      </Row>
      <ContentView>
        <Loading loading={loading}>{content}</Loading>
      </ContentView>

      <ProductInfo
        visible={product !== undefined}
        loading={loading}
        doc={product}
        onSave={onSave}
        onChange={(v) => setProduct({ ...v })}
        onClose={onClose}
        onDelete={onDelete}
      />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
`

const ContentView = styled.div`
  width: 100%;
`

export default inject('unit')(observer(ProductList))
