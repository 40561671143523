import { runInAction } from 'mobx'

import { http, error, helper } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Market extends BaseStore {
  constructor() {
    super()
    this.observable({
      dashboard: {
        isMore: false,
        total: 0,
        list: [],
      },
      market_list: [],
      doc: {},
      counter: {
        new_order: 0,
        unit_total: 0,
        round_total: 0,
        ready_round: 0,
      },
    })
    state = this
  }

  async getMyMarketList(query = {}) {
    const { market_list } = this.toJS()
    if (market_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/user/market/market-info`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.market_list = list
    })
  }

  async getMarketWithOrder(query = {}) {
    const url = `${config.api}/v1/user/market/market-info/order/available`
    const res = await http.get(url)

    const { isMore, total, list } = res.body || {}
    runInAction(() => {
      state.dashboard = {
        isMore,
        total,
        list,
      }
    })
  }

  async getMarketInfo(id) {
    const doc = this.toJS().doc

    if (doc.market_id === +id && doc.isEdit !== true) {
      return
    }

    const url = `${config.api}/v1/user/market/market-info/${id}`
    const res = await http.get(url)

    const data = res.body || {}
    runInAction(() => {
      state.doc = data
    })
  }

  async getMarketCounter(id) {
    const url = `${config.api}/v1/user/market/order-market/${id}/counter/today`
    const res = await http.get(url)

    const data = res.body || {}
    runInAction(() => {
      state.counter = {
        new_order: data.new_order || 0,
        unit_total: data.unit_total || 0,
        round_total: data.round_total || 0,
        ready_round: data.ready_round || 0,
      }
    })
  }

  async updateImg({ market_id, image, index }) {
    const url = `${config.api}/v1/user/market/market-info/${market_id}/image`
    const json = { image, index }

    const res = await http.put(url, { json, check: false })

    const isError = res.status !== 200
    const { img_url, message } = res.body
    return { isError, img_url, index, message }
  }

  async updateFloorplanImg(doc) {
    const { market_id } = doc
    const floor_plan_url = doc.floor_plan_url || ''
    if (floor_plan_url === '' || helper.isUrl(floor_plan_url)) {
      return doc
    }

    const res = await this.updateImg({ market_id, image: floor_plan_url })
    const { isError, img_url, message } = res
    error.isError(isError, message)

    doc.floor_plan_url = img_url
    runInAction(() => {
      state.doc = doc
    })

    return doc
  }

  async updateImgList(doc) {
    const { market_id, img_list } = doc

    const upload_list = []
    let index = 0
    for (const image of img_list) {
      if (helper.isUrl(image) === false) {
        upload_list.push(this.updateImg({ market_id, image, index }))
      }

      index++
    }

    const list = await Promise.all(upload_list)

    let errorMessage = null
    for (const item of list) {
      const { isError, img_url, index, message } = item
      if (isError) {
        errorMessage = message
      } else {
        img_list[index] = img_url
      }
    }

    doc.img_list = img_list
    runInAction(() => {
      state.doc = doc
    })

    error.isError(errorMessage !== null, errorMessage)
    return { ...doc }
  }

  async updateInfo(doc) {
    let json = await this.updateFloorplanImg(doc)
    json = await this.updateImgList(json)

    const url = `${config.api}/v1/user/market/market-info/${doc.market_id}/information`

    delete json.detail
    const res = await http.put(url, { json })

    const data = res.body
    runInAction(() => {
      state.doc = {
        ...doc,
        ...data,
      }
    })
  }

  async updateBank(doc) {
    const url = `${config.api}/v1/user/market/market-info/${doc.market_id}/banking`
    const json = { ...doc }

    delete json.img_list
    delete json.detail
    await http.put(url, { json })
  }

  async updateOther(doc) {
    const url = `${config.api}/v1/user/market/market-info/${doc.market_id}/other`
    const json = { ...doc }

    delete json.img_list
    await http.put(url, { json })
  }

  setMarket(data) {
    data.isEdit = true
    runInAction(() => {
      state.doc = data
    })
  }
}

export default new Market()
