import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content, Tab } from 'components/display'

import Card from './Card'

const BookingList = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const [status, setStatus] = useState('create')

  const navigate = useNavigate()
  const { market_id } = useParams()

  const location = useLocation()

  const query = queryString.parse(location.search)

  const { order, counter } = props.order.toJS()
  const list = order.list || []
  const content = list.map((it, i) => {
    return <Card item={it} status={status} key={i} />
  })

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      const s = query.status ? query.status : 'create'
      setStatus(s)
      await Promise.all([
        props.order.countOrder({ market_id }),
        props.order.getOrderList({ market_id, status: s }),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.order, market_id])

  const getOrderList = async (s) => {
    try {
      setLoading(true)
      await props.order.getOrderList({ market_id, status: s })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onCloseError = () => setErr('')
  const onStatus = (val) => {
    setStatus(val)
    getOrderList(val)
  }

  const onBack = () => {
    if (market_id) {
      navigate(`/market/${market_id}`)
    } else {
      navigate('/dashboard')
    }
  }

  const getLabel = (label, counter) => {
    return (
      <Label>
        {label}
        <Counter>{counter}</Counter>
      </Label>
    )
  }

  const menu = [
    { label: getLabel('รายการใหม่', counter.create), value: 'create' },
    { label: getLabel('เสนอใหม่', counter.offer), value: 'offer' },
    { label: getLabel('รอชำระเงิน', counter.payment), value: 'payment' },
    { label: getLabel('สำเร็จ', counter.completed), value: 'completed' },
    { label: getLabel('ยกเลิก', counter.cancelled), value: 'cancelled' },
  ]

  return (
    <PageView>
      <Meta title="Market - Booking List" description="Booking List" />
      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Menu>
          <Tab menu={menu} selected={status} onChange={onStatus} />
        </Menu>

        {content}
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Menu = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 15px;
`

const Label = styled.div`
  width: 100%;
  min-width: 125px;
  display: flex;
  justify-content: center;
`

const Counter = styled.div`
  color: white;
  background-color: ${(p) => p.theme.color.blue};
  border-radius: 50%;
  min-width: 23px;
  height: fit-content;
  margin-left: 8px;
`

export default inject('order')(observer(BookingList))
