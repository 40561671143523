import styled from 'styled-components'
import { Row, Col } from 'antd'

import { market } from 'utils'

import { MarketDetail } from './components'
import MarketType from './MarketType'

const MarketInfo = (props) => {
  const { doc = {} } = props
  const { name } = doc

  return (
    <PageView>
      <Name>{name}</Name>

      <MarketDetail item={doc} />

      <Row style={{ paddingTop: '8px' }}>
        <Col span={12}>
          <OpenTime>{market.toOpenTime(doc)}</OpenTime>
        </Col>
        <Col span={12}>
          <MarketType item={doc} />
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const OpenTime = styled.div`
  font-size: 14px;
`

const Name = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default MarketInfo
