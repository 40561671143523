import { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Link } from 'components/link'
import { Col } from 'components/display'
import { Up, Down, Edit, DeleteV2, Copy } from 'icon'
import { ConfirmDialog } from 'dialog'

import Product from './Product'

const Unit = (props) => {
  const [visible, setVisible] = useState(false)
  const {
    index,
    last_index,
    doc = {},
    onDelete,
    onClone,
    onUpdateOrder,
  } = props

  const { market_id, floorplan_id, unit_id } = doc
  const product_list = doc.product_list || []

  const onConfirmDelete = () => setVisible(true)
  const onCloseDelete = () => setVisible(false)
  const onDeleteItem = () => onDelete(doc)

  const onCloneItem = () => onClone(doc)
  const onUpItem = () => onUpdateOrder({ index_a: index, index_b: index - 1 })
  const onDownItem = () => onUpdateOrder({ index_a: index, index_b: index + 1 })

  const last = product_list.length - 1
  const content = product_list.map((it, i) => {
    return <Product key={i} product={it} isLast={i === last} />
  })

  const upDisable = index === 1
  const upColor = upDisable ? '#BBBBBB' : undefined
  const downDisable = index === last_index
  const downColor = downDisable ? '#BBBBBB' : undefined

  return (
    <Card>
      <Title>{doc.name}</Title>
      <PageView>
        <Menu>
          <Row>
            <Col span={8}>
              <Left>
                <LeftBtn
                  className={upDisable ? 'disable' : ''}
                  onClick={upDisable ? undefined : onUpItem}
                >
                  <Up color={upColor} />
                </LeftBtn>
                <LeftBtn
                  className={downDisable ? 'disable' : ''}
                  onClick={downDisable ? undefined : onDownItem}
                >
                  <Down color={downColor} />
                </LeftBtn>
              </Left>
            </Col>
            <Col span={16}>
              <Right>
                <Link
                  to={`/market/${market_id}/unit/${floorplan_id}/${unit_id}`}
                >
                  <LinkBtn>
                    <Edit />
                  </LinkBtn>
                </Link>
                <Btn onClick={onConfirmDelete}>
                  <DeleteV2 />
                </Btn>
                <Btn onClick={onCloneItem}>
                  <Copy />
                </Btn>
              </Right>
            </Col>
          </Row>
        </Menu>
        {content}
      </PageView>
      <ConfirmDialog
        visible={visible}
        title="ยืนยันการลบล็อก"
        message="คุณต้องการที่จะลบล็อก ใช่หรือไม่?"
        onClose={onCloseDelete}
        onConfirm={onDeleteItem}
      />
    </Card>
  )
}

const Card = styled.div`
  width: 100%;
`

const PageView = styled.div`
  width: 100%;
  padding: 30px 20px 20px 20px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Title = styled.div`
  min-width: 64px;
  max-width: 180px;
  height: 52px;
  font-size: 32px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 25px;
  margin: 0 auto;
`

const Menu = styled.div`
  padding: 10px 0px;
`

const Left = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const LinkBtn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 3px;
`

const LeftBtn = styled.span`
  margin-right: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.disable {
    cursor: default;
  }
`

const Btn = styled.span`
  margin-left: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export default Unit
