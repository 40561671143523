import { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'

import { Edit } from 'icon'
import { ConfirmDialog } from 'dialog'
import ViewFloorplan from 'components/floorplan/ViewFloorplan'

import FloorplanOrderDialog from './FloorplanOrderDialog'
import PopupMenu from './PopupMenu'

const FloorImage = (props) => {
  const [visible, setVisible] = useState(false)
  const [orderVisible, setOrderVisible] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [orderLoading, setOrderLoading] = useState(false)
  const {
    isReady,
    floorplan,
    floorplan_list = [],
    unit_list = [],
    onEdit,
    onEditOrder,
    onDelete,
  } = props

  const onEditItem = () => {
    onEdit(floorplan)
    setVisible(false)
  }

  const onEditOrderItem = () => {
    setOrderVisible(true)
    setVisible(false)
  }

  const onDeleteItem = () => {
    setConfirm(true)
    setVisible(false)
  }

  const onConfirmClose = () => setConfirm(false)

  const onConfirmDelete = () => {
    onConfirmClose()
    onDelete(floorplan)
  }

  const onOrderClose = () => setOrderVisible(false)
  const onOrderConfirm = async (i) => {
    setOrderLoading(true)
    await onEditOrder(i)
    onOrderClose()
    setOrderLoading(false)
  }

  const index = floorplan_list.findIndex(
    (it) => it.floorplan_id === floorplan.floorplan_id
  )
  const no = index + 1
  const content = (
    <PopupMenu
      no={no}
      onEdit={onEditItem}
      onEditOrder={onEditOrderItem}
      onDelete={onDeleteItem}
    />
  )

  const pin_list = unit_list.map((it) => {
    return {
      x: it.axis_x,
      y: it.axis_y,
      unit: it,
    }
  })

  return (
    <PageView>
      <ViewFloorplan
        visible={isReady}
        img_url={floorplan.img_url}
        size={15}
        height="327px"
        pin_list={pin_list}
      />
      <Popover
        content={content}
        overlayClassName="popup"
        placement="bottomRight"
        trigger="click"
        visible={visible}
        onVisibleChange={(v) => setVisible(v)}
      >
        <Icon>
          <Edit />
        </Icon>
      </Popover>
      <ConfirmDialog
        visible={confirm}
        title="ยืนยันการลบแผนผัง"
        message="คุณต้องการที่จะลบแผนผัง ใช่หรือไม่?"
        onClose={onConfirmClose}
        onConfirm={onConfirmDelete}
      />
      <FloorplanOrderDialog
        visible={orderVisible}
        loading={orderLoading}
        index={no}
        size={floorplan_list.length}
        onClose={onOrderClose}
        onConfirm={onOrderConfirm}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: white;
  padding: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  position: relative;
`

const Icon = styled.span`
  position: absolute;
  top: 36px;
  right: 36px;
  height: 15px;
  cursor: pointer;
`

export default FloorImage
