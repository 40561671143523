import styled from 'styled-components'

import { Remove } from 'icon'
import { format } from 'utils'

const Product = (props) => {
  const { item = {}, index, onRemove } = props
  const {
    name,
    booking_type,
    total_price,
    start_at,
    finish_at,
    round,
    deposit_type,
    deposit,
  } = item

  const onClick = () => onRemove(index)
  const getDate = () => {
    const start = format.toThaiDate(start_at)
    const finish = format.toThaiDate(finish_at)
    const date = start === finish ? start : `${start} ถึง ${finish}`
    if (start === finish) {
      return (
        <Text>
          {start}
          <span>{round}</span>
        </Text>
      )
    } else {
      return (
        <>
          <Text>{date}</Text>
          <Text>{round}</Text>
        </>
      )
    }
  }

  const getLabel = () => {
    return (
      <Label>
        {format.toBookingType(booking_type)} ({format.toDigi(total_price)} บาท)
      </Label>
    )
  }

  const depositContent =
    deposit_type === 'yes' ? (
      <Text>มัดจำ/ประกัน {format.toDigi(deposit)} บาท</Text>
    ) : undefined

  return (
    <PageView>
      <Name>{name}</Name>
      <Info>
        {getLabel()}

        {getDate()}
        {depositContent}
      </Info>
      <Button onClick={onClick}>
        <Remove size={1.2} color={'#494949'} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 10px 10px 10px 0px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`

const Name = styled.div`
  width: 90px;
  height: 64px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Info = styled.div`
  width: calc(100% - 140px);
`

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Text = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  span {
    margin-left: 4px;
  }
`

const Button = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export default Product
