import styled from 'styled-components'

import { Line } from 'components/display'
import { DatePicker } from 'components/date'
import { FindInput } from 'components/input'

import { col } from './col'

const Header = (props) => {
  const { limit = {}, date, filter, onDate, onFilter } = props

  return (
    <PageView>
      <Line>
        <DatePicker
          placeholder="วันที่จอง"
          min={limit.min}
          max={limit.max}
          value={date}
          onChange={onDate}
        />
      </Line>
      <FindInput
        placeholder="ค้นหาหมายเลขล็อก"
        value={filter}
        onChange={onFilter}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const Text = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.blue};
  font-size: 14px;
  border-radius: 12px;
  text-align: center;
  padding: 2px 0px;
`

export default Header
