import React from 'react'
import styled from 'styled-components'

import { CheckCircle } from 'icon'

const Step = (props) => {
  const { active } = props

  const getNumberActive = (val) => {
    if (active === val) {
      return <No className="active">{val}</No>
    } else if (active > val) {
      return (
        <ActiveNo>
          <CheckCircle />
        </ActiveNo>
      )
    }

    return <No>{val}</No>
  }

  const getActive = (val) => {
    if (active === val) return 'active'
    else if (active > val) return 'pass'

    return undefined
  }

  return (
    <PageView>
      <NoLine>
        {getNumberActive(1)}
        {getNumberActive(2)}
        {getNumberActive(3)}
      </NoLine>
      <TextLine>
        <Text className={getActive(1)}>ข้อมูลทั่วไป</Text>
        <Text className={getActive(2)}>ข้อมูลอื่นๆ</Text>
        <Text className={getActive(3)}>ข้อมูลการเงิน</Text>
      </TextLine>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const NoLine = styled.div`
  width: 200px;
  margin: 0 auto;
  height: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #dcdcdc;
  border-radius: 12px;
`

const No = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 14px;
  color: white;
  background-color: ${(p) => p.theme.color_level.grey.medium};

  &.active {
    background-color: ${(p) => p.theme.color.blue};
  }
`

const ActiveNo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(p) => p.theme.color.green};
`

const TextLine = styled.div`
  width: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-left: 8px;
`

const Text = styled.span`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.low};

  &.pass {
    color: ${(p) => p.theme.color.green};
  }

  &.active {
    color: ${(p) => p.theme.color.blue};
  }
`

export default Step
