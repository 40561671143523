import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

const MarketName = (props) => {
  const { market_name = '', status, onOffer } = props
  if (status !== 'create') {
    return <Name>{market_name}</Name>
  }

  return (
    <PageView>
      <Name>{market_name}</Name>
      <Btn>
        <PrimaryBtn text="ยื่นข้อเสนอ" onClick={onOffer} />
      </Btn>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.div`
  width: calc(100% - 100px);
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${(p) => p.theme.color.blue};
`

const Btn = styled.div`
  width: 100px;
`

export default MarketName
