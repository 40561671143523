import styled from 'styled-components'

const PageView = (props) => {
  const { children } = props

  return <View>{children}</View>
}

const View = styled.div`
  width: 100%;
  min-height: 90vh;
`

export default PageView
