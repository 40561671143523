import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { market } from 'utils'

import { col } from './col'

const Product = (props) => {
  const { last = {}, doc = {} } = props

  const isName = last.unit_id !== doc.unit_id
  const openTime = market.toOpenTimeItem(doc)
  const getStatus = () => {
    const shop_info = doc.shop_info || {}
    return <ShopName>{shop_info.name}</ShopName>
  }

  const getReady = () => {
    return <Ready>ว่าง</Ready>
  }

  const name = isName ? <Name>{doc.name}</Name> : ''
  const content = doc.status === 'active' ? getReady() : getStatus()
  return (
    <PageView>
      <Row>
        <Col {...col.name} position="left">
          {name}
        </Col>
        <Col {...col.detail} position="center">
          <Time>{openTime}</Time>
        </Col>
        <Col {...col.type} position="right">
          {content}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Name = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.green};
  font-size: 14px;
  font-weight: bold;
  border-radius: 12px;
  padding: 1px 8px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
`

const Time = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  font-weight: bold;
`

const ShopName = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  font-weight: bold;
`

const Ready = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.green};
  font-size: 14px;
  border-radius: 12px;
  padding: 1px 8px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
`

export default Product
