import styled from 'styled-components'

import Product from './Product'

const Unit = (props) => {
  const { isReadonly, unit = {}, doc = {} } = props
  const product_list = unit.product_list || []

  const last = product_list.length - 1
  const content = product_list.map((it, i) => {
    return (
      <Product
        key={i}
        isReadonly={isReadonly}
        product={it}
        doc={doc}
        isLast={i === last}
      />
    )
  })

  return (
    <Card>
      <Title>{unit.name}</Title>
      <PageView>{content}</PageView>
    </Card>
  )
}

const Card = styled.div`
  width: 100%;
`

const PageView = styled.div`
  width: 100%;
  padding: 30px 20px 20px 20px;
  margin-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Title = styled.div`
  min-width: 64px;
  max-width: 180px;
  height: 52px;
  font-size: 32px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 25px;
  margin: 0 auto;
`

export default Unit
