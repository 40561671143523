import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { Help } from 'icon'
import { Row, Col, SaveBtn } from 'components/display'
import { Input } from 'components/input'
import { PrimaryBtn } from 'components/button'
import { message } from 'utils'
import Loading from 'components/loading'

import AddBtn from './AddBtn'
import FloorImage from './FloorImage'

const Header = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [err, setErr] = useState('')
  const [mode, setMode] = useState('')

  const navigate = useNavigate()

  const { isReady, market_id } = props
  const { floorplan = {}, market = {}, floorplan_list } = props.floorplan.toJS()
  const { have_unit, unit_list = [] } = props.unit.toJS()

  const openImage = () => {
    const input = document.getElementById('floorplan')
    if (input) {
      input.value = null
      input.click()
    }
  }
  const onAddFloor = () => {
    setMode('create')
    openImage()
  }

  const onEditFloor = () => {
    setMode('edit')
    openImage()
  }

  const onAddUnit = () => {
    const newbie = have_unit ? '' : '?action=newbie'
    navigate(`/market/${market_id}/unit/${floorplan.floorplan_id}${newbie}`)
  }

  const onSelectImage = async (img_url) => {
    floorplan.img_url = img_url

    if (mode !== 'create') {
      props.floorplan.setFloorplan(floorplan)
      return
    }

    try {
      setLoading(true)

      await props.floorplan.createFloorplan({ market_id }, { img_url })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onPlan = (val, name) => {
    floorplan[name] = val
    props.floorplan.setFloorplan(floorplan)
  }

  const onChange = (evt) => {
    const files = evt.target.files
    if (files.length === 0) return

    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onSelectImage(reader.result, file)
    })
    reader.readAsDataURL(file)
  }

  const onSave = async () => {
    try {
      setSaving(true)
      await props.floorplan.updateFloorplan(floorplan)
      message.success()
    } catch (e) {
      setErr(e.message)
    }
    setSaving(false)
  }

  const onDeleteFloor = async () => {
    try {
      setLoading(true)
      await props.floorplan.deleteFloorplan(floorplan)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onEditFloorOrder = async (index) => {
    try {
      await props.floorplan.editFloorplanOrder({
        floorplan_id: floorplan.floorplan_id,
        index,
      })
    } catch (e) {
      setErr(e.message)
    }
  }

  const onCloseError = () => setErr('')

  const isNone = floorplan.img_url === undefined

  const img = isNone ? (
    <AddBtn onAdd={onAddFloor} />
  ) : (
    <FloorImage
      isReady={isReady}
      floorplan={floorplan}
      floorplan_list={floorplan_list}
      unit_list={unit_list}
      onEdit={onEditFloor}
      onEditOrder={onEditFloorOrder}
      onDelete={onDeleteFloor}
    />
  )

  const saveBtn = isNone ? undefined : (
    <SaveBtn loading={saving} onClick={onSave} />
  )

  const content = !isNone ? (
    <>
      <Space />
      <Row align="middle">
        <Col {...col.name} position="left">
          <Input
            label="ชื่อรูปแผนผัง หรือ โซน"
            name="name"
            value={floorplan.name}
            onChange={onPlan}
          />
        </Col>
        <Col {...col.btn} position="right">
          <PrimaryBtn text="สร้างล็อก" onClick={onAddUnit} />
        </Col>
      </Row>
    </>
  ) : undefined

  let column
  let btn
  if (floorplan_list.length === 0) {
    column = col.c
    btn = (
      <Col {...col.c} position="right">
        <Helper>
          <span>วิธีการสร้างแผนผัง</span>
          <Help />
        </Helper>
      </Col>
    )
  } else {
    column = col.name
    btn = (
      <Col {...col.btn} position="right">
        <PrimaryBtn text="เพิ่มรูปแผนผัง" onClick={onAddFloor} />
      </Col>
    )
  }
  return (
    <PageView>
      <Row align="middle">
        <Col {...column} position="left">
          <Name>{market.name}</Name>
        </Col>
        {btn}
      </Row>

      <Loading loading={loading}>
        {img}
        {content}
      </Loading>
      {saveBtn}
      <Picker
        id="floorplan"
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={onChange}
      />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const col = {
  name: { lg: 20, md: 18, sm: 18, xs: 14 },
  btn: { lg: 4, md: 6, sm: 6, xs: 10 },
  c: { lg: 12, md: 12, sm: 12, xs: 12 },
}

const PageView = styled.div`
  width: 100%;
`

const Name = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  color: ${(p) => p.theme.color.blue};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Helper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  display: none;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
  svg {
    margin-left: 8px;
  }
`

const Space = styled.div`
  padding-top: 24px;
`

const Picker = styled.input`
  display: none;
`

export default inject('floorplan', 'unit')(observer(Header))
