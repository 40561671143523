const config = {
  mode: 'production',
  firebase: {
    apiKey: 'AIzaSyAaQogn3tmBR8Gn2TcsTaU2BLMLh2yD1tE',
    authDomain: 'marget-app.firebaseapp.com',
    projectId: 'marget-app',
    storageBucket: 'marget-app.appspot.com',
    messagingSenderId: '466684017658',
    appId: '1:466684017658:web:f965fcb36b0a51b3ce0243',
    measurementId: 'G-4ERJK4EJPW',
  },
  marget_url: 'https://marget.app',
  line: {
    liffId: '1657172449-Lx7x6Bq9',
  },
  api: 'https://api.marget.app/api',
  limit: {
    file: 1600000,
  },
  version: '0.1',
}

module.exports = config
