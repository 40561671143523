import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content } from 'components/display'

import Header from './Header'
import Pagination from './Pagination'
import UnitList from './UnitList'

const FloorPlan = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id, floorplan_id } = useParams()

  const { floorplan = {}, floorplan_list } = props.floorplan.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.floorplan.getFloorplanList({ market_id, floorplan_id }),
        props.floorplan.getMarketInfo({ market_id }),
        props.unit.checkFirstUnit({ market_id }),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.floorplan, market_id, floorplan_id])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onChangeFloor = async (selected) => {
    try {
      setLoading(true)
      await props.floorplan.setFloorplan(selected)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onCloseError = () => setErr('')
  const onBack = () => {
    navigate(`/market/${market_id}`)
  }

  return (
    <PageView>
      <Meta
        title="Market - ตั้งค่าแผนผังตลาด"
        description="ตั้งค่าแผนผังตลาด"
      />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Header isReady={!loading} market_id={market_id} />
        <Pagination
          selected={floorplan.floorplan_id}
          list={floorplan_list}
          onChange={onChangeFloor}
        />
        <UnitList market_id={market_id} floorplan_id={floorplan.floorplan_id} />
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

export default inject('floorplan', 'unit')(observer(FloorPlan))
