import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
}

let state
export class FloorPlan extends BaseStore {
  constructor() {
    super()
    this.observable({
      market: {},
      floorplan: cloneDeep(original),
      floorplan_list: [],
    })
    state = this
  }

  async getFloorplanList({ market_id, floorplan_id }) {
    const url = `${config.api}/v1/user/market/floorplan/${market_id}`
    const resp = await http.get(url)

    const data = resp.body || {}
    const floorplan_list = data.floorplan_list || []
    const id = +floorplan_id || undefined
    const i = floorplan_list.findIndex((it) => it.floorplan_id === id)

    let doc = {}
    if (i > -1) {
      doc = floorplan_list[i]
    } else if (floorplan_list.length > 0) {
      doc = floorplan_list[0]
    }

    runInAction(() => {
      state.floorplan_list = floorplan_list
      state.floorplan = doc
    })

    return { floorplan_list, floorplan: doc }
  }

  async getMarketInfo({ market_id }) {
    const url = `${config.api}/v1/user/market/market-info/${market_id}/short`
    const resp = await http.get(url)

    const data = resp.body || {}

    runInAction(() => {
      state.market = data
    })
  }

  async getFloorplanInfo({ floorplan_id }) {
    const url = `${config.api}/v1/user/market/floorplan/${floorplan_id}/info`
    const resp = await http.get(url)

    const data = resp.body || cloneDeep(original)

    runInAction(() => {
      state.floorplan = data
    })
  }

  toFloorplanList() {
    const floorplan_list = this.toJS().floorplan_list || []

    return floorplan_list
  }

  async createFloorplan({ market_id }, json = {}) {
    const url = `${config.api}/v1/user/market/floorplan/${market_id}`
    const resp = await http.post(url, { json })

    const doc = resp.body || {}

    const floorplan_list = this.toFloorplanList()
    floorplan_list.push(doc)

    runInAction(() => {
      state.floorplan_list = floorplan_list
      state.floorplan = doc
    })
  }

  async updateFloorplan(json = {}) {
    const { floorplan_id } = json
    const url = `${config.api}/v1/user/market/floorplan/${floorplan_id}`
    const resp = await http.put(url, { json })

    const doc = resp.body || {}

    const floorplan_list = this.toFloorplanList()
    const i = floorplan_list.findIndex(
      (it) => it.floorplan_id === doc.floorplan_id
    )
    if (i !== -1) {
      floorplan_list[i] = doc
      runInAction(() => {
        state.floorplan_list = floorplan_list
        state.floorplan = doc
      })
    }
  }

  async deleteFloorplan({ floorplan_id } = {}) {
    const url = `${config.api}/v1/user/market/floorplan/${floorplan_id}/delete`
    await http.put(url, {})

    const floorplan_list = this.toFloorplanList()
    const i = floorplan_list.findIndex((it) => it.floorplan_id === floorplan_id)
    if (i !== -1) {
      floorplan_list.splice(i, 1)
      const doc =
        floorplan_list.length === 0 ? cloneDeep(original) : floorplan_list[0]

      runInAction(() => {
        state.floorplan_list = floorplan_list
        state.floorplan = doc
      })
    }
  }

  async editFloorplanOrder({ floorplan_id, index } = {}) {
    const url = `${config.api}/v1/user/market/floorplan/${floorplan_id}/${index}/order`
    const res = await http.put(url, {})

    const floorplan_list = res.body

    runInAction(() => {
      state.floorplan_list = floorplan_list
    })
  }

  setFloorplan(doc) {
    runInAction(() => {
      state.floorplan = doc
    })
  }
}

export default new FloorPlan()
