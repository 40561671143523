import { format } from './format'
import { timer } from './timer'

export class Market {
  getOpenTimePattern(txt) {
    const val = txt || ''
    return val.length > 3 ? 'xx:xx' : 'x:xx'
  }

  toOpenTime(doc = {}) {
    const open_time_list = doc.open_time_list || []

    const list = open_time_list.map((it) => {
      return this.toOpenTimeItem(it)
    })

    return list.join(' ,')
  }

  toOpenTimeItem(doc = {}) {
    const { start_at, finish_at } = doc

    const pStart = this.getOpenTimePattern(start_at)
    const pFinish = this.getOpenTimePattern(finish_at)

    const start = format.toPattern(start_at, pStart)
    const finish = format.toPattern(finish_at, pFinish)

    return `${start}-${finish}`
  }

  toBanProduct(doc = {}, options = {}) {
    const list = [
      { name: 'เตาไฟฟ้า', value: 'ban_electric_stove' },
      { name: 'เตาแก๊ส', value: 'ban_gas_stove' },
      { name: 'อาหารหนัก (ข้าว, เส้น)', value: 'ban_food' },
      { name: 'น้ำชงทุกชนิด', value: 'ban_make_drink' },
      { name: 'น้ำเปล่า น้ำอัดลม', value: 'ban_drink' },
      { name: 'มูลนิธิ', value: 'ban_foundation' },
      { name: 'บูธบัตรเครดิต / เน็ตบ้าน', value: 'ban_direct_sale' },
    ]

    const content_list = list
      .filter((it) => doc[it.value] === 1)
      .map((it) => it.name)
    if (doc.ban_other === 1 && doc.ban_other_text !== '') {
      content_list.push(doc.ban_other_text)
    }

    const ch = options.ch || ', '
    const text = content_list.join(ch)
    return text
  }

  toProductType(doc = {}, options = {}) {
    const list = [
      { name: 'อาหาร', value: 'type_food' },
      { name: 'เครื่องดื่ม', value: 'type_drink' },
      { name: 'เสื้อผ้า', value: 'type_clothes' },
      { name: 'ของใช้', value: 'type_things' },
      { name: 'บริการ', value: 'type_services' },
    ]

    const content_list = list
      .filter((it) => doc[it.value] === 1)
      .map((it) => it.name)

    const ch = options.ch || ', '
    const text = content_list.join(ch)
    return text
  }

  getBookingType(doc = {}) {
    switch (doc.booking_type) {
      case 'daily':
        return 'บาท/วัน'
      case 'monthly':
        return 'บาท/เดือน'
      default:
        return 'บาท/ต่อรอบ'
    }
  }

  getBookingTypeName(bookingType) {
    switch (bookingType) {
      case 'daily':
      case 'booking_daily':
        return 'รายวัน'
      case 'monthly':
      case 'booking_monthly':
        return 'รายเดือน'
      default:
        return 'รายรอบ'
    }
  }

  getRoundList(start, finish, round) {
    if (round === 1) {
      return [{ start_at: start, finish_at: finish }]
    }
    const range_days = finish.diff(start, 'days') + 1
    const days = Math.ceil(range_days / round)
    const round_list = []
    for (let i = 1; i < round; i++) {
      const start_at = timer.get(start)
      const finish_at = timer.get(start).add(days - 1, 'days')

      round_list.push({ start_at, finish_at })
      start = timer.get(start).add(days, 'days')
    }

    round_list.push({ start_at: start, finish_at: finish })

    return round_list
  }

  getDateLimitation(params = {}) {
    const { setting, days_list } = params
    const { start_at, finish_at } = setting
    const min = start_at ? start_at.toDate() : undefined
    const max = finish_at ? finish_at.toDate() : undefined

    const limit = { min, max, days_list }
    return limit
  }

  isBetween(val, start, finish) {
    const t1 = 'DD/MM/YY'
    const rv = val.format(t1)
    const rs = start.format(t1)
    const rf = finish.format(t1)

    const v = timer.get(rv, t1)
    const s = timer.get(rs, t1)
    const f = timer.get(rf, t1)

    return v.isSameOrAfter(s) && v.isSameOrBefore(f)
  }

  toPrice(doc = {}, option = {}) {
    const { price, booking_type, daily_type, round_type } = doc
    const { start_at } = option
    if (!start_at) return price

    if (booking_type === 'daily' && daily_type === 'specific') {
      const days = start_at ? start_at.days() : -1
      switch (days) {
        case 0:
          return doc.price_sunday
        case 1:
          return doc.price_monday
        case 2:
          return doc.price_tuesday
        case 3:
          return doc.price_wednesday
        case 4:
          return doc.price_thursday
        case 5:
          return doc.price_friday
        case 6:
          return doc.price_saturday
        default:
          return price
      }
    } else if (booking_type === 'round' && round_type === 'specific') {
      const { round_start_at, round_finish_at } = option

      const round_price_list = doc.round_price_list || []
      for (const item of round_price_list) {
        const start = timer.get(item.start_at).startOf('day')
        const finish = timer.get(item.finish_at)
        const inStart = this.isBetween(round_start_at, start, finish)
        const inFinish = this.isBetween(round_finish_at, start, finish)

        if (inStart || inFinish) {
          return +item.price
        }
      }
    }

    return price
  }

  getDailyPrice(date, doc) {
    if (doc.daily_type !== 'specific') {
      return doc.price
    }
    const days = date.days()
    switch (days) {
      case 0:
        return doc.price_sunday
      case 1:
        return doc.price_monday
      case 2:
        return doc.price_tuesday
      case 3:
        return doc.price_wednesday
      case 4:
        return doc.price_thursday
      case 5:
        return doc.price_friday
      case 6:
        return doc.price_saturday
      default:
        return doc.price
    }
  }

  calcPrice(product, start_at, finish_at, price, days_list) {
    if (!start_at || !finish_at) {
      return { total_price: price, price_list: [], days: 1 }
    }

    const { booking_type } = product
    const price_list = []
    const p = +price
    const days = finish_at.diff(start_at, 'days') + 1
    if (booking_type !== 'daily' || days === 1) {
      return { total_price: p, price_list, days }
    }

    let counter = 0
    let total = 0
    let date = timer.get(start_at)

    for (let i = 0; i < days; i++) {
      const num = date.days()
      const isOpen = days_list.includes(num)
      if (isOpen) {
        const date_price = this.getDailyPrice(date, product)
        price_list.push({ date, price: date_price })
        total += date_price
        counter++
      }

      date.add(1, 'days')
    }

    return { total_price: total, price_list, days: counter }
  }
}

export const market = new Market()
export default market
