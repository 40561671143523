import Joi from 'joi'

import { validator } from 'utils'

export const getRule = (options = {}) => {
  const { set_finish_at = false } = options

  const obj = {
    start_at: validator.getRuleDate('วันที่เริ่ม'),
  }

  if (set_finish_at) {
    obj.set_finish_at = validator.getRuleDate('วันที่สิ้นสุด')
  }

  const rule = Joi.object(obj).options({ allowUnknown: true })

  return rule
}
