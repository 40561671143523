import styled from 'styled-components'

import image from 'img'
import { Marget } from 'icon'
import { Content } from 'components/display'
import Loading from 'components/loading'

const LoadingView = (props) => {
  return (
    <PageView>
      <Content>
        <Marget size={1.8} />
        <Loading loading />
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
  text-align: center;
  background-image: url(${image.landing});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
`

export default LoadingView
