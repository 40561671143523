import { Row, Col } from 'components/display'
import { Input } from 'components/input'

const DayItem = (props) => {
  const { name, price_name, doc = {}, validate = {}, onChange } = props

  const onPrice = (val, name) => {
    doc[name] = val
    onChange(doc)
  }

  return (
    <Row align="middle">
      <Col span={10} position="left">
        {name}
      </Col>
      <Col span={14} position="right">
        <Input
          label="ราคา (บาท)"
          name={price_name}
          value={doc[price_name]}
          onChange={onPrice}
          isNumber={true}
          invalid={validate[price_name]}
        />
      </Col>
    </Row>
  )
}

export default DayItem
