import styled from 'styled-components'

import { helper, product } from 'utils'

const Information = (props) => {
  const { item = {}, text, color } = props
  const { list = [], market_name, img_list = [] } = item

  const getRound = ({ start_at, finish_at, round }) => {
    const date = product.getDate(start_at, finish_at)
    return { date, round }
  }

  const img_url = helper.getUrl(img_list)
  const { date, round } = list.length === 0 ? {} : getRound(list[0])
  const keys = {}
  for (const item of list) {
    keys[item.name] = true
  }
  const names = Object.keys(keys)
  const content = names.map((name, i) => {
    return <Name key={i}>{name}</Name>
  })

  return (
    <PageView>
      <Image src={img_url} />
      <Info>
        <Status style={{ color }}>{text}</Status>
        <Detail>
          <NameList>{content}</NameList>
          <Title>{market_name}</Title>
          <Time>
            {date}
            <span>{round}</span>
          </Time>
        </Detail>
      </Info>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Image = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 8px;
  object-fit: cover;
`

const Info = styled.div`
  flex: auto;
  padding-left: 16px;
`

const Status = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: right;
`

const Detail = styled.div``

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
`

const Time = styled.div`
  font-size: 14px;
  font-weight: 700;

  span {
    float: right;
  }
`

const NameList = styled.div`
  width: 100%;
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 8px;
`

const Name = styled.div`
  width: fit-content;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 8px;
  padding: 0px 4px;
`

export default Information
