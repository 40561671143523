import styled from 'styled-components'

import { format } from 'utils'

const Price = (props) => {
  const { price, label } = props
  return (
    <PageView>
      <PriceText>{format.toDigi(price)}</PriceText>
      <Label>บาท/{label}</Label>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const PriceText = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  line-height: 26px;
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color.red};
  line-height: 18px;
`

export default Price
