import { useState } from 'react'
import styled from 'styled-components'

import CropperImage from 'components/cropper'
import { config } from 'config'
import { file } from 'utils'

import Label from '../Label'
import ContentView from '../ContentView'
import AddFloorplan from './AddFloorplan'
import Picture from './Picture'

const FloorplanImg = (props) => {
  const [selected, setSelected] = useState(null)

  const { doc = {}, onChange } = props

  const onRemove = () => {
    doc.floor_plan_url = ''
    onChange(doc)
  }

  const onCompleteImg = (value) => {
    doc.img_list.push(value)
    onChange(doc)
  }

  const onSelectImg = async (img, data) => {
    if (data.size > config.limit.file) {
      const res = await file.compress({ value: img, limit: config.limit.file })
      img = res.text
    }
    doc.floor_plan_url = img
    onChange(doc)
  }

  const img_url = doc.floor_plan_url || ''
  const content =
    img_url === '' ? (
      <AddFloorplan onAdd={onSelectImg} />
    ) : (
      <Picture img={doc.floor_plan_url} onRemove={onRemove} />
    )
  return (
    <PageView>
      <Label text="รูปภาพแผนผังรวม" />
      <ContentView>{content}</ContentView>
      <CropperImage
        visible={selected !== null}
        image={selected}
        isCircle={false}
        displaySide={false}
        limitSize={500000}
        onClose={() => setSelected(null)}
        onSave={onCompleteImg}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default FloorplanImg
