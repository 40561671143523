import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Up } from 'icon'

import Product from './Product'

const Order = (props) => {
  const { visible, onClose } = props
  const order = props.order.toJS().offer
  const { list } = order

  const onRemove = (index) => {
    const item = list[index]
    const { key } = item
    props.order.removeOffer(item, { key })
  }

  const content = list.map((it, i) => {
    return <Product key={i} item={it} index={i} onRemove={onRemove} />
  })

  return (
    <PageView className={visible ? 'visible' : ''}>
      <Header>
        <Row align="middle">
          <Col lg={3} md={3} xs={2}>
            <Button onClick={onClose}>
              <Up />
            </Button>
          </Col>
          <Col lg={13} md={13} xs={14}>
            รายการที่เลือก
          </Col>
          <Col lg={8} md={8} xs={8}>
            <Text>
              <Total>{list.length}</Total>
              รายการ
            </Text>
          </Col>
        </Row>
      </Header>
      <Body>{content}</Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  overflow: hidden; /* Hide the element content, while height = 0 */
  height: 0;
  opacity: 0;
  transition: height 0ms 600ms, opacity 400ms 0ms;

  &.visible {
    padding-top: 20px;
    height: auto;
    opacity: 1;
    transition: height 1000ms 1000ms, opacity 400ms 0ms;
  }
`

const Header = styled.div`
  width: 100%;
  font-size: 14px;
  border: 0px dashed ${(p) => p.theme.color_level.grey.low};
  border-bottom-width: 1px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Body = styled.div`
  width: 100%;
  max-height: 45vh;
  overflow: auto;
`

const Text = styled.div`
  width: 100%;
  text-align: right;
`

const Total = styled.div`
  margin-right: 8px;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.blue};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export default inject('order')(observer(Order))
