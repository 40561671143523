import styled from 'styled-components'

import { Add } from 'icon'

import Label from './Label'
import ContentView from './ContentView'
import Line from './Line'
import OpenTime from './OpenTime'

const OpenTimeList = (props) => {
  const { doc = {}, onChange = () => {} } = props
  const open_time_list = doc.open_time_list || []

  const onAdd = () => {
    open_time_list.push({ start_at: '', finish_at: '' })
    doc.open_time_list = open_time_list

    onChange(doc)
  }

  const onCh = (item, index) => {
    open_time_list[index] = item
    doc.open_time_list = open_time_list
    onChange(doc)
  }

  const onRemove = (index) => {
    open_time_list.splice(index, 1)
    doc.open_time_list = open_time_list
    onChange(doc)
  }

  const content = open_time_list.map((it, i) => {
    return (
      <OpenTime
        key={i}
        item={it}
        onChange={onCh}
        onRemove={onRemove}
        index={i}
      />
    )
  })

  return (
    <PageView>
      <Label text="รอบการขาย" />
      <ContentView>
        {content}
        <Line>
          <AddBtn onClick={onAdd}>
            <Add color={'#00B377'} />
            <span>เพิ่มรอบ</span>
          </AddBtn>
        </Line>
      </ContentView>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const AddBtn = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 8px;
    font-size: 14px;
    color: ${(p) => p.theme.color_level.grey.dark};
  }
`

export default OpenTimeList
