import styled from 'styled-components'

import Loading from 'components/loading'
import { BaseDialog } from 'dialog'
import { Close } from 'icon'

const FloorplanDialog = (props) => {
  const {
    visible,
    index = 0,
    loading,
    size,
    onClose,
    onConfirm = () => {},
  } = props

  const content = []
  for (let i = 1; i <= size; i++) {
    const css = i === index ? 'active' : ''
    const click = i === index ? undefined : () => onConfirm(i)
    content.push(
      <No key={i} className={css} onClick={click}>
        {i}
      </No>
    )
  }
  return (
    <BaseDialog isHeader={false} visible={visible} onClose={onClose}>
      <Content>
        <Icon onClick={onClose}>
          <Close />
        </Icon>
        <Body>
          <Title>เปลี่ยนลำดับ</Title>
          <LoadingArea>
            <Loading loading={loading} size={0.3} top="0px" />
          </LoadingArea>
          <LineItem>{content}</LineItem>
        </Body>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 330px;
  padding: 0px;
  position: relative;
`

const Body = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 24px;
`

const LoadingArea = styled.div`
  width: 100%;
  margin: 20px 0px;
`

const Title = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 24px;
  font-weight: bold;
`

const LineItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
`

const No = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color_level.yellow.low};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background-color: ${(p) => p.theme.color.blue};
    cursor: default;
  }
`

const Icon = styled.span`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`

export default FloorplanDialog
