import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { file } from 'utils'

import { toPosition, toLocalPosition, toNumber } from '../location'
import EditPin from './EditPin'
import Pin from './Pin'

const Floorplan = (props) => {
  const [imgSize, setImgSize] = useState(undefined)
  const [imgLayout, setImgLayout] = useState(undefined)
  const [pin, setPin] = useState({ offsetWidth: 0, offsetHeight: 0 })
  const [ratioX, setRatioX] = useState(1)
  const [ratioY, setRatioY] = useState(1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [limitPosition, setLimitPosition] = useState({
    left: 0,
    top: 0,
    bottom: 1000,
    right: 1000,
  })

  let pointX = 0,
    pointY = 0,
    lastX = 0,
    lastY = 0
  let mode

  const {
    visible = true,
    img_url,
    height,
    size = 20,
    pin_list = [],
    onSelected,
    onPosition = () => {},
    init = {},
    viewMode = 'view',
  } = props

  const calcImgSize = useCallback(async () => {
    const img = await file.base64ToImage(img_url, true)

    setImgSize(img)
  }, [img_url])

  const updatePosition = useCallback((x, y) => {
    setPosition({ x, y })
    onPosition({ x, y })
  }, [])

  const onRef = useCallback(
    (node) => {
      if (!node) return
      setImgLayout(node)

      const height = node.height
      const width = node.width

      const limit = {
        left: 0,
        top: 0,
        bottom: height,
        right: width,
      }
      setLimitPosition(limit)

      if (viewMode === 'create') {
        const x = width / 2
        const y = height / 2
        updatePosition(x, y)
      }
    },
    [updatePosition, viewMode]
  )

  const onRefPin = useCallback((node) => {
    if (node) setPin(node)
  }, [])

  useEffect(() => {
    if (visible) {
      calcImgSize()
      const x = init.x || 0
      const y = init.y || 0

      updatePosition(x, y)
    }
  }, [calcImgSize, updatePosition, visible, img_url])

  useEffect(() => {
    if (!imgSize || !imgLayout) {
      return []
    }

    const rx = toNumber(imgLayout.width / imgSize.width)
    const ry = toNumber(imgLayout.height / imgSize.height)

    setRatioX(rx)
    setRatioY(ry)
  }, [imgSize, imgLayout])

  const onPinMove = (evt) => {
    pointX = lastX - evt.clientX
    pointY = lastY - evt.clientY
    lastX = evt.clientX
    lastY = evt.clientY

    let x = pin.offsetLeft - pointX
    let y = pin.offsetTop - pointY

    if (x < limitPosition.left) x = limitPosition.left
    else if (x > limitPosition.right - pin.offsetWidth) {
      x = limitPosition.right - pin.offsetWidth
    }

    if (y < limitPosition.top) y = limitPosition.top
    else if (y > limitPosition.bottom - pin.offsetHeight) {
      y = limitPosition.bottom - pin.offsetHeight
    }

    const location = toPosition(x, y, size)
    const newX = location.x / ratioX
    const newY = location.y / ratioY
    updatePosition(newX, newY)
  }

  const onTouchStart = (evt) => {
    const obj = evt.changedTouches[0]
    lastX = obj.clientX
    lastY = obj.clientY
    document.ontouchmove = onTouchMove
    // onLog('onTouchStart', evt)
  }

  const onTouchMove = (evt) => {
    const obj = evt.changedTouches[0]
    onPinMove(obj)
  }

  const onTouchEnd = () => {
    document.ontouchmove = null
  }

  const onMoveUp = (evt) => {
    document.onmouseup = null
    document.onmousemove = null
    mode = undefined
  }

  const onMove = (evt) => {
    if (mode !== 'moving') return
    evt.preventDefault()

    onPinMove(evt)
    // onLog('onMove', evt)
  }

  const onLog = (name = '', evt) => {
    console.log(name, evt.clientX, evt.clientY)
  }

  const onMoveDown = (evt) => {
    if (mode !== undefined) return

    onLog('onMoveDown', evt)
    mode = 'moving'
    lastX = evt.clientX
    lastY = evt.clientY

    document.onmouseup = onMoveUp
    document.onmousemove = onMove
  }

  const getContent = () => {
    if (!imgSize || !imgLayout || !visible) {
      return []
    }

    const list = pin_list.map((it, i) => {
      const location = toLocalPosition(
        toNumber(it.x * ratioX),
        toNumber(it.y * ratioY),
        size
      )

      const css = {
        top: `${location.y}px`,
        left: `${location.x}px`,
      }

      return (
        <Pin
          key={i}
          tag={it}
          style={css}
          size={size}
          onClick={onSelected}
        ></Pin>
      )
    })

    return list
  }

  const getEditPin = () => {
    if (viewMode === 'view' || !imgSize || !imgLayout || !visible) {
      return undefined
    }

    const location = toLocalPosition(
      toNumber(position.x * ratioX),
      toNumber(position.y * ratioY),
      size
    )
    const css = {
      top: `${location.y}px`,
      left: `${location.x}px`,
    }

    return (
      <EditPin
        onRef={onRefPin}
        style={css}
        size={size}
        onMouseDown={onMoveDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      ></EditPin>
    )
  }

  const content = getContent()
  const edit = getEditPin()

  return (
    <PageView>
      <ContentFloorplan>
        <Picture ref={onRef} style={{ maxHeight: height }} src={img_url} />
        {content}
        {edit}
      </ContentFloorplan>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${(p) => p.theme.color_level.grey.light};
  text-align: center;
`

const ContentFloorplan = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
`

const Picture = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  max-height: 60vh;
  object-fit: contain;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`

export default Floorplan
