import styled from 'styled-components'
import { Row, Col } from 'antd'

const Label = (props) => {
  const { text = '', onEdit } = props

  return (
    <Detail>
      <Row align="middle">
        <Col span={16}>{text}</Col>
        <Col span={8}>
          <Info onClick={onEdit}>แก้ไข</Info>
        </Col>
      </Row>
    </Detail>
  )
}

const Detail = styled.div`
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
  padding-bottom: 10px;
`

const Info = styled.div`
  font-size: 14px;
  text-align: right;
  color: ${(p) => p.theme.color_level.grey.high};
  text-decoration: underline;
  cursor: pointer;
`

export default Label
