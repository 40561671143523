import styled from 'styled-components'

import { Checker } from 'components/input'

import Label from '../Label'

const BookingType = (props) => {
  const { doc = {}, validate = {}, onChange } = props

  const onCheck = (val, name) => {
    doc[name] = val ? 1 : 0
    onChange(doc)
  }

  const content = list.map((it, i) => {
    const { name, value } = it
    const isCheck = doc[value] === 1
    return (
      <Line key={i}>
        <Checker
          label={name}
          name={value}
          value={isCheck}
          onChange={onCheck}
          invalid={validate[value]}
        />
      </Line>
    )
  })
  return (
    <PageView>
      <Label text="ประเภทสินค้า" padding={false} />
      <BodyContent>{content}</BodyContent>
    </PageView>
  )
}

const list = [
  {
    name: 'อาหาร',
    value: 'type_food',
  },
  {
    name: 'เครื่องดื่ม',
    value: 'type_drink',
  },
  {
    name: 'เสื้อผ้า',
    value: 'type_clothes',
  },
  {
    name: 'ของใช้',
    value: 'type_things',
  },
  {
    name: 'บริการ',
    value: 'type_services',
  },
]

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const BodyContent = styled.div`
  width: 100%;
  padding-top: 12px;
`

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default BookingType
