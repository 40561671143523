import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { BackHeader, Content } from 'components/display'
import Order from 'pages/allBookingList/Order'

const OrderList = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const navigate = useNavigate()

  const { all_order } = props.order.toJS()
  const { total, list } = all_order

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await props.order.getAllOrderList({ page: 1, per_page: 100 })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.order])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/')

  const getOrderList = () => {
    const source = '/booking'
    return list.map((it, i) => {
      return <Order key={i} item={it} source={source} />
    })
  }

  const content =
    list.length === 0 ? (
      <Coming>วันนี้ยังไม่มี รายการจองใหม่</Coming>
    ) : (
      getOrderList()
    )

  return (
    <PageView>
      <BackHeader onClick={onBack} title="รายละเอียดการจอง" />
      <Content loading={loading} isHeader={true}>
        <Title>
          การจองวันนี้
          <Total>{total || 0}</Total>
        </Title>

        {content}
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.green};
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-bottom: 16px;
`

const Total = styled.div`
  width: 32px;
  height: 32px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  background-color: ${(props) => props.theme.color.green};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Coming = styled.div`
  width: 100%;
  color: ${(props) => props.theme.color_level.grey.low};
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding: 40px 0px;
`

export default inject('order')(observer(OrderList))
