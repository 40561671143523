import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Input } from 'components/input'
import { DatePicker } from 'components/date'
import { Delete } from 'icon'

const RoundItem = (props) => {
  const { item = {}, index = 0, onChange, onRemove } = props

  const onItem = (val, name) => {
    item[name] = val
    onChange(item)
  }

  const remove = () => onRemove(index)

  return (
    <PageView>
      <Label>เงื่อนไขที่ {index + 1}</Label>
      <Row align="middle">
        <Col span={12} position="left">
          <DatePicker
            placeholder="วันเริ่มต้น"
            name="start_at"
            value={item.start_at}
            onChange={onItem}
          />
        </Col>
        <Col span={12} position="right">
          <DatePicker
            placeholder="วันสิ้นสุด"
            name="finish_at"
            value={item.finish_at}
            onChange={onItem}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col span={24}>
          <Input
            label="หมายเหตุ"
            name="note"
            value={item.note}
            onChange={onItem}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12} position="left">
          <Input
            label="ราคา (บาท)"
            name="price"
            value={item.price}
            onChange={onItem}
            isNumber={true}
          />
        </Col>
        <Col span={12} position="right">
          <Btn onClick={remove}>
            <Delete size={1.2} />
          </Btn>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 16px;
`

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 8px;
`

const Btn = styled.span`
  width: 40px;
  height: 100%;
  display: flex;
`

export default RoundItem
