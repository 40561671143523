import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import {
  Meta,
  BackHeader,
  Content,
  Row,
  Col,
  SaveBtn,
} from 'components/display'
import { Toggle, InputArea, HtmlEditor } from 'components/input'
import { message } from 'utils'

const Announce = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id } = useParams()

  const { announce } = props.marketSetting.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await props.marketSetting.getAnnounce(market_id)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.marketSetting, market_id])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')
  const onBack = () => {
    navigate(`/market/${market_id}`)
  }

  const onSave = async () => {
    try {
      setProcessing(true)
      await props.marketSetting.updateAnnounce(market_id, announce)
      message.success()
    } catch (e) {
      setErr(e.message)
    }
    setProcessing(false)
  }

  const onChange = (val, name) => {
    announce[name] = val
    props.marketSetting.setAnnounce(announce)
  }

  const onDetail = (val) => {
    const data = props.marketSetting.toJS().announce
    data.detail = val
    props.marketSetting.setAnnounce(data)
  }

  const onStatus = (val) => {
    announce.status = val ? 'active' : 'inactive'
    props.marketSetting.setAnnounce(announce)
  }

  return (
    <PageView>
      <Meta title="Market - ตั้งค่าประกาศ" description="ตั้งค่าประกาศ" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Row align="middle">
          <Col lg={20} md={20} xs={18}>
            <Title>แจ้งประกาศ</Title>
          </Col>
          <Col lg={4} md={4} xs={6}>
            <Status>
              <Toggle
                value={announce.status === 'active'}
                onChange={onStatus}
              />
            </Status>
          </Col>
        </Row>

        <InputArea
          label="หัวข้อ"
          name="title"
          value={announce.title}
          rows={2}
          onChange={onChange}
        />

        <Label>รายละเอียด</Label>
        <HtmlEditor
          init={announce.announce_id}
          name="detail"
          value={announce.detail}
          onChange={onDetail}
        />
      </Content>
      <SaveBtn loading={processing} onClick={onSave} />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 18px;
`

const Status = styled.div`
  width: 55px;
  float: right;
`

const Label = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
`

export default inject('marketSetting')(observer(Announce))
