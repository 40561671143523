import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Label } from './components'

const MarketInfo = (props) => {
  const { doc = {} } = props
  const navigate = useNavigate()

  const {
    market_id,
    address,
    sub_district,
    district,
    province,
    postcode,
    map_link,
  } = doc

  const onEdit = () => navigate(`/market/${market_id}/editor-information`)

  const getBkkPrefix = () => {
    const tumbon = 'แขวง'
    const prefix = 'เขต'

    return { tumbon, prefix }
  }

  const getPrefix = () => {
    const tumbon = 'ตำบล'
    const prefix = 'อำเภอ'

    return { tumbon, prefix }
  }

  const { tumbon, prefix } =
    province === 'กรุงเทพมหานคร' ? getBkkPrefix() : getPrefix()

  return (
    <PageView>
      <Label text="ที่อยู่" onEdit={onEdit} />
      <Text>
        {address} {tumbon} {sub_district} {prefix} {district} {province}{' '}
        {postcode}
      </Text>

      <a href={map_link} target="_blank" rel="noreferrer">
        <Link>{map_link}</Link>
      </a>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 15px 0px;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 120%;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Link = styled.div`
  padding-top: 20px;
  color: ${(p) => p.theme.color.blue};
`

export default MarketInfo
