import Joi from 'joi'

import { validator } from 'utils'

const rules = Joi.object({
  name: validator.getRuleString('รายละเอียดโปรโมชั่น'),
  start_at: validator.getRuleDate('วันที่จัดโปรโมชั่น'),
  value: validator.getRuleNumber('่จำนวนเงินส่วนลด'),
}).options({ allowUnknown: true })

export default rules
