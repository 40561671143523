import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Dot } from 'components/display'
import Loading from 'components/loading'
import { helper } from 'utils'

import ImageList from './ImageList'
import Header from './Header'
import Address from './Address'
import FloorPlan from './FloorPlan'
import Information from './Information'
import MarketBank from './MarketBank'
import Announce from './Announce'

const MarketInfo = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const { market_id } = useParams()
  const navigate = useNavigate()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.market.getMarketInfo(market_id),
        props.marketSetting.getAnnounce(market_id),
      ])
    } catch (e) {
      console.log('e', e.message)
    }
    setLoading(false)
  }, [props.market, props.marketSetting, market_id])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onCloseError = () => setErr('')
  const onBack = () => {
    navigate(`/market/${market_id}`)
  }

  const { doc = {} } = props.market.toJS()
  const { announce = {} } = props.marketSetting.toJS()

  const { name = '', img_list = [] } = doc
  const img_url = helper.getUrl(img_list)
  return (
    <PageView>
      <Meta
        loading={loading}
        title={`ตลาด - ${name}`}
        description={doc.keywords}
        url={img_url}
      />
      <BackHeader color="white" onClick={onBack} />
      <Body>
        <Loading loading={loading} top="20px" />
        <ImageList doc={doc} />
        <Announce doc={announce} />
        <Detail>
          <Header doc={doc} />
          <Address doc={doc} />
          <FloorPlan doc={doc} />
          <Dot />
          <Information doc={doc} />
          <Dot />
          <MarketBank doc={doc} />
        </Detail>
      </Body>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
`

const Body = styled.div`
  padding-top: 65px;
  min-height: 400px;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
`

const Detail = styled.div`
  padding: 30px 24px;
`

export default inject('market', 'marketSetting')(observer(MarketInfo))
