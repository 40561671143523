import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'
import { InputArea, Checker } from 'components/input'
import { PrimaryBtn } from 'components/button'

const Reason = (props) => {
  const [category, setCategory] = useState('')
  const [text, setText] = useState('')
  const [note, setNote] = useState('')

  const { visible, onClose, onSave = () => {} } = props

  useEffect(() => {
    if (visible) {
      const item = menu[0]
      setCategory(item.value)
      setText(item.name)
      setNote('')
    }
  }, [visible])

  const onChange = (v) => setNote(v)
  const onChecker = (v, name, tag) => {
    setCategory(tag.value)
    setText(tag.name)
  }

  const onClick = () => {
    const v = category === 'other' ? note : text
    onSave(v)
  }

  const content = menu.map((it, i) => {
    return (
      <Line key={i}>
        <Checker
          label={it.name}
          value={it.value === category}
          onChange={onChecker}
          tag={it}
        />
      </Line>
    )
  })

  const disabled = category === 'other' ? note === '' : false
  return (
    <BaseDrawer
      visible={visible}
      title="เหตุผลที่จะเสนอรายการใหม่"
      height="525px"
      onClose={onClose}
    >
      <PageView>
        {content}
        <Note>
          <InputArea
            label="ระบุเหตุผลอื่นฯ"
            disabled={category !== 'other'}
            value={note}
            onChange={onChange}
          />
        </Note>

        <Button>
          <PrimaryBtn
            text="ส่งเสนอรายการ"
            disabled={disabled}
            onClick={onClick}
          />
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const menu = [
  { name: 'สินค้าซ้ำ', value: 'duplicate' },
  { name: 'สินค้าไม่ตรงตามเงื่อนไข', value: 'condition' },
  { name: 'ล็อกที่จองไม่ว่าง', value: 'unavailable' },
  { name: 'ความสูงเกิน', value: 'height' },
  { name: 'แผนผังมีการเปลี่ยนแปลง', value: 'floor' },
  { name: 'อื่นๆ (โปรดระบุด้านล่าง)', value: 'other' },
]

const PageView = styled.div`
  width: 100%;
`

const Line = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Note = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Button = styled.div`
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
  padding-top: 16px;
`

export default Reason
