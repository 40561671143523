import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog, ConfirmDialog } from 'dialog'
import { Meta, BackHeader, Content, SaveBtn } from 'components/display'
import { validator } from 'utils'
import Loading from 'components/loading'

import Information from './Information'
import UnitList from './UnitList'
import rules from './rules'

const Promotion = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [validate, setValidate] = useState({})
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id, promotion_id } = useParams()

  const { doc = {}, unit = {} } = props.promotion.toJS()
  const unit_list = unit.unit_list || []

  const getProduct = useCallback(
    async (mId, id) => {
      try {
        setLoading(true)

        await props.promotion.getUnitList({ market_id: mId })
        if (promotion_id) {
          await props.promotion.getPromotion({ promotion_id: id })
        } else {
          props.promotion.reset()
        }
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.promotion]
  )

  useEffect(() => {
    getProduct(market_id, promotion_id)
  }, [getProduct, market_id, promotion_id])

  const onBack = () => navigate(`/market/${market_id}/promotion`)
  const onCloseError = () => setErr('')
  const onDeleteItem = () => setConfirm(true)
  const onConfirmClose = () => setConfirm(false)

  const onSave = async () => {
    try {
      const checker = validator.process(rules, doc)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setSaving(true)

      doc.market_id = market_id
      await props.promotion.updatePromotion(doc)
      onBack()
      return
    } catch (e) {
      setErr(e.message)
    }
    setSaving(false)
  }

  const onDelete = async () => {
    try {
      setDeleting(true)
      onConfirmClose()
      await props.promotion.deletePromotion({ promotion_id })
      onBack()
      return
    } catch (e) {
      setErr(e.message)
    }
    setDeleting(false)
  }

  const onChange = (data) => props.promotion.setPromotion(data)
  const onValidate = (data) => setValidate({ ...data })

  return (
    <PageView>
      <Meta
        title="Market - รายละเอียดโปรโมชั่น"
        description="รายละเอียดโปรโมชั่น"
      />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Information
          doc={doc}
          validate={validate}
          onChange={onChange}
          onValidate={onValidate}
        />

        <Loading loading={loading}>
          <UnitList doc={doc} unit_list={unit_list} />
        </Loading>

        <SaveBtn
          loading={saving}
          onClick={onSave}
          deleting={deleting}
          onDelete={promotion_id ? onDeleteItem : undefined}
        />
        <ErrorDialog error={err} onClose={onCloseError} />
        <ConfirmDialog
          visible={confirm}
          title="ยืนยันการลบโปรโมชั่น"
          message="คุณต้องการที่จะลบโปรโมชั่น ใช่หรือไม่?"
          onClose={onConfirmClose}
          onConfirm={onDelete}
        />
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

export default inject('promotion')(observer(Promotion))
