import styled from 'styled-components'

const BookingTypeName = (props) => {
  const { booking_type } = props

  const getContent = () => {
    switch (booking_type) {
      case 'daily':
        return <Daily>รายวัน</Daily>
      case 'monthly':
        return <Monthly>รายเดือน</Monthly>
      default:
        return <Round>บูธอีเวนท์</Round>
    }
  }

  return getContent()
}

const Card = styled.span`
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 4px;
  color: white;
`

const Daily = styled(Card)`
  background-color: ${(p) => p.theme.color.red};
`

const Monthly = styled(Card)`
  background-color: ${(p) => p.theme.color.green};
`

const Round = styled(Card)`
  background-color: ${(p) => p.theme.color.blue};
`

export default BookingTypeName
