import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import image from 'img'
import { Marget } from 'icon'
import { ErrorDialog } from 'dialog'
import { Meta } from 'components/display'
import Loading from 'components/loading'
import UserMenu from 'menu/UserMenu'

import MyMarket from './MyMarket'
import NoMarket from './NoMarket'

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')

  const { dashboard } = props.market.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.market.getMarketWithOrder(),
        props.order.countAllOrder(),
        props.order.getAllOrderList(),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.market, props.order])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')

  const isNoMarket = dashboard.list.length === 0
  const content = isNoMarket ? <NoMarket /> : <MyMarket />

  return (
    <PageView>
      <Meta title="Market - dashboard" description="dashboard" />

      <ContentView>
        <Logo>
          <Marget size={1.2} />
        </Logo>

        <Loading loading={loading}>{content}</Loading>
      </ContentView>
      <UserMenu visible={!isNoMarket} selected="market" />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 40px 0px;
  min-height: 90vh;
  background-image: url(${image.landing});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
`

const ContentView = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`

const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`

export default inject('market', 'order')(observer(Dashboard))
