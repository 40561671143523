import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { InputLine, Line } from 'components/market/components'
import { Checker, Input, SelectList } from 'components/input'
import { bank_list } from 'definition'

const Bank = (props) => {
  const {
    doc = {},
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props
  const bank_code = doc.bank_code || null

  const onBankCode = (val) => {
    doc.bank_code = val
    doc.account_no = ''
    onChange(doc)

    delete validate.bank_code
    delete validate.account_no
    onValidate(validate)
  }

  const onCh = (val, name) => {
    doc[name] = val
    onChange(doc, name)

    delete validate[name]
    onValidate(validate)
  }

  const onChecker = (val, name) => {
    doc[name] = val ? 'yes' : 'no'
    onChange(doc, name)
  }

  const getPattern = () => {
    const bank = bank_list.find((it) => it.bank_code === bank_code)

    return bank || { limit: 100, pattern: 'xxx-x-xxxxx-xxx' }
  }

  const bank = getPattern()

  return (
    <PageContent>
      <InputLine label="ข้อมูลการเงิน">
        <Line>
          <SelectList
            placeholder="เลือกธนาคาร"
            menu={bank_list}
            name="bank_code"
            value={bank_code}
            onChange={onBankCode}
            invalid={validate['bank_code']}
            clear={false}
          />
        </Line>
        <Line>
          <Input
            label="ชื่อบัญชี"
            name="account_name"
            value={doc.account_name}
            onChange={onCh}
            invalid={validate['account_name']}
          />
        </Line>

        <Input
          label="หมายเลขบัญชี"
          name="account_no"
          value={doc.account_no}
          disabled={!bank_code}
          onChange={onCh}
          invalid={validate['account_no']}
          isNumber={true}
          pattern={bank.pattern}
        />
      </InputLine>

      <InputLine label="เลือกวิธีการส่งใบกำกับภาษีให้ผู้เช่า">
        <Row>
          <Col lg={12} md={12} xs={24}>
            <Line>
              <Checker
                label="จัดส่งที่หน้าตลาด"
                name="delivery_market"
                value={doc.delivery_market === 'yes'}
                onChange={onChecker}
              />
            </Line>
          </Col>

          <Col lg={12} md={12} xs={24}>
            <Checker
              label="จัดส่งทางไปรษณีย์"
              name="delivery_post_office"
              value={doc.delivery_post_office === 'yes'}
              onChange={onChecker}
            />
          </Col>
        </Row>
      </InputLine>

      <InputLine label="เบอร์ติดต่อตลาด">
        <Row>
          <Col lg={12} md={12} xs={13} position="left">
            <Input
              label="หน้าตลาด"
              name="contact_phone"
              value={doc.contact_phone}
              onChange={onCh}
              invalid={validate['contact_phone']}
              isNumber={true}
              pattern="xxx-xxx-xxxx"
            />
          </Col>

          <Col lg={12} md={12} xs={11} position="right">
            <Input
              label="ชื่อผู้ติดต่อ"
              name="contact_name"
              value={doc.contact_name}
              onChange={onCh}
              invalid={validate['contact_name']}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} xs={13} position="left">
            <Input
              label="การจอง, ใบเสร็จ, และอื่นๆ"
              name="support_contact_phone"
              value={doc.support_contact_phone}
              onChange={onCh}
              isNumber={true}
              pattern="xxx-xxx-xxxx"
            />
          </Col>

          <Col lg={12} md={12} xs={11} position="right">
            <Input
              label="ชื่อผู้ติดต่อ"
              name="support_contact_name"
              value={doc.support_contact_name}
              onChange={onCh}
            />
          </Col>
        </Row>
      </InputLine>
    </PageContent>
  )
}

const PageContent = styled.div`
  width: 100%;
`

export default Bank
