import styled from 'styled-components'

const Dot = (props) => {
  const { css } = props
  return <PageView style={css} />
}

const PageView = styled.div`
  width: 100%;
  border: 0px dashed ${(p) => p.theme.color_level.grey.low};
  border-bottom-width: 1px;
`

export default Dot
