import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Phnom_Penh')
moment.updateLocale('en', {
  monthsShort: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค',
  ],
  // weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  weekdaysMin: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
})

export class Timer {
  get(init, format) {
    if (init && format) return moment(init, format)
    else if (init) return moment(init)

    return moment()
  }

  getDate() {
    const val = moment().startOf('day')
    return val
  }

  getUnix(init, format) {
    return this.get(init, format).unix()
  }

  getNoMinute() {
    return moment().minute(0).second(0)
  }

  format() {
    return moment().format()
  }

  toThatDate(val, time = false) {
    const date = moment(val)
    const text_date = date.format('DD/MM/') + `${date.year()}`

    const text_time = time ? ` ${date.format('HH:mm')}` : ''
    return text_date + text_time
  }

  convertToMoment(val, format) {
    try {
      if (val === '' || val === undefined || val === null) return undefined
      else if (moment.isMoment(val)) return val

      return moment(val, format)
    } catch (e) {
      console.log('to moment:', e.message)
    }

    return undefined
  }

  getFinishMonth(init, format) {
    const val = this.get(init, format)

    return val.add(1, 'months').subtract(1, 'days')
  }

  toStart(val) {
    return val.startOf('day')
  }

  isBetween(src, t1, t2) {
    const s = src.unix()
    const v1 = t1.unix()
    const v2 = t2.unix()

    return s >= v1 && s <= v2
  }
}

export const timer = new Timer()
export default timer
