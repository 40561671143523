import BaseDrawer from './BaseDrawer'
import Floorplan from './Floorplan'

const FloorplanDrawer = (props) => {
  const {
    visible,
    init,
    name = '',
    pin_list = [],
    img_url,
    viewMode,
    onClose,
    onSave,
  } = props

  const text = name !== '' ? ` (${name})` : ''
  return (
    <BaseDrawer
      title={`ระบุตําแหน่ง${text}`}
      visible={visible}
      height="90%"
      onClose={onClose}
    >
      <Floorplan
        visible={visible}
        init={init}
        img_url={img_url}
        pin_list={pin_list}
        viewMode={viewMode}
        onSave={onSave}
      />
    </BaseDrawer>
  )
}

export default FloorplanDrawer
