import styled from 'styled-components'

import { Add } from 'icon'

const AddBtn = (props) => {
  const { onAdd = () => {} } = props

  return (
    <PageView>
      <Btn onClick={onAdd}>
        <Add />
        <Text>กดเพื่อเพิ่มรูป</Text>
        <Text>แผนผังตลาด</Text>
      </Btn>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
`

const Btn = styled.div`
  width: 100%;
  height: 295px;
  background-color: ${(p) => p.theme.color_level.blue.low};
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  svg {
    margin-bottom: 12px;
  }
`

const Text = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 1.2;
  color: ${(p) => p.theme.color.disable};
`

export default AddBtn
