import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import { Meta, BackHeader, Title, Content } from 'components/display'

const ContactUs = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }

  return (
    <PageView>
      <Meta title="ติดต่อเรา" description="ติดต่อเราทีมงาน marget" />
      <BackHeader onClick={onBack} />
      <Content isHeader={true}>
        <Title text="ติตต่อเรา" />
        <Label>ที่อยู่</Label>
        <Text>ถนนเพลินจิต แขวงลุมพินี เขตปทุมวัน กรุงเทพฯ 10330</Text>

        <Label>ติดต่อ</Label>
        <Text>
          Email: <a href="mailto: Bymarget@gmail.com">Bymarget@gmail.com</a>
        </Text>
        <Text>
          คุณเดือน: <a href="tel:086-990-0387">086-990-0387</a>
        </Text>
        <Text>
          คุณจิม: <a href="tel:081-441-9492">081-441-9492</a>
        </Text>
        <Text>
          คุณออย: <a href="tel:087-704-8445">087-704-8445</a>
        </Text>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  min-height: 85vh;
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
`

const Label = styled.div`
  font-weight: bold;
  margin-top: 20px;
`

const Text = styled.div`
  a {
    color: ${(p) => p.theme.color.blue};
  }
`

export default ContactUs
