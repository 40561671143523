import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row as AntdRow } from 'antd'

import { Row, Col } from 'components/display'
import { Checker, SelectList, Input } from 'components/input'
import { timer, market } from 'utils'
import { Add } from 'icon'

import Label from '../Label'
import DayItem from './DayItem'
import RoundItem from './RoundItem'

const BookingType = (props) => {
  const { doc = {}, validate = {}, onChange } = props

  const { daily_type, round_type } = doc
  const isDailySpecific = daily_type === 'specific'
  const isRoundSpecific = round_type === 'specific'

  const onChangeItem = (val, name) => {
    doc[name] = val
    onChange(doc)
  }

  const onRoundItem = (val, i) => {
    doc.round_price_list[i] = val
    onChange(doc)
  }

  const onRemoveRoundItem = (i) => {
    doc.round_price_list.splice(i, 1)
    onChange(doc)
  }

  const onDailyType = (val) => {
    doc.daily_type = val ? 'specific' : 'none'
    if (val) {
      for (const item of day_list) {
        const current = doc[item.value] || 0
        doc[item.value] = current || doc.price_daily
      }
    }
    onChange(doc)
  }

  const onRoundType = (val) => {
    doc.round_type = val ? 'specific' : 'none'
    if (val) {
      let round_price_list = doc.round_price_list || []

      if (round_price_list.length === 0) {
        const { setting } = props.marketSetting.toJS()
        const { open_type } = setting
        if (open_type === 'manual') {
          const round_list = market.getRoundList(
            timer.get(setting.start_at),
            timer.get(setting.finish_at),
            setting.event_round || 1
          )

          round_price_list = round_list.map((it) => {
            it.price = +doc.price_round || 0
            return it
          })
        }
      }

      doc.round_price_list = round_price_list
    }
    onChange(doc)
  }

  const onAdd = () => {
    const round_price_list = doc.round_price_list || []
    const item = {
      start_at: timer.get(),
      finish_at: timer.get(),
      price: +doc.price_round || 0,
    }

    round_price_list.push(item)
    doc.round_price_list = round_price_list
    onChange(doc)
  }

  const getDailyPriceList = () => {
    if (!isDailySpecific) {
      return undefined
    }

    const content = day_list.map((it, i) => {
      const price_name = it.value
      return (
        <DayItem
          key={i}
          name={it.name}
          price_name={price_name}
          doc={doc}
          onChange={onChange}
          validate={validate}
        />
      )
    })

    return <PriceContent>{content}</PriceContent>
  }

  const getRoundPriceList = () => {
    if (!isRoundSpecific) {
      return undefined
    }

    const round_price_list = doc.round_price_list || []
    const content = round_price_list.map((it, i) => {
      return (
        <RoundItem
          key={i}
          item={it}
          index={i}
          onChange={onRoundItem}
          onRemove={onRemoveRoundItem}
        />
      )
    })

    return <PriceContent>{content}</PriceContent>
  }

  const getPriceName = () => {
    switch (doc.booking_type) {
      case 'monthly':
        return 'price_monthly'
      case 'round':
        return 'price_round'
      default:
        return 'price_daily'
    }
  }

  const getSetting = () => {
    let btn
    switch (doc.booking_type) {
      case 'daily':
        return (
          <PriceList>
            <Checker
              label="ระบุราคาแต่ละวัน"
              value={isDailySpecific}
              onChange={onDailyType}
            />
            {getDailyPriceList()}
          </PriceList>
        )
      case 'round':
        btn = isRoundSpecific ? (
          <AddBtn onClick={onAdd}>
            <Add color={'#00B377'} />
            <span>เพิ่มเงื่อนไข</span>
          </AddBtn>
        ) : undefined
        return (
          <PriceList>
            <AntdRow align="middle">
              <Col lg={12} md={12} xs={12} position="left">
                <Checker
                  label="ระบุเงื่อนไข"
                  value={isRoundSpecific}
                  onChange={onRoundType}
                />
              </Col>
              <Col lg={12} md={12} xs={12} position="right">
                {btn}
              </Col>
            </AntdRow>
            {getRoundPriceList()}
          </PriceList>
        )
      default:
        return undefined
    }
  }

  const setting = getSetting()
  const price_name = getPriceName()
  return (
    <PageView>
      <Label text="ประเภทการเช่า" padding={false} />
      <BodyContent>
        <Row>
          <Col lg={12} md={12} xs={12} position="left">
            <SelectList
              placeholder="ประเภnการเช่า"
              menu={booking_list}
              name="booking_type"
              value={doc.booking_type}
              onChange={onChangeItem}
            />
          </Col>
          <Col lg={12} md={12} xs={12} position="right">
            <Input
              label="ราคา (บาท)"
              name={price_name}
              value={doc[price_name]}
              onChange={onChangeItem}
              disabled={doc.booking_type === 'daily' && isDailySpecific}
              isNumber={true}
              invalid={validate[price_name]}
            />
          </Col>
        </Row>
        {setting}
      </BodyContent>
    </PageView>
  )
}

const booking_list = [
  {
    name: 'รายวัน',
    value: 'daily',
    price_name: 'price_daily',
  },
  {
    name: 'รายเดือน',
    value: 'monthly',
    price_name: 'price_monthly',
  },
  {
    name: 'บูธอีเวนท์',
    value: 'round',
    price_name: 'price_round',
  },
]

const day_list = [
  { name: 'จันทร์', value: 'price_monday' },
  { name: 'อังคาร', value: 'price_tuesday' },
  { name: 'พุธ', value: 'price_wednesday' },
  { name: 'พฤหัสบดี', value: 'price_thursday' },
  { name: 'ศุกร์', value: 'price_friday' },
  { name: 'เสาร์', value: 'price_saturday' },
  { name: 'อาทิตย์', value: 'price_sunday' },
]

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const BodyContent = styled.div`
  width: 100%;
  padding-top: 12px;
`

const PriceList = styled.div`
  width: 100%;
  padding-top: 16px;
`

const PriceContent = styled.div`
  width: 100%;
  padding-top: 8px;
`

const AddBtn = styled.div`
  display: flex;
  align-items: center;
  float: right;
  cursor: pointer;

  span {
    margin-left: 8px;
    font-size: 14px;
    color: ${(p) => p.theme.color_level.grey.dark};
  }
`

export default inject('marketSetting')(observer(BookingType))
