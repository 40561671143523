import styled from 'styled-components'

import { Place, Others, Space } from 'components/market/components'

const Information = (props) => {
  const {
    doc,
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props

  return (
    <PageContent>
      <Place
        doc={doc}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate}
      />
      <Space />

      <Others
        doc={doc}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate}
      />
    </PageContent>
  )
}

const PageContent = styled.div`
  width: 100%;
`

export default Information
