import styled from 'styled-components'

const Step = (props) => {
  const { menu = [] } = props

  const content = menu.map((it, i) => {
    return (
      <Btn key={i}>
        <No>{i + 1}</No>
        <Text>{it.name}</Text>
      </Btn>
    )
  })

  return (
    <PageView>
      <NoLine>{content}</NoLine>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Btn = styled.div``

const NoLine = styled.div`
  width: 80%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #dcdcdc;
  border-radius: 12px;
  margin: 0 auto;
`

const No = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 14px;
  color: white;
  background-color: ${(p) => p.theme.color.blue};
`

const Text = styled.span`
  color: ${(p) => p.theme.color_level.grey.low};
  position: relative;
  width: max-content;
  bottom: -20px;
  left: -20px;
`

export default Step
