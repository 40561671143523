import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Menu from 'components/menu'
import { Meta, Content } from 'components/display'
import { PrimaryBtn } from 'components/button'
import UserMenu from 'menu/UserMenu'

import Header from './Header'

const Profile = (props) => {
  const navigate = useNavigate()

  const onLogout = async () => {
    await props.member.logout()
    navigate('/')
  }

  return (
    <PageView>
      <Meta title="บัญชีของฉัน" description="เมนู บัญชีของฉัน" />
      <Content>
        <Header />

        <Menu title="บัญชีของฉัน" menu={account_menu} />

        <Menu title="ข้อมูลทั่วไป" menu={general_menu} />

        <Button>
          <PrimaryBtn text="ออกจากระบบ" onClick={onLogout} />
        </Button>

        <UserMenu visible={true} selected="account" />
      </Content>
    </PageView>
  )
}

const account_menu = [
  { label: 'ข้อมูลส่วนตัว', link: '/account/information' },
  { label: 'ตลาดของฉัน', link: '/market?source=/account' },
  { label: 'รายการจองของฉัน', link: '/booking', disable: true },
]

const general_menu = [
  { label: 'เกี่ยวกับเรา', link: '/about-us?source=/account' },
  { label: 'ติดต่อเรา', link: '/contact-us?source=/account' },
  { label: 'เงื่อนไขการให้บริการ', link: '/account/consent/terms' },
  { label: 'นโยบายความเป็นส่วนตัว', link: '/account/consent/privacy' },
  { label: 'Cookie policy', link: '/account/consent/cookie' },
]

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
`

export default inject('member')(observer(Profile))
