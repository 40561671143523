import Layout from './components/Layout'

const Floorplan = (props) => {
  const {
    visible = true,
    img_url,
    height,
    size = 20,
    pin_list = [],
    onSelected,
  } = props
  return (
    <Layout
      visible={visible}
      viewMode="view"
      img_url={img_url}
      height={height}
      size={size}
      pin_list={pin_list}
      onSelected={onSelected}
    />
  )
}

export default Floorplan
