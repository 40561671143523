import React from 'react'
import styled from 'styled-components'

const Label = (props) => {
  const { text } = props

  return <Title>{text}</Title>
}

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 16px;
  color: ${(p) => p.theme.color.blue};
`

export default Label
