import styled from 'styled-components'

import { FindInput } from 'components/input'

const Header = (props) => {
  const { filter, onFilter } = props

  return (
    <PageView>
      <FindInput
        placeholder="ค้นหารายละเอียด"
        value={filter}
        onChange={onFilter}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default Header
