import { format } from './format'

export class Product {
  toProductType(doc = {}) {
    switch (doc.product_type) {
      case 'type_food':
      case 'food':
        return 'อาหาร'
      case 'type_drink':
      case 'drink':
        return 'เครื่องดื่ม'
      case 'type_clothes':
      case 'clothes':
        return 'เสื้อผ้า'
      case 'type_things':
      case 'things':
        return 'ของใช้'
      default:
        return 'บริการ'
    }
  }

  toShopType(doc = {}) {
    const list = [
      { name: 'เตาไฟฟ้า', value: 'electric_stove_type' },
      { name: 'เตาแก๊ส', value: 'gas_stove_type' },
      { name: 'อาหารหนัก (ข้าว, เส้น)', value: 'food_type' },
      { name: 'น้ำชงทุกชนิด', value: 'make_drink_type' },
      { name: 'น้ำเปล่า น้ำอัดลม', value: 'drink_type' },
      { name: 'มูลนิธิ', value: 'foundation_type' },
      { name: 'บูธบัตรเครดิต / เน็ตบ้าน', value: 'direct_sale_type' },
    ]

    const content_list = list
      .filter((it) => doc[it.value] === 1)
      .map((it) => it.name)

    const text = content_list.join(', ')
    return text
  }

  getStatus({ status, market_note, due_payment_at }) {
    let text = ''
    let note = ''
    let comment = ''
    let color = ''
    const date = format.toThaiDatetime(due_payment_at)
    switch (status) {
      case 'create':
        text = 'รอการยืนยัน'
        note = 'กรุณาตรวจสอบข้อมูล และอนุมัติการจอง'
        color = '#F5BF57'
        break
      case 'payment':
        text = 'รอชำระเงิน'
        note = `กรุณาชำระเงินภายใน ${date}`
        comment = market_note
        color = '#D78F00'
        break
      case 'offer':
        text = 'เสนอใหม่'
        note = market_note
        color = '#577AF5'
        break
      case 'completed':
        text = 'การจองสำเร็จแล้ว'
        note = market_note
        color = '#00C767'
        break
      default:
        text = 'การจองถูกยกเลิก'
        note = market_note
        color = '#ED3034'
    }

    return { text, note, comment, color }
  }

  getDate(start_at, finish_at) {
    const start = format.toThaiDate(start_at)
    const finish = format.toThaiDate(finish_at)
    const date = start === finish ? start : `${start} - ${finish}`
    return date
  }

  getTextPromotion(promotion) {
    const val = format.toDigi(promotion.value)
    const date = this.getDate(promotion.start_at, promotion.finish_at)

    return `ส่วนลด ${val} บาท วันที่ ${date}`
  }
}

export const product = new Product()
export default product
