import React from 'react'
import styled from 'styled-components'

import { Radio, Input, InputArea, HtmlEditor } from 'components/input'

import Line from './Line'
import InputLine from './InputLine'

const Others = (props) => {
  const {
    doc = {},
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props

  const onCh = (val, name) => {
    doc[name] = val
    onChange(doc)

    delete validate[name]
    onValidate(validate)
  }

  return (
    <PageView>
      <InputLine label="ที่จอดรถ">
        <Line>
          <Radio
            gap={125}
            menu={parking_menu}
            name="parking_status"
            value={doc.parking_status}
            onChange={onCh}
          />
        </Line>

        <Input
          label="ระบุค่าจอด"
          name="parking_info"
          disabled={doc.parking_status !== 'yes'}
          value={doc.parking_info}
          onChange={onCh}
        />
      </InputLine>

      <InputLine label="อุปกรณ์ที่มีให้">
        <InputArea
          label="เช่น โต๊ะ, เก้าอี้"
          name="equipment"
          value={doc.equipment}
          onChange={onCh}
          invalid={validate['equipment']}
        />
      </InputLine>

      <InputLine label="ข้อห้ามของตลาด (ถ้ามี)">
        <InputArea
          label="เช่น เตาถ่านปิ้งย่าง"
          name="sale_condition"
          value={doc.sale_condition}
          onChange={onCh}
        />
      </InputLine>

      <InputLine label="รายละเอียด (ถ้ามี)">
        <HtmlEditor
          init={doc.market_id}
          name="detail"
          value={doc.detail}
          onChange={onCh}
        />
      </InputLine>
    </PageView>
  )
}

const parking_menu = [
  { name: 'มี', value: 'yes' },
  { name: 'ไม่มี', value: 'no' },
]

const PageView = styled.div`
  width: 100%;
`

export default Others
