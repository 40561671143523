import styled from 'styled-components'

const Pagination = (props) => {
  const { list = [], selected, onChange } = props

  if (list.length < 2) {
    return <div />
  }

  const content = list.map((it, i) => {
    const floorplan_id = it.floorplan_id
    const css = floorplan_id === selected ? 'active' : ''
    const click = floorplan_id === selected ? undefined : () => onChange(it)
    return (
      <Btn key={i} className={css} onClick={click}>
        {i + 1}
      </Btn>
    )
  })

  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`

const Btn = styled.div`
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  color: white;
  background-color: ${(p) => p.theme.color.disable};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background-color: ${(p) => p.theme.color.green};
  }
`

export default Pagination
