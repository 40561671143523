import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'

import { Meta, BackHeader, Title, Content } from 'components/display'

import Calculator from './Calculator'

const per_lock = 5
const per_admin = 50

const MargetPrice = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/'
    navigate(path)
  }

  return (
    <PageView>
      <Meta title="ค่าบริการ" description="ค่าบริการ" />
      <BackHeader onClick={onBack} />
      <Content isHeader={true}>
        <Title text="ค่าบริการ" />
        <Label>รายละเอียด</Label>
        <List>
          <li>10 ล็อกใช้งานฟรี</li>
          <li>50 ล็อกแรก 500 บาทต่อเดือน</li>
          <li>หลังจากนั้นคิดล็อกละ {per_lock} บาทต่อเดือน</li>
          <li>
            ผู้ดูแลระบบ 2 คนแรกฟรี หลังจากนั้นคนละ {per_admin} บามต่อเดือน
          </li>
        </List>
        <Calculator />
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  min-height: 85vh;
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
`

const Label = styled.div`
  font-weight: bold;
  margin-top: 20px;
`

const List = styled.ul``

export default MargetPrice
