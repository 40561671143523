import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: undefined,
  ver: '',
  consent_type: 'privacy',
  status: 'active',
  content: '',
}

let state
export class Consent extends BaseStore {
  constructor() {
    super()
    this.observable({
      cookie: cloneDeep(original),
      privacy: cloneDeep(original),
      terms: cloneDeep(original),

      my_privacy: cloneDeep(original),
      my_terms: cloneDeep(original),
    })
    state = this
  }

  async getPrivacy() {
    const doc = this.toJS().privacy || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/public/consent/privacy`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.privacy = tmp
    })
  }

  async getTerms() {
    const doc = this.toJS().terms || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/public/consent/terms`
    const resp = await http.get(url)

    const data = resp.body || {}
    const tmp = data.consent_id ? data : cloneDeep(original)

    runInAction(() => {
      state.terms = tmp
    })
  }

  async getCookie() {
    const doc = this.toJS().cookie || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/public/consent/cookie`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.cookie = tmp
    })
  }

  async getMyPrivacy() {
    const doc = this.toJS().my_privacy || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/user/consent/privacy`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.my_privacy = tmp
    })
  }

  async getMyTerms() {
    const doc = this.toJS().my_terms || {}
    if (doc.consent_id) {
      return
    }

    const url = `${config.api}/v1/user/consent/terms`
    const resp = await http.get(url)

    const data = resp.body || {}
    const tmp = data.consent_id ? data : cloneDeep(original)

    runInAction(() => {
      state.my_terms = tmp
    })
  }
}

export default new Consent()
