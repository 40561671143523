import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4335 2.8125H18.2588C18.1343 2.8125 18.0148 2.86209 17.9269 2.95031C17.8393 3.03816 17.7901 3.15717 17.7901 3.28125H17.7901V7.5C17.7901 7.75884 18 7.96875 18.2588 7.96875C18.5177 7.96875 18.7276 7.75884 18.7276 7.5V6.13373C18.9789 6.13242 19.2715 6.13106 19.4335 6.13106C20.3613 6.13106 21.1162 5.38673 21.1162 4.47183C21.1162 3.55692 20.3613 2.8125 19.4335 2.8125ZM19.4335 5.19356C19.2722 5.19356 18.9834 5.19483 18.7332 5.19619C18.7319 4.93781 18.7306 4.63678 18.7306 4.47183C18.7306 4.33148 18.7298 4.01962 18.7291 3.75005H19.4335C19.8374 3.75005 20.1787 4.08061 20.1787 4.47183C20.1787 4.86305 19.8374 5.19356 19.4335 5.19356Z"
        fill="#494949"
      />
      <path
        d="M14.3685 14.2137L13.4962 11.306C13.1665 10.2071 12.1741 9.46875 11.0268 9.46875H5.37942C4.23211 9.46875 3.23977 10.2071 2.91005 11.306L2.03775 14.2137C0.874781 14.4628 0 15.4981 0 16.7344V20.4844C0 20.8727 0.314812 21.1875 0.703125 21.1875H0.9375V21.8906C0.9375 23.0537 1.88377 24 3.04688 24C4.20998 24 5.15625 23.0537 5.15625 21.8906V21.1875H11.25V21.8906C11.25 23.0537 12.1963 24 13.3594 24C14.5225 24 15.4688 23.0537 15.4688 21.8906V21.1875H15.7031C16.0914 21.1875 16.4062 20.8727 16.4062 20.4844V16.7344C16.4062 15.4981 15.5315 14.4628 14.3685 14.2137ZM4.25695 11.7101C4.40686 11.2106 4.85794 10.875 5.37942 10.875H11.0269C11.5484 10.875 11.9994 11.2106 12.1493 11.7101L12.8831 14.1562H3.52313L4.25695 11.7101ZM2.57812 15.5625H13.8281C14.4743 15.5625 15 16.0882 15 16.7344V16.9688H13.8281C13.4398 16.9688 13.125 17.2836 13.125 17.6719C13.125 18.0602 13.4398 18.375 13.8281 18.375H15V19.7812H1.40625V18.375H2.57812C2.96644 18.375 3.28125 18.0602 3.28125 17.6719C3.28125 17.2836 2.96644 16.9688 2.57812 16.9688H1.40625V16.7344C1.40625 16.0882 1.93195 15.5625 2.57812 15.5625ZM3.75 21.8906C3.75 22.2783 3.43458 22.5938 3.04688 22.5938C2.65917 22.5938 2.34375 22.2783 2.34375 21.8906V21.1875H3.75V21.8906ZM14.0625 21.8906C14.0625 22.2783 13.7471 22.5938 13.3594 22.5938C12.9717 22.5938 12.6562 22.2783 12.6562 21.8906V21.1875H14.0625V21.8906Z"
        fill="#494949"
      />
      <path
        d="M22.3594 0H16.2656C15.361 0 14.625 0.735984 14.625 1.64062V9.14062C14.625 10.0453 15.361 10.7812 16.2656 10.7812H18.6094V22.5938H17.4375C17.0492 22.5938 16.7344 22.9086 16.7344 23.2969C16.7344 23.6852 17.0492 24 17.4375 24H21.1875C21.5758 24 21.8906 23.6852 21.8906 23.2969C21.8906 22.9086 21.5758 22.5938 21.1875 22.5938H20.0156V10.7812H22.3594C23.264 10.7812 24 10.0453 24 9.14062V1.64062C24 0.735984 23.264 0 22.3594 0ZM22.5938 9.14062C22.5938 9.26986 22.4886 9.375 22.3594 9.375H16.2656C16.1364 9.375 16.0312 9.26986 16.0312 9.14062V1.64062C16.0312 1.51139 16.1364 1.40625 16.2656 1.40625H22.3594C22.4886 1.40625 22.5938 1.51139 22.5938 1.64062V9.14062Z"
        fill="#494949"
      />
      <path
        d="M6.32812 18.375H10.0781C10.4664 18.375 10.7812 18.0602 10.7812 17.6719C10.7812 17.2836 10.4664 16.9688 10.0781 16.9688H6.32812C5.93981 16.9688 5.625 17.2836 5.625 17.6719C5.625 18.0602 5.93981 18.375 6.32812 18.375Z"
        fill="#494949"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
