import styled from 'styled-components'

import { BookingTypeName } from 'components/market/components'

const BookingType = (props) => {
  const { booking_type } = props

  return (
    <PageView>
      <BookingTypeName booking_type={booking_type} />
    </PageView>
  )
}

const PageView = styled.div`
  margin-top: 8px;
`

export default BookingType
