import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'

import { format } from 'utils'
import { bank_list } from 'definition'

import { Label } from './components'

const MarketBank = (props) => {
  const navigate = useNavigate()
  const { doc = {} } = props
  const {
    market_id,
    bank_code,
    account_name,
    account_no,
    contact_name,
    contact_phone,
    support_contact_name,
    support_contact_phone,
    delivery_market,
    delivery_post_office,
  } = doc

  const onEdit = () => navigate(`/market/${market_id}/editor-banking`)

  const display = (title, text) => {
    return (
      <Line>
        <Row>
          <Col lg={12} md={12} xs={12}>
            {title}
          </Col>
          <Col lg={12} md={12} xs={12}>
            <Info>{text}</Info>
          </Col>
        </Row>
      </Line>
    )
  }

  const getContact = (name, mobile) => {
    const val = format.toMobile(mobile)
    return `${name} (${val})`
  }

  const bank = bank_list.find((it) => it.value === bank_code)
  const bankInfo = bank ? bank : {}
  const accountNo = format.toPattern(account_no, bankInfo.pattern)
  const contact = getContact(contact_name, contact_phone)
  const contactSupport = getContact(support_contact_name, support_contact_phone)

  const list = []
  if (delivery_market === 'yes') {
    list.push('จัดส่งที่หน้าตลาด')
  }

  if (delivery_post_office === 'yes') {
    list.push('จัดส่งทางไปรษณีย์')
  }

  const delivery = list.join()

  return (
    <PageView>
      <Label text="ข้อมูลการเงิน" onEdit={onEdit} />

      <Title>บัญชีรับเงิน</Title>
      {display('ธนาคาร', bankInfo.name || '-')}
      {display('ชื่อบัญชี', account_name || '-')}
      {display('เลขที่บัญชี', accountNo || '-')}
      <Space />

      <Title>วิธีการส่งใบกำกับภาษีให้ผู้เช่า</Title>
      <Line>{delivery}</Line>
      <Space />

      <Title>เบอร์ติดต่อตลาด</Title>
      {display('หน้าตลาด', contact)}
      {display('การจอง,ใบเสร็จ,และอื่นๆ', contactSupport)}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 25px 0px;
`

const Line = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Title = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4;
`

const Info = styled.div`
  line-height: 1.4;
  text-align: right;
`

const Space = styled.div`
  height: 20px;
`

export default MarketBank
