import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Link } from 'components/link'
import { Row, Col } from 'components/display'
import Order from 'pages/allBookingList/Order'

const OrderList = (props) => {
  const { all_order } = props.order.toJS()
  const { total, list } = all_order

  const getOrderList = () => {
    const source = '/'
    return list.map((it, i) => {
      return <Order key={i} item={it} source={source} />
    })
  }

  const content =
    list.length === 0 ? (
      <Coming>ยังไม่มี รายการรอยืนยัน</Coming>
    ) : (
      getOrderList()
    )
  return (
    <PageView>
      <Row align="middle">
        <Col span={16}>
          <Title>
            รายการรอยืนยัน
            <Total>{total || 0}</Total>
          </Title>
        </Col>
        <Col span={8}>
          <TotalOrder>
            <Link to="/booking">ดูทั้งหมด</Link>
          </TotalOrder>
        </Col>
      </Row>

      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 0px 24px;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.green};
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 6px;
`

const Total = styled.div`
  width: 32px;
  height: 32px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  background-color: ${(props) => props.theme.color.green};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TotalOrder = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  text-align: right;
  text-decoration: underline;
`

const Coming = styled.div`
  width: 100%;
  color: ${(props) => props.theme.color_level.grey.low};
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  padding: 40px 0px;
`

export default inject('order')(observer(OrderList))
