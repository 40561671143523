import styled from 'styled-components'

const Label = (props) => {
  const { text, padding = true } = props

  const css = padding ? { padding: '16px 0px' } : undefined
  return <PageView style={css}>{text}</PageView>
}

const PageView = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
`

export default Label
