import styled from 'styled-components'

const Section = (props) => {
  const { title, children } = props
  return (
    <PageView>
      <Label>{title}</Label>

      {children}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(props) => props.theme.color_level.grey.high};
  margin-bottom: 24px;
`

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 16px;
`

export default Section
