import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { PinHelpDialog } from 'helper'
import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content } from 'components/display'
import FloorplanDrawer from 'components/floorplan/EditDrawer'

import Header from './Header'
import ProductList from './ProductList'

const Unit = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const [help, setHelp] = useState(false)
  const [editPosition, setEditPosition] = useState(false)

  const navigate = useNavigate()
  const { market_id, floorplan_id, unit_id } = useParams()
  const location = useLocation()
  const { action } = queryString.parse(location.search)

  const { floorplan = {} } = props.floorplan.toJS()
  const { unit = {}, unit_list } = props.unit.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        props.marketSetting.getSetting(market_id),
        props.floorplan.getMarketInfo({ market_id }),
        props.floorplan.getFloorplanInfo({ floorplan_id }),
        props.unit.getUnitInfo({ unit_id }),
        props.unit.getUnitList({ floorplan_id }),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.floorplan, props.unit, market_id, floorplan_id, unit_id])

  useEffect(() => {
    onInit()
  }, [onInit])

  useEffect(() => {
    const isNewbie = action === 'newbie'
    setHelp(isNewbie)

    const isEdit = action === 'clone' || (!unit_id && !isNewbie)
    setEditPosition(isEdit)
  }, [action, unit_id])

  const onCloseError = () => setErr('')
  const onBack = () => {
    navigate(`/market/${market_id}/floor-plan/${floorplan_id}`)
  }

  const showEditPosition = () => setEditPosition(true)
  const closeEditPosition = () => setEditPosition(false)
  const closeHelp = () => {
    setHelp(false)
    showEditPosition()
  }

  const onEditPosition = (position = {}) => {
    unit.axis_x = position.x
    unit.axis_y = position.y
    props.unit.setUnit(unit)
    closeEditPosition()
  }

  const content = unit_id ? (
    <ProductList market_id={market_id} unit_id={unit_id} />
  ) : undefined

  const pin_list = unit_list
    .filter((it) => it.unit_id !== unit.unit_id)
    .map((it) => {
      return {
        x: it.axis_x,
        y: it.axis_y,
        unit: it,
      }
    })
  return (
    <PageView>
      <Meta title="Market - ตั้งค่า unit" description="ตั้งค่า unit" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Header isReady={!editPosition} onEditPosition={showEditPosition} />
        {content}
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
      <PinHelpDialog visible={help} onClose={closeHelp} />
      <FloorplanDrawer
        visible={editPosition}
        init={{ x: unit.axis_x, y: unit.axis_y }}
        name={unit.name}
        pin_list={pin_list}
        viewMode={unit_id === undefined ? 'create' : 'edit'}
        img_url={floorplan.img_url}
        onClose={closeEditPosition}
        onSave={onEditPosition}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('floorplan', 'unit', 'marketSetting')(observer(Unit))
