import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import image from 'img'
import { Marget } from 'icon'
import { Meta, Content } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'

const Announce = (props) => {
  const navigate = useNavigate()
  const onCreate = () => navigate('/new-market')
  const onSkip = () => navigate('/dashboard')

  return (
    <PageView>
      <Meta title="Market - Welcome" description="welcome to marget" />

      <Content>
        <Title>ยินดีต้อนรับ</Title>
        <Marget size={1.4} />

        <Bandner src={image.landing} />
        <Description>อีกขั้นตอนเดียว</Description>
        <Description>เพิ่มข้อมูลตลาดของคุณก็จะสมบูรณ์</Description>
        <Button>
          <PrimaryBtn
            text="สร้างตลาดของฉัน"
            onClick={onCreate}
            disabled={true}
          />
          <Btn text="ไว้ทีหลัง" onClick={onSkip} />
        </Button>
        <Note>คุณสามารถสร้างตลาดในภายหลัง</Note>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
  text-align: center;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 36px;
  font-weight: bold;
  margin-top: 10%;
  margin-bottom: 10px;
`

const Bandner = styled.img`
  width: 100%;
  margin-bottom: 20px;
`

const Description = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 18px;
  ine-height: 1;
`

const Note = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  margin: 20px 0px;
`

const Button = styled.div`
  width: 220px;
  margin: 20px auto;

  button {
    margin: 8px 0px;
  }
`

export default Announce
