import member from './Member'
import province from './Province'
import consent from './Consent'
import account from './Account'
import market from './Market'
import marketType from './MarketType'
import marketArea from './MarketArea'
import marketSetting from './MarketSetting'
import floorplan from './FloorPlan'
import unit from './Unit'
import product from './Product'
import promotion from './Promotion'
import order from './Order'

/* eslint-disable */
export default {
  member,
  account,
  province,
  consent,
  market,
  marketType,
  marketArea,
  marketSetting,
  floorplan,
  unit,
  product,
  promotion,
  order,
}
/* eslint-enable */
