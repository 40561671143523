import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Checker, Input } from 'components/input'

import Label from '../Label'

const BookingType = (props) => {
  const { doc = {}, onChange } = props

  const onCheck = (val, name) => {
    doc[name] = val ? 1 : 0
    onChange(doc)
  }

  const onText = (val, name) => {
    doc[name] = val
    onChange(doc)
  }

  const content = list.map((it, i) => {
    const { name, value } = it
    const isCheck = doc[value] === 1
    return (
      <Line key={i}>
        <Checker label={name} name={value} value={isCheck} onChange={onCheck} />
      </Line>
    )
  })

  return (
    <PageView>
      <Label text="สิ่งที่ห้ามนำเข้า" padding={false} />
      <BodyContent>
        {content}

        <Row align="middle">
          <Col span={3} position="left">
            <Checker
              name="ban_other"
              value={doc.ban_other}
              onChange={onCheck}
            />
          </Col>
          <Col span={21} position="right">
            <Input
              label="อื่นๆ โปรดระบุ"
              name="ban_other_text"
              value={doc.ban_other_text}
              disabled={doc.ban_other !== 1}
              onChange={onText}
            />
          </Col>
        </Row>
      </BodyContent>
    </PageView>
  )
}

const list = [
  {
    name: 'เตาไฟฟ้า',
    value: 'ban_electric_stove',
  },
  {
    name: 'เตาแก๊ส',
    value: 'ban_gas_stove',
  },
  {
    name: 'อาหารหนัก (ข้าว, เส้น)',
    value: 'ban_food',
  },
  {
    name: 'น้ำชงทุกชนิด',
    value: 'ban_make_drink',
  },
  {
    name: 'น้ำเปล่า น้ำอัดลม',
    value: 'ban_drink',
  },
  {
    name: 'มูลนิธิ',
    value: 'ban_foundation',
  },
  {
    name: 'บูธบัตรเครดิต / เน็ตบ้าน',
    value: 'ban_direct_sale',
  },
]

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

const BodyContent = styled.div`
  width: 100%;
  padding-top: 12px;
`

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default BookingType
