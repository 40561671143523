/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_339_1139)">
        <path
          d="M16.1124 14.6438L15.477 9.07781C15.3266 7.7505 14.2753 6.75 13.0333 6.75H10.5867C9.3466 6.75 8.29623 7.7505 8.14485 9.07781L7.50947 14.6438C7.45678 15.1096 7.60053 15.5724 7.90335 15.9117C8.16497 16.204 8.51092 16.3746 8.88754 16.3956L9.41654 23.5563C9.43473 23.8064 9.64367 24 9.89379 24H13.7271C13.9782 24 14.1871 23.8064 14.2044 23.5563L14.7334 16.3956C15.109 16.3746 15.4559 16.2049 15.7176 15.9117C16.0223 15.5714 16.1652 15.1096 16.1124 14.6438ZM15.0046 15.2734C14.949 15.3348 14.8302 15.4392 14.6538 15.4392H14.2887C14.0377 15.4392 13.8287 15.6328 13.8105 15.8829L13.2825 23.0417H10.3394L9.81235 15.8829C9.79317 15.6328 9.58522 15.4392 9.33415 15.4392H8.96997C8.7946 15.4392 8.67479 15.3348 8.61922 15.2734C8.49753 15.1364 8.44003 14.9466 8.46204 14.7521L9.09742 9.18611C9.19323 8.34374 9.83436 7.70836 10.5886 7.70836H13.0352C13.7885 7.70836 14.4296 8.34374 14.5264 9.18611L15.1618 14.7521C15.1838 14.9466 15.1263 15.1364 15.0046 15.2734Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M11.8104 5.79168C13.131 5.79168 14.2062 4.71643 14.2062 3.39586C14.2062 2.07529 13.132 1 11.8104 1C10.4899 1 9.41461 2.07525 9.41461 3.39582C9.41461 4.71639 10.4899 5.79168 11.8104 5.79168ZM11.8104 1.95832C12.603 1.95832 13.2479 2.60326 13.2479 3.39582C13.2479 4.18837 12.603 4.83332 11.8104 4.83332C11.0179 4.83332 10.3729 4.18837 10.3729 3.39582C10.3729 2.60331 11.0189 1.95832 11.8104 1.95832Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_339_1139">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
/* eslint-enable */
export default Logo
