import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'
import { InputArea } from 'components/input'
import { PrimaryBtn } from 'components/button'

const Reason = (props) => {
  const [note, setNote] = useState('')

  const { visible, onClose, onSave = () => {} } = props

  useEffect(() => {
    if (visible) {
      setNote('')
    }
  }, [visible])

  const onChange = (v) => setNote(v)
  const onClick = () => onSave(note)

  return (
    <BaseDrawer
      visible={visible}
      title="อนุมัติรายการจอง"
      height="290px"
      onClose={onClose}
    >
      <PageView>
        <Note>
          <InputArea
            label="ข้อความถึงร้านค้า"
            value={note}
            onChange={onChange}
          />
        </Note>

        <Button>
          <PrimaryBtn text="ยืนยันอนุมัติการจอง" onClick={onClick} />
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Line = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Note = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Button = styled.div`
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
  padding-top: 16px;
`

export default Reason
