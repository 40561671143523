import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import {
  Meta,
  BackHeader,
  Content,
  SaveBtn,
  Row,
  Col,
} from 'components/display'
import { Toggle, RadioItem, SelectList } from 'components/input'
import { DatePicker } from 'components/date'
import { validator, message, timer } from 'utils'

import Section from './Section'
import { getRule } from './rules'

const SaleSetting = (props) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [validate, setValidate] = useState({})
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id } = useParams()

  const { doc } = props.market.toJS()
  const { setting } = props.marketSetting.toJS()

  const today = timer.get().subtract(7, 'day').toDate()
  const min_finish = setting.start_at
    ? timer.get(setting.start_at).toDate()
    : today

  const isManual = setting.approve_type === 'manual'
  const isMonth = setting.open_type === 'month'

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.market.getMarketInfo(market_id),
        props.marketSetting.getSetting(market_id, true),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.market, props.marketSetting, market_id])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')
  const onBack = () => {
    navigate(`/market/${market_id}`)
  }

  const onSave = async () => {
    try {
      const condition = {}

      if (setting.open_type === 'manual') {
        condition.set_finish_at = true
      }
      const rules = getRule(condition)
      const checker = validator.process(rules, setting)

      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setProcessing(true)
      await props.marketSetting.updateSetting(market_id, setting)
      message.success()
    } catch (e) {
      setErr(e.message)
    }
    setProcessing(false)
  }

  const onChange = (val, name) => {
    setting[name] = val
    props.marketSetting.setSetting(setting)

    delete validate[name]
    setValidate({ ...validate })
  }

  const onStatus = (val) => {
    setting.status = val ? 'active' : 'inactive'
    props.marketSetting.setSetting(setting)
  }

  const onOpenType = (val, name) => {
    setting.open_type = name === 'month' ? 'month' : 'manual'
    props.marketSetting.setSetting(setting)
  }

  const onApproveType = (val, name) => {
    setting.approve_type = name === 'auto' ? 'auto' : 'manual'
    props.marketSetting.setSetting(setting)
  }

  const getRoundSetting = () => {
    const round = isMonth ? (
      <SelectList
        placeholder="จำนวนสัปดาห์ต่อรอบ"
        menu={week_round_menu}
        name="event_weeks"
        value={setting.event_weeks}
        onChange={onChange}
      />
    ) : (
      <SelectList
        placeholder="จำนวนรอบ"
        menu={round_menu}
        name="event_round"
        value={setting.event_round}
        onChange={onChange}
      />
    )

    return <Section title="ตั้งค่าบูธอีเวนท์">{round}</Section>
  }

  const openType = isMonth ? (
    <SelectList
      placeholder="เปิดจองล่วงหน้าอัตโนมัติ"
      menu={menu}
      name="next_value"
      value={setting.next_value}
      onChange={onChange}
    />
  ) : (
    <DatePicker
      placeholder="วันที่สุดท้ายที่ขาย"
      name="set_finish_at"
      value={setting.set_finish_at}
      onChange={onChange}
      min={min_finish}
      invalid={validate.set_finish_at}
    />
  )

  const roundMenu = doc.booking_round === 'yes' ? getRoundSetting() : undefined
  return (
    <PageView>
      <Meta title="Market - ตั้งค่าการจอง" description="ตั้งค่าการจอง" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Row align="middle">
          <Col lg={20} md={20} xs={18}>
            <Title>ตั้งค่าการจอง</Title>
          </Col>
          <Col lg={4} md={4} xs={6}>
            <Status>
              <Toggle value={setting.status === 'active'} onChange={onStatus} />
            </Status>
          </Col>
        </Row>

        <Section title="วันที่ขายของ">
          <Line>
            <DatePicker
              placeholder="วันที่เริ่มขาย"
              name="start_at"
              value={setting.start_at}
              onChange={onChange}
              min={today}
              invalid={validate.start_at}
            />
          </Line>
        </Section>

        <Section title="วันที่สิ้นสุดการขาย">
          <Line>
            <RadioItem
              label="ระบุวัน"
              name="manual"
              value={!isMonth}
              onChange={onOpenType}
            />
          </Line>

          <Line>
            <RadioItem
              label="จองต่อเนื่อง"
              name="month"
              value={isMonth}
              onChange={onOpenType}
            />
          </Line>

          {openType}
        </Section>

        {roundMenu}

        <Section title="เลือกวิธีอนุมัติการจอง">
          <Line>
            <RadioItem
              label="อนุมัติทันทีโดยอัตโนมัติ"
              name="auto"
              value={!isManual}
              onChange={onApproveType}
            />
          </Line>

          <RadioItem
            label="อนุมัติด้วยตัวท่านเอง"
            name="manual"
            value={isManual}
            onChange={onApproveType}
          />
        </Section>
        <Section title="กำหนดเวลาในการยืนยันการจ่าย">
          <SelectList
            placeholder="ยืนยันการจ่ายใน"
            menu={payment_menu}
            name="payment_hours"
            value={setting.payment_hours}
            onChange={onChange}
          />
        </Section>
      </Content>
      <SaveBtn loading={processing} onClick={onSave} />
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const menu = [
  { name: '1 วัน', value: 0.01 },
  { name: '1 สัปดาห์', value: 0.25 },
  { name: '2 สัปดาห์', value: 0.5 },
  { name: '1 เดือน', value: 1 },
  { name: '2 เดือน', value: 2 },
  { name: '3 เดือน', value: 3 },
  { name: '6 เดือน', value: 6 },
]

const payment_menu = [
  { name: '2 ชั่วโมง', value: 2 },
  { name: '3 ชั่วโมง', value: 3 },
  { name: '6 ชั่วโมง', value: 6 },
  { name: '12 ชั่วโมง', value: 12 },
  { name: '1 วัน', value: 24 },
]

const round_menu = [
  { name: '1 รอบ', value: 1 },
  { name: '2 รอบ', value: 2 },
  { name: '3 รอบ', value: 3 },
  { name: '4 รอบ', value: 4 },
]

const week_round_menu = [
  { name: '1 สัปดาห์', value: 1 },
  { name: '2 สัปดาห์', value: 2 },
  { name: '3 สัปดาห์', value: 3 },
  { name: '4 สัปดาห์', value: 4 },
]

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
  color: ${(props) => props.theme.color_level.grey.high};
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 18px;
`

const Status = styled.div`
  width: 55px;
  float: right;
`

const Line = styled.div`
  margin-bottom: 16px;
`

export default inject('market', 'marketSetting')(observer(SaleSetting))
