import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { format, market } from 'utils'

import BookingType from './BookingType'

const Product = (props) => {
  const { product = {}, isLast = false } = props

  const time = market.toOpenTimeItem(product)
  const productType = market.toProductType(product, { ch: ' ' })
  const bookingType = market.getBookingType(product)

  const dot = isLast ? undefined : <Dot />
  return (
    <PageView>
      <Row align="middle">
        <Col span={16}>
          <Time>{time}</Time>
          <Info>{productType}</Info>
        </Col>
        <Col span={8}>
          <Price>{format.toDigi(product.price)}</Price>
          <BookingUnit>{bookingType}</BookingUnit>
        </Col>
      </Row>

      <BookingType booking_type={product.booking_type} />
      {dot}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Time = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.color_level.grey.high};
  line-height: 1.2;
`

const Info = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  line-height: 1.2;
`

const Price = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  text-align: right;
  line-height: 1.2;
`

const BookingUnit = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color.red};
  text-align: right;
  line-height: 1.2;
`

const Dot = styled.div`
  width: 100%;
  border: 0px dashed ${(p) => p.theme.color_level.grey.low};
  border-bottom-width: 1px;
  margin: 16px 0px;
`

export default Product
