/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(88 * size)}`
  const height = `${Math.ceil(88 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1705_7831)">
        <path
          d="M56.2961 18.8702L37.9622 0.536267C37.2472 -0.178756 36.0847 -0.178756 35.3697 0.536267C34.6547 1.25129 34.6547 2.41372 35.3697 3.12874L53.7036 21.4627C54.063 21.8183 54.5322 21.9981 55.0016 21.9981C55.471 21.9981 55.9403 21.8185 56.2961 21.4627C57.0111 20.7475 57.0111 19.5853 56.2961 18.8702Z"
          fill="white"
        />
        <path
          d="M69.7262 47.6683L80.7265 47.6683C81.7386 47.6683 82.56 46.8469 82.56 45.8349L82.56 34.8345C82.56 33.8225 81.7386 33.0011 80.7265 33.0011C79.7145 33.0011 78.8931 33.8225 78.8931 34.8345L78.8931 44.0014L69.7262 44.0014C68.7142 44.0014 67.8927 44.8229 67.8927 45.8349C67.8927 46.8469 68.7142 47.6683 69.7262 47.6683Z"
          fill="white"
        />
        <path
          d="M36.7252 14.6672C37.7372 14.6672 38.5586 13.8458 38.5586 12.8338L38.5586 3.6669L47.7255 3.6669C48.7375 3.66689 49.559 2.84548 49.559 1.83345C49.559 0.821416 48.7375 -1.12167e-08 47.7255 7.72579e-08L36.7252 1.03894e-06C35.7132 1.12741e-06 34.8917 0.821417 34.8917 1.83345L34.8917 12.8338C34.8917 13.846 35.7132 14.6672 36.7252 14.6672Z"
          fill="white"
        />
        <path
          d="M81.9636 44.5376L63.6296 26.2036C62.9146 25.4886 61.7521 25.4886 61.0371 26.2036C60.3221 26.9186 60.3221 28.0811 61.0371 28.7961L79.3711 47.1301C79.7305 47.4857 80.1997 47.6655 80.6691 47.6655C81.1386 47.6655 81.6078 47.4859 81.9636 47.1301C82.6786 46.4151 82.6786 45.2526 81.9636 44.5376Z"
          fill="white"
        />
        <path
          d="M52.809 53.2975C50.1689 49.7738 45.2262 48.9268 41.5557 51.3688L38.5012 53.4039V31.1648C38.5012 28.1324 36.0335 25.6647 33.001 25.6647C29.9685 25.6647 27.5008 28.1324 27.5008 31.1648V36.9766C26.9288 36.775 26.3092 36.665 25.6674 36.665C23.1373 36.665 20.9959 38.3847 20.3616 40.7167C19.731 40.4711 19.049 40.3317 18.3339 40.3317C15.8039 40.3317 13.6624 42.0514 13.0282 44.3835C12.3975 44.1378 11.7155 43.9984 11.0005 43.9984C7.96802 43.9984 5.50034 46.4661 5.50034 49.4986V64.1657C5.50034 73.2629 12.9036 80.6662 22.0008 80.6662H28.161C31.7508 80.6662 35.1976 79.1481 37.6249 76.508L52.4644 60.3155C54.2501 58.3614 54.3967 55.4133 52.809 53.2975ZM49.7581 57.8334L34.9187 74.0259C33.188 75.918 30.7239 76.9996 28.1609 76.9996H22.0007C14.9238 76.9996 9.16689 71.2428 9.16689 64.1658V49.4988C9.16689 48.4868 9.9883 47.6653 11.0003 47.6653C12.0124 47.6653 12.8338 48.4868 12.8338 49.4988V51.3322C12.8338 52.3443 13.6552 53.1657 14.6672 53.1657C15.6793 53.1657 16.5005 52.3443 16.5005 51.3322V45.8321C16.5005 44.82 17.3219 43.9986 18.3339 43.9986C19.346 43.9986 20.1674 44.82 20.1674 45.8321V51.3322C20.1674 52.3443 20.9888 53.1657 22.0008 53.1657C23.0129 53.1657 23.8343 52.3443 23.8343 51.3322V42.1653C23.8343 41.1533 24.6557 40.3319 25.6677 40.3319C26.6798 40.3319 27.5008 41.1531 27.5008 42.1652V51.3321C27.5008 52.3441 28.3223 53.1655 29.3343 53.1655C30.3463 53.1655 31.1677 52.3441 31.1677 51.3321V31.1648C31.1677 30.1528 31.9892 29.3314 33.0012 29.3314C34.0132 29.3314 34.8346 30.1528 34.8346 31.1648V56.8322C34.8346 57.5106 35.2086 58.1303 35.8063 58.4457C36.3967 58.7647 37.1227 58.7281 37.6873 58.354L43.5909 54.4159C45.6406 53.0555 48.4017 53.5249 49.8757 55.494C50.4035 56.198 50.3559 57.1844 49.7581 57.8334Z"
          fill="white"
        />
        <path
          d="M36.6678 76.9996H11.0004C9.98832 76.9996 9.1669 77.821 9.1669 78.8331V86.1667C9.1669 87.1787 9.98832 88.0002 11.0004 88.0002C12.0124 88.0002 12.8338 87.1787 12.8338 86.1667V80.6665H34.8345V86.1667C34.8345 87.1787 35.6559 88.0002 36.668 88.0002C37.68 88.0002 38.5012 87.1786 38.5012 86.1665V78.8329C38.5012 77.821 37.6798 76.9996 36.6678 76.9996Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1705_7831">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
/* eslint-enable */
export default Logo
