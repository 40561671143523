import styled from 'styled-components'

const Title = (props) => {
  const { text = '' } = props
  return <PageView>{text}</PageView>
}

const PageView = styled.div`
  width: 100%;
  padding: 10px 0px;
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 18px;
`

export default Title
