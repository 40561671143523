/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#DCDCDC' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.3828 0 0 5.3828 0 12C0 18.6172 5.3828 24 12 24C18.6172 24 24 18.6163 24 12C24 5.38373 18.6172 0 12 0ZM12 22.141C6.40898 22.141 1.85902 17.592 1.85902 12C1.85902 6.40805 6.40898 1.85902 12 1.85902C17.591 1.85902 22.141 6.40805 22.141 12C22.141 17.592 17.592 22.141 12 22.141Z"
        fill={color}
      />
      <path
        d="M16.6476 10.9869H12.9295V7.26883C12.9295 6.75573 12.514 6.33929 12 6.33929C11.486 6.33929 11.0705 6.75573 11.0705 7.26883V10.9869H7.35244C6.83841 10.9869 6.42291 11.4033 6.42291 11.9164C6.42291 12.4295 6.83841 12.8459 7.35244 12.8459H11.0705V16.564C11.0705 17.077 11.486 17.4935 12 17.4935C12.514 17.4935 12.9295 17.077 12.9295 16.564V12.8459H16.6476C17.1616 12.8459 17.5771 12.4295 17.5771 11.9164C17.5771 11.4033 17.1616 10.9869 16.6476 10.9869Z"
        fill={color}
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
