import styled from 'styled-components'
import { Row } from 'antd'

import { PrimaryBtn, Btn } from 'components/button'
import { Col } from 'components/display'
import { timer } from 'utils'

const Button = (props) => {
  const { status, rejecting, approving, payment_at, onReject, onApprove } =
    props

  if (status === 'create') {
    return (
      <PageView>
        <BtnArea>
          <Row>
            <Col span={12} position="left">
              <Btn loading={rejecting} text="ไม่อนุมัติ" onClick={onReject} />
            </Col>

            <Col span={12} position="right">
              <PrimaryBtn
                loading={approving}
                text="อนุมัติ"
                onClick={onApprove}
              />
            </Col>
          </Row>
        </BtnArea>
      </PageView>
    )
  } else if (status === 'completed') {
    const last_date = timer.get(payment_at).add(3, 'day')
    const today = timer.get()
    if (last_date > today) {
      return (
        <PageView>
          <OneBtnArea>
            <Btn
              loading={rejecting}
              text="ยกเลิกรายการจอง"
              onClick={onReject}
            />
          </OneBtnArea>
        </PageView>
      )
    }
  }

  return <div />
}

const PageView = styled.div`
  width: 100%;
  padding: 16px 0px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10;
`

const BtnArea = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  ${(p) => p.theme.media.mobile} {
    max-width: 90%;
  }
`

const OneBtnArea = styled.div`
  width: 100%;
  max-width: 314px;
  margin: 0 auto;

  ${(p) => p.theme.media.mobile} {
    max-width: 90%;
  }
`

export default Button
