import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  name: validator.getRuleString('ชื่อตลาด'),
  address: validator.getRuleString('ที่อยู่'),
  province: validator.getRuleString('จังหวัด'),
  district: validator.getRuleString('เขต/อำเภอ'),
}).options({ allowUnknown: true })

export default role
