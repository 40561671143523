import styled from 'styled-components'

import { Input, InputArea, Checker } from 'components/input'
import { RangeDatePicker } from 'components/date'
import { timer } from 'utils'

const dateMin = timer.get().subtract(1, 'year').toDate()
const dateMax = timer.get().add(1, 'year').toDate()

const Information = (props) => {
  const { doc = {}, validate = {}, onChange, onValidate } = props

  const { name, value, start_at, finish_at, is_all } = doc

  const toDate = (val) => {
    return val ? val.startOf('day') : val
  }

  const onDate = (val) => {
    doc.start_at = toDate(val.start_at)
    doc.finish_at = toDate(val.finish_at)

    onChange(doc)

    delete validate.start_at
    onValidate(validate)
  }

  const onCh = (val, name) => {
    doc[name] = val

    onChange(doc)

    delete validate[name]
    onValidate(validate)
  }

  const onCheck = (val) => {
    doc.is_all = val
    onChange(doc)
  }

  const isReadonly = doc.status !== 'active'
  return (
    <PageView>
      <Line>
        <InputArea
          label="รายละเอียดโปรโมชั่น"
          name="name"
          value={name}
          rows={3}
          disabled={isReadonly}
          onChange={onCh}
          invalid={validate.name}
        />
      </Line>

      <Line>
        <RangeDatePicker
          placeholder="วันที่จัดโปรโมชั่น"
          start_at={start_at}
          finish_at={finish_at}
          onChange={onDate}
          invalid={validate.start_at}
          disabled={isReadonly}
          min={dateMin}
          max={dateMax}
        />
      </Line>

      <Line>
        <Input
          label={'ใส่จำนวนส่วนลด (บาท)'}
          name="value"
          value={value}
          onChange={onCh}
          isNumber={true}
          disabled={isReadonly}
          invalid={validate.value}
        />
      </Line>

      <Checker
        label="ล็อคทั้งหมด"
        name="is_all"
        value={is_all}
        onChange={onCheck}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default Information
