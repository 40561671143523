import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Tab } from 'components/display'

const MarketMenu = (props) => {
  const { id, selected } = props
  const navigate = useNavigate()

  const onChange = (val) => {
    let link
    switch (val) {
      case 'other':
        link = `/market/${id}/editor-other`
        break
      case 'banking':
        link = `/market/${id}/editor-banking`
        break
      default:
        link = `/market/${id}/editor-information`
    }
    navigate(link)
  }

  return (
    <Menu>
      <Tab menu={menu_list} selected={selected} onChange={onChange} />
    </Menu>
  )
}

const menu_list = [
  { label: 'ข้อมูลทั่วไป', value: 'information' },
  { label: 'ข้อมูลอื่นๆ', value: 'other' },
  { label: 'ข้อมูลการเงิน', value: 'banking' },
]

const Menu = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 10px 0px 20px 0px;
`

export default MarketMenu
