/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#305FED' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6H22" stroke="#252525" strokeWidth="1.8" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M4 11.5H20" stroke="#252525" strokeWidth="1.8" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M6 17H18" stroke="#252525" strokeWidth="1.8" strokeMiterlimit="10" strokeLinejoin="round"/>
    </svg>
  )
}
/* eslint-enable */
export default Logo
