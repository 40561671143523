import styled from 'styled-components'

const Pin = (props) => {
  const {
    text,
    size = 20,
    style = {},
    onRef,
    onMouseDown,
    onTouchStart,
    onTouchEnd,
  } = props
  const s = `${size}px`
  style.width = s
  style.height = s

  return (
    <PinView
      ref={onRef}
      style={style}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {text}
    </PinView>
  )
}

const PinView = styled.span`
  position: absolute;
  color: white;
  background-color: ${(p) => p.theme.color.blue};
  border-radius: 50%;
  opacity: 0.7;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  z-index: 15;
`

export default Pin
