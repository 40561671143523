import { useState, useEffect, useCallback } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import MainLayout from 'menu'
import Loading from 'pages/loading'
import ContactUs from 'pages/contact'
import AboutUs from 'pages/about'
import Home from 'pages/home'
import Register from 'pages/register'
import Welcome from 'pages/welcome'
import Dashboard from 'pages/dashboard'
import NewMarket from 'pages/newMarket'
import Market from 'pages/market'
import MarketList from 'pages/marketList'
import MarketInfo from 'pages/marketInfo'
import MarketInfoEditor from 'pages/marketEditor/information'
import MarketOtherEditor from 'pages/marketEditor/other'
import MarketBankEditor from 'pages/marketEditor/banking'
import Announce from 'pages/announce'
import SaleSetting from 'pages/setting'
import FloorPlan from 'pages/floorplan'
import Product from 'pages/product'
import Unit from 'pages/unit'
import ProductStatusList from 'pages/unitList'
import LineLoginChecker from 'pages/checker/line'
import AccountMenu from 'pages/account/profile'
import AccountInfo from 'pages/account/information'
import ConsentPage from 'pages/account/consent'
import Booking from 'pages/booking'
import BookingList from 'pages/bookingList'
import AllBookingList from 'pages/allBookingList'
import BookingOffer from 'pages/bookingOffer'
import PromotionList from 'pages/promotionList'
import Promotion from 'pages/promotion'
import MargetPrice from 'pages/price'

const App = (props) => {
  const [loading, setLoading] = useState(false)
  const { user } = props.member.toJS()

  const isLogin = !!user.user_id
  const name = user.name || ''
  const isRegisterMode = name === ''

  const onCheck = useCallback(async () => {
    if (isLogin) return
    try {
      setLoading(true)
      await props.member.checkLoginUser()
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member, isLogin])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <MainLayout>
        <Routes>
          <Route path="/*" element={<Loading />} />
        </Routes>
      </MainLayout>
    )
  } else if (isLogin === false) {
    return (
      <MainLayout>
        <Routes>
          <Route path="/line-login" element={<LineLoginChecker />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/price" element={<MargetPrice />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </MainLayout>
    )
  } else if (isRegisterMode) {
    return (
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/price" element={<MargetPrice />} />
          <Route path="/*" element={<Register />} />
        </Routes>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/price" element={<MargetPrice />} />

        <Route path="/line-login" element={<LineLoginChecker />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/new-market" element={<NewMarket />} />

        <Route path="/booking" element={<AllBookingList />} />
        <Route path="/booking/:code" element={<Booking />} />
        <Route path="/booking/:market_id/:code/offer" element={<Booking />} />

        <Route path="/market" element={<MarketList />} />
        <Route path="/market/:market_id" element={<Market />} />
        <Route path="/market/:market_id/floor-plan" element={<FloorPlan />} />
        <Route
          path="/market/:market_id/floor-plan/:floorplan_id"
          element={<FloorPlan />}
        />
        <Route
          path="/market/:market_id/unit/:floorplan_id"
          element={<Unit />}
        />
        <Route
          path="/market/:market_id/unit/:floorplan_id/:unit_id"
          element={<Unit />}
        />
        <Route path="/market/:market_id/booking" element={<BookingList />} />
        <Route path="/market/:market_id/booking/:code" element={<Booking />} />
        <Route
          path="/market/:market_id/booking/:code/offer"
          element={<BookingOffer />}
        />
        <Route
          path="/market/:market_id/promotion"
          element={<PromotionList />}
        />
        <Route
          path="/market/:market_id/promotion/:promotion_id/edit"
          element={<Promotion />}
        />
        <Route
          path="/market/:market_id/promotion/create"
          element={<Promotion />}
        />
        <Route path="/market/:market_id/information" element={<MarketInfo />} />
        <Route path="/market/:market_id/announce" element={<Announce />} />
        <Route path="/market/:market_id/setting" element={<SaleSetting />} />
        <Route path="/market/:market_id/product" element={<Product />} />
        <Route path="/market/:market_id/unit" element={<ProductStatusList />} />

        <Route
          path="/market/:market_id/editor-information"
          element={<MarketInfoEditor />}
        />
        <Route
          path="/market/:market_id/editor-other"
          element={<MarketOtherEditor />}
        />
        <Route
          path="/market/:market_id/editor-banking"
          element={<MarketBankEditor />}
        />

        <Route path="/account" element={<AccountMenu />} />
        <Route path="/account/information" element={<AccountInfo />} />
        <Route path="/account/consent/:typ" element={<ConsentPage />} />
      </Routes>
    </MainLayout>
  )
}

export default inject('member')(observer(App))
