import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { ConfirmDialog } from 'dialog'
import { BaseDrawer } from 'drawer'
import { validator } from 'utils'

import OpenTime from './OpenTime'
import BookingType from './BookingType'
import ProductType from './ProductType'
import Deposit from './Deposit'
import BanProduct from './BanProduct'
import SaveBtn from './SaveBtn'
import { getRule } from './rules'

const ProductInfo = (props) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [validate, setValidate] = useState({})
  const {
    visible,
    loading,
    doc = {},
    onChange,
    onSave,
    onDelete,
    onClose,
  } = props

  useEffect(() => {
    if (visible) {
      setValidate({})
    }
  }, [visible])

  const { product_id } = doc

  const onValidate = () => {
    const condition = {}

    switch (doc.booking_type) {
      case 'monthly':
        condition.price_monthly = true
        break
      case 'round':
        condition.price_round = true
        break
      default:
        condition.price_daily =
          doc.daily_type === 'specific' ? 'specific' : 'none'
    }

    if (doc.deposit_type === 'yes') {
      condition.deposit = true
    }

    const isProductType =
      doc.type_food ||
      doc.type_drink ||
      doc.type_clothes ||
      doc.type_things ||
      doc.type_services
    if (!isProductType) {
      condition.product_type = true
    }

    const rules = getRule(condition)
    const checker = validator.process(rules, doc)
    if (checker.invalid) {
      setValidate(checker.errors)
      validator.focus(checker.errors)
      return
    }

    onSave()
  }

  const onDeleteConfirm = () => setDeleteConfirm(true)
  const onCloseDeleteConfirm = () => setDeleteConfirm(false)
  const onDeleteProduct = () => {
    onCloseDeleteConfirm()
    onDelete(doc)
  }

  const onChangeVal = (val) => {
    setValidate({})
    onChange(val)
  }

  return (
    <BaseDrawer title="รอบเช่า" visible={visible} onClose={onClose}>
      <BodyContent>
        <OpenTime doc={doc} onChange={onChangeVal} validate={validate} />
        <BookingType doc={doc} onChange={onChangeVal} validate={validate} />
        <ProductType doc={doc} onChange={onChange} validate={validate} />
        <Deposit doc={doc} onChange={onChange} validate={validate} />
        <BanProduct doc={doc} onChange={onChange} />

        <SaveBtn
          loading={loading}
          onClick={onValidate}
          onDelete={product_id ? onDeleteConfirm : undefined}
        />

        <ConfirmDialog
          visible={deleteConfirm}
          title="ยืนยันการลบรอบเช่า"
          message="คุณต้องการที่จะลบรอบเช่า ใช่หรือไม่?"
          onClose={onCloseDeleteConfirm}
          onConfirm={onDeleteProduct}
        />
      </BodyContent>
    </BaseDrawer>
  )
}

const BodyContent = styled.div`
  width: 100%;
  padding-bottom: 60px;
`

export default ProductInfo
