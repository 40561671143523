/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#494949' } = props
  const width = `${Math.ceil(22 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.62669 24H20.3734C22.1289 24 23.5556 22.5733 23.5556 20.8178V4.95998C23.5556 3.20442 22.1289 1.77774 20.3734 1.77774H19.1112V0.888872C19.1112 0.399978 18.7111 0 18.2222 0C17.7333 0 17.3333 0.399978 17.3333 0.888872V1.77774H6.66672V0.888872C6.66672 0.399978 6.26666 0 5.77777 0C5.28887 0 4.8889 0.399978 4.8889 0.888872V1.77774H3.62669C1.87114 1.77774 0.444458 3.20442 0.444458 4.95998V20.8178C0.444458 22.5733 1.87114 24 3.62669 24V24ZM2.22228 4.95998C2.22228 4.18667 2.85338 3.55556 3.62669 3.55556H4.8889V4.44444C4.8889 4.93333 5.28887 5.33331 5.77777 5.33331C6.26666 5.33331 6.66664 4.93333 6.66664 4.44444V3.55556H17.3333V4.44444C17.3333 4.93333 17.7333 5.33331 18.2222 5.33331C18.7111 5.33331 19.1111 4.93333 19.1111 4.44444V3.55556H20.3733C21.1466 3.55556 21.7777 4.18667 21.7777 4.95998V20.8178C21.7777 21.5911 21.1466 22.2222 20.3733 22.2222H3.62669C2.85338 22.2222 2.22228 21.5911 2.22228 20.8178V4.95998Z"
        fill={color}
      />
      <path
        d="M6.88892 10.2222C7.50256 10.2222 8.00001 9.72474 8.00001 9.1111C8.00001 8.49745 7.50256 8 6.88892 8C6.27528 8 5.77783 8.49745 5.77783 9.1111C5.77783 9.72474 6.27528 10.2222 6.88892 10.2222Z"
        fill={color}
      />
      <path
        d="M11.7778 10.2222C12.3915 10.2222 12.8889 9.72474 12.8889 9.1111C12.8889 8.49745 12.3915 8 11.7778 8C11.1642 8 10.6667 8.49745 10.6667 9.1111C10.6667 9.72474 11.1642 10.2222 11.7778 10.2222Z"
        fill={color}
      />
      <path
        d="M16.6667 10.2222C17.2803 10.2222 17.7778 9.72474 17.7778 9.1111C17.7778 8.49745 17.2803 8 16.6667 8C16.0531 8 15.5556 8.49745 15.5556 9.1111C15.5556 9.72474 16.0531 10.2222 16.6667 10.2222Z"
        fill={color}
      />
      <path
        d="M6.88892 14.6667C7.50256 14.6667 8.00001 14.1692 8.00001 13.5556C8.00001 12.9419 7.50256 12.4445 6.88892 12.4445C6.27528 12.4445 5.77783 12.9419 5.77783 13.5556C5.77783 14.1692 6.27528 14.6667 6.88892 14.6667Z"
        fill={color}
      />
      <path
        d="M11.7778 14.6667C12.3915 14.6667 12.8889 14.1692 12.8889 13.5556C12.8889 12.9419 12.3915 12.4445 11.7778 12.4445C11.1642 12.4445 10.6667 12.9419 10.6667 13.5556C10.6667 14.1692 11.1642 14.6667 11.7778 14.6667Z"
        fill={color}
      />
      <path
        d="M16.6667 14.6667C17.2803 14.6667 17.7778 14.1692 17.7778 13.5556C17.7778 12.9419 17.2803 12.4445 16.6667 12.4445C16.0531 12.4445 15.5556 12.9419 15.5556 13.5556C15.5556 14.1692 16.0531 14.6667 16.6667 14.6667Z"
        fill={color}
      />
      <path
        d="M6.88892 19.1111C7.50256 19.1111 8.00001 18.6136 8.00001 18C8.00001 17.3864 7.50256 16.8889 6.88892 16.8889C6.27528 16.8889 5.77783 17.3864 5.77783 18C5.77783 18.6136 6.27528 19.1111 6.88892 19.1111Z"
        fill={color}
      />
      <path
        d="M11.7778 19.1111C12.3915 19.1111 12.8889 18.6136 12.8889 18C12.8889 17.3864 12.3915 16.8889 11.7778 16.8889C11.1642 16.8889 10.6667 17.3864 10.6667 18C10.6667 18.6136 11.1642 19.1111 11.7778 19.1111Z"
        fill={color}
      />
      <path
        d="M16.6667 19.1111C17.2803 19.1111 17.7778 18.6136 17.7778 18C17.7778 17.3864 17.2803 16.8889 16.6667 16.8889C16.0531 16.8889 15.5556 17.3864 15.5556 18C15.5556 18.6136 16.0531 19.1111 16.6667 19.1111Z"
        fill={color}
      />
    </svg>
  )
}

/* eslint-enable */
export default Logo
