import React from 'react'
import styled from 'styled-components'

const Label = (props) => {
  const { text = '' } = props
  return <PageView>{text}</PageView>
}

const PageView = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 18px;
  margin-bottom: 8px;
  width: 100%;
`

export default Label
