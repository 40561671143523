import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
  status: 'active',
  promotion_type: 'money',
  value: 0,
  product_list: [],
}

let state
export class Promotion extends BaseStore {
  constructor() {
    super()
    this.observable({
      promotion_list: [],
      doc: cloneDeep(original),
      unit: {
        market_id: undefined,
        unit_list: [],
      },
    })
    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  setPromotion(data) {
    runInAction(() => {
      state.doc = data
    })
  }

  async getPromotionList(query = {}) {
    const { market_id, status } = query

    const url = `${config.api}/v1/user/market/promotion/${market_id}/market?status=${status}`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.promotion_list = list
    })
  }

  async getPromotion(query = {}) {
    const { promotion_id } = query
    this.reset()

    const url = `${config.api}/v1/user/market/promotion/${promotion_id}`
    const res = await http.get(url)

    const data = res.body || {}

    runInAction(() => {
      state.doc = data
    })
  }

  async getUnitList(query = {}) {
    const { market_id } = query

    const unit = this.toJS().unit
    if (unit.market_id === market_id) {
      return
    }

    const url = `${config.api}/v1/user/market/product/${market_id}`
    const res = await http.get(url)

    const unit_list = res.body || []

    runInAction(() => {
      state.unit = {
        market_id,
        unit_list,
      }
    })
  }

  async updatePromotion(json = {}) {
    const { market_id, promotion_id } = json

    if (promotion_id) {
      const url = `${config.api}/v1/user/market/promotion/${promotion_id}`
      await http.put(url, { json })
    } else {
      const url = `${config.api}/v1/user/market/promotion/${market_id}`
      await http.post(url, { json })
    }
  }

  async deletePromotion(query = {}) {
    const { promotion_id } = query

    const url = `${config.api}/v1/user/market/promotion/${promotion_id}/delete`
    await http.put(url)
  }
}

export default new Promotion()
