import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content } from 'components/display'
import { timer } from 'utils'
import Loading from 'components/loading'

import Filter from './Filter'
import Header from './Header'
import Product from './Product'

const getMinDate = () => {
  return timer.getDate().subtract(1, 'months').toDate()
}

const getMaxDate = () => {
  return timer.getDate().add(6, 'months').toDate()
}

const UnitList = (props) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [limit] = useState({
    min: getMinDate(),
    max: getMaxDate(),
  })
  const [date, setDate] = useState(timer.getDate())
  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id } = useParams()

  const { ready_product_list } = props.product.toJS()

  const getProduct = useCallback(
    async (id, d) => {
      try {
        setLoading(true)
        await props.product.getBookingProductList({ market_id: id, date: d })
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.product]
  )

  useEffect(() => {
    getProduct(market_id, timer.getDate())
  }, [getProduct, market_id])

  const onBack = () => navigate(`/market/${market_id}`)
  const onCloseError = () => setErr('')
  const onFilter = (val) => setFilter(val)
  const onDate = (val) => {
    setDate(val)
    getProduct(market_id, val)
  }

  const getFilterList = () => {
    if (filter === '') {
      return ready_product_list
    }

    const text = filter.toLowerCase()
    return ready_product_list.filter((it) => {
      const name = (it.name || '').toLowerCase()
      return name.includes(text)
    })
  }

  const list = getFilterList()
  let last = undefined
  const content = list.map((it, i) => {
    const item = <Product key={i} last={last} doc={it} />
    last = it
    return item
  })

  return (
    <PageView>
      <Meta title="Market - สถานะล็อก" description="สถานะล็อก" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Filter
          limit={limit}
          date={date}
          filter={filter}
          onDate={onDate}
          onFilter={onFilter}
        />
        <Header />
        <Loading loading={loading}>{content}</Loading>

        <ErrorDialog error={err} onClose={onCloseError} />
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

export default inject('product')(observer(UnitList))
