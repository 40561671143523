import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Input } from 'components/input'
import { PrimaryBtn } from 'components/button'

import BaseDrawer from './BaseDrawer'

const CloneUnitDrawer = (props) => {
  const [name, setName] = useState('')
  const { loading, visible, doc = {}, onClose, onConfirm } = props

  useEffect(() => {
    if (visible) {
      setName('')
    }
  }, [visible])

  const onName = (val) => setName(val)
  const onClone = () => onConfirm({ name })

  return (
    <BaseDrawer
      title="คัดลอกข้อมูลล็อกใหม่"
      height={'320px'}
      visible={visible}
      onClose={onClose}
    >
      <Body>
        <Input label="หมายเลขล็อก" value={name} limit={7} onChange={onName} />
        <Space />
        <Text>คัดลอกข้อมูลล็อก ({doc.name || ''})</Text>
        <Text>กรุณาแก้ไขตำแหน่งล็อกใหม่ได้ที่หน้าถัดไป</Text>

        <ButtonSection>
          <Button>
            <PrimaryBtn
              loading={loading}
              text="ตกลง"
              disabled={name === ''}
              onClick={onClone}
            />
          </Button>
        </ButtonSection>
      </Body>
    </BaseDrawer>
  )
}

const Body = styled.div`
  padding: 16px 0px;
`

const Space = styled.div`
  margin-top: 16px;
`

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.medium};
`

const ButtonSection = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: white;
  padding: 20px 0px;
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
`

export default CloneUnitDrawer
