import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Next } from 'icon'

const Card = (props) => {
  const { title = '', detail = '' } = props
  return (
    <PageView>
      <Row align="middle">
        <Col span={20}>
          <Title>{title}</Title>
          <Detail>{detail}</Detail>
        </Col>
        <Col span={4}>
          <Icon>
            <Next />
          </Icon>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  background-color: ${(props) => props.theme.color_level.grey.low};
  width: 100%;
  padding: 22px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 18px;
  font-weight: bold;
  width: 100%;
`

const Detail = styled.div`
  color: ${(props) => props.theme.color_level.grey.medium};
  font-size: 14px;
  width: 100%;
`

const Icon = styled.div`
  display: flex;
  justify-content: end;
`

export default Card
