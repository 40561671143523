import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'
import { BaseDialog } from 'dialog'
import { Close } from 'icon'

import Zoom from './Zoom'
import Click from './Click'
import Arrow from './Arrow'
import Pin from './Pin'

const PinHelpDialog = (props) => {
  const [page, setPage] = useState(0)
  const { visible, onClose } = props

  useEffect(() => {
    if (visible) setPage(0)
  }, [visible])

  const getFirstPage = () => {
    return (
      <InfoContent>
        <Zoom />
        <Title>ถ่างนิ้ว</Title>
        <Text>เพื่อขยายแผนผังตลาด</Text>

        <Button>
          <PrimaryBtn text="ถัดไป" onClick={() => setPage(1)} />
        </Button>
      </InfoContent>
    )
  }

  const getNextPage = () => {
    return (
      <InfoContent>
        <IconLine>
          <Click />
          <Arrow />
          <Pin />
        </IconLine>
        <Title>ปักหมุด</Title>
        <Text>ไปยังตำแหน่งล็อก ที่ต้องการ</Text>
        <Text>และกดยืนยัน</Text>

        <Button>
          <PrimaryBtn text="เข้าใจแล้ว" onClick={onClose} />
        </Button>
      </InfoContent>
    )
  }

  const content = page === 0 ? getFirstPage() : getNextPage()
  return (
    <BaseDialog isHeader={false} visible={visible} onClose={onClose}>
      <Content>
        <Header>
          กดเลือกล็อกที่ต้องการปักหมุด
          <CloseBtn onClick={onClose}>
            <Close />
          </CloseBtn>
        </Header>
        <Body>{content}</Body>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 330px;

  ${(p) => p.theme.media.mobile} {
    width: 75vw;
  }
`

const Body = styled.div`
  width: 100%;
  height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
  color: white;
  background-color: ${(p) => p.theme.color_level.grey.high};
`

const Header = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 14px;
  margin-bottom: 20px;
  height: 25px;
  width: 100%;
  position: relative;
  text-align: center;
`

const Title = styled.div`
  font-size: 24px;
  width: 100%;
  padding-top: 10px;
`

const Text = styled.div`
  font-size: 18px;
  width: 100%;
  line-height: 1.2;
`

const IconLine = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100%;
`

const InfoContent = styled.div``

const CloseBtn = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`

const Button = styled.div`
  width: 165px;
  margin: 0 auto;
  padding-top: 20px;
`

export default PinHelpDialog
