import { useState, useEffect, useCallback, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { BackHeader, Content } from 'components/display'
import Loading from 'components/loading'

const CheckLine = (props) => {
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const ref = useRef(true)

  const navigate = useNavigate()

  const onCheck = useCallback(async () => {
    try {
      setErr(undefined)
      setLoading(true)

      const { user = {}, setting = {} } = await props.member.checkLineProfile()
      console.log('user line:', user)
      console.log('user setting:', setting)
      if (ref.current) {
        setLoading(false)
        const userId = +user.user_id || -1
        const name = user.name || ''
        const isRegister = userId !== -1 && name === ''
        if (isRegister) {
          navigate('/register')
        } else {
          const redirect = setting.redirect || '/dashboard'
          navigate(redirect)
        }
      }
    } catch (e) {
      const msg = e.message
      setErr(msg)
      setLoading(false)
    }
  }, [props.member])

  useEffect(() => {
    onCheck()
    return () => {
      ref.current = false
    }
  }, [onCheck])

  const onBack = () => navigate('/')
  const getLoading = () => {
    return (
      <Text>
        <h3>กำลังประมวลผล กรุณารอสักครู่</h3>
      </Text>
    )
  }

  const getContent = () => {
    const val = err ? (
      <Text>
        <h3>เกิดข้อผิดพลาด</h3>
        <ErrText>{err}</ErrText>
      </Text>
    ) : (
      <Text>
        <h3>ตรวจสอบเรียบร้อย</h3>
      </Text>
    )

    return val
  }

  const content = loading ? getLoading() : getContent()

  return (
    <PageView>
      <BackHeader onClick={onBack} />
      <Content isHeader={true}>
        <Body>
          {content}
          <Loading size={0.8} loading={loading} />
        </Body>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Body = styled.div`
  height: 50vh;
  padding: 20px;
`

const Text = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const ErrText = styled.div`
  color: #ff3a33;
  font-size: 16px;
  margin-top: 10px;
  color: ${(p) => p.theme.color.error};
`

export default inject('member')(observer(CheckLine))
