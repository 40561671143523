import styled from 'styled-components'

import { product } from 'utils'

import Title from './Title'

const Header = (props) => {
  const { order = {} } = props
  const { code = '' } = order

  const { text, note, comment, color } = product.getStatus(order)
  const content = comment === '' ? undefined : <Note>({comment})</Note>
  return (
    <PageView>
      <Title text="หมายเลขการจอง" />
      <Code style={{ backgroundColor: color }}>{code.toUpperCase()}</Code>
      <Status style={{ color }}>{text}</Status>
      <Note>{note}</Note>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
`

const Code = styled.span`
  font-size: 24px;
  color: white;
  background-color: #f55759;
  border-radius: 18px;
  padding: 2px 25px;
`

const Status = styled.div`
  width: 100%;
  font-size: 18px;
  color: #f55759;
  margin-top: 8px;
`

const Note = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 16px;
`

export default Header
