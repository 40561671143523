import styled from 'styled-components'

import ViewFloorplan from 'components/floorplan/ViewFloorplan'

const FloorImage = (props) => {
  const { visible, img_url, doc = {} } = props

  const pin_list = [
    {
      x: doc.axis_x,
      y: doc.axis_y,
    },
  ]
  return (
    <PageView>
      <ViewFloorplan
        visible={visible}
        img_url={img_url}
        height="76px"
        size={12}
        pin_list={pin_list}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 12px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`

export default FloorImage
