import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorDialog } from 'dialog'
import { CloneUnitDrawer } from 'drawer'
import Loading from 'components/loading'
import { FindInput } from 'components/input'

import Unit from './Unit'

const UnitList = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [clone, setClone] = useState(undefined)
  const [err, setErr] = useState('')
  const [filter, setFilter] = useState('')

  const navigate = useNavigate()

  const { market_id, floorplan_id } = props
  const { unit_list = [] } = props.unit.toJS()

  const onCloseError = () => setErr('')
  const onCloseClone = () => setClone(undefined)
  const onClone = (val) => setClone(val)
  const onFilter = (val) => setFilter(val)

  const getUnitList = useCallback(
    async (id) => {
      try {
        setLoading(true)

        await props.unit.getUnitList({ floorplan_id: id })
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.unit]
  )

  useEffect(() => {
    getUnitList(floorplan_id)
  }, [getUnitList, floorplan_id])

  const onCloneUnit = async (json = {}) => {
    try {
      setSaving(true)
      const { unit_id } = clone || {}
      const doc = await props.unit.cloneUnit({ unit_id }, json)
      if (doc) {
        const link = `/market/${market_id}/unit/${floorplan_id}/${doc.unit_id}?action=clone`
        navigate(link)
        return
      }

      onCloseClone()
    } catch (e) {
      setErr(e.message)
    }
    setSaving(false)
  }

  const onDeleteUnit = async ({ unit_id }) => {
    try {
      setLoading(true)

      await props.unit.deleteUnit({ unit_id })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const onUpdateOrderUnit = async ({ index_a, index_b }) => {
    try {
      setLoading(true)

      await props.unit.updateOrderUnit({ index_a, index_b })
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const getFilterList = () => {
    if (filter === '') {
      return unit_list
    }

    const keyword = filter.toLowerCase()
    return unit_list.filter((it) => {
      const name = (it.name || '').toLowerCase()
      return name.includes(keyword)
    })
  }

  const list = getFilterList()
  const last_index = list.length
  const content = list.map((it, i) => {
    return (
      <Unit
        key={i}
        index={i + 1}
        last_index={last_index}
        doc={it}
        onClone={onClone}
        onDelete={onDeleteUnit}
        onUpdateOrder={onUpdateOrderUnit}
      />
    )
  })

  return (
    <PageView>
      <Label>ล็อกให้เช่า</Label>
      <FindInput
        placeholder="ค้นหาหมายเลขล็อก"
        value={filter}
        onChange={onFilter}
      />
      <ContentView>
        <Loading loading={loading}>{content}</Loading>
      </ContentView>
      <ErrorDialog error={err} onClose={onCloseError} />
      <CloneUnitDrawer
        loading={saving}
        visible={clone !== undefined}
        doc={clone}
        onClose={onCloseClone}
        onConfirm={onCloneUnit}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 24px;
`

const Label = styled.div`
  width: 100%;
  font-size: 18px;
  color: ${(p) => p.theme.color.blue};
`

const ContentView = styled.div`
  width: 100%;
`

export default inject('unit')(observer(UnitList))
