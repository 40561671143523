import styled from 'styled-components'

import { Email, Phone, Line, Facebook, Instagram } from 'icon'

const Menu = () => {
  return (
    <PageView>
      <a href="mailto: Bymarget@gmail.com">
        <Icon>
          <Email color="white" />
        </Icon>
      </a>

      <a href="tel:086-990-0387">
        <Icon>
          <Phone />
        </Icon>
      </a>

      <a href="http://line.me/ti/p/@marget" target="_blank" rel="noreferrer">
        <Icon>
          <Line />
        </Icon>
      </a>

      <a
        href="https://www.facebook.com/ByMarget"
        target="_blank"
        rel="noreferrer"
      >
        <Icon>
          <Facebook />
        </Icon>
      </a>

      <a
        href="https://www.instagram.com/bymarget"
        target="_blank"
        rel="noreferrer"
      >
        <Icon>
          <Instagram size={0.8} />
        </Icon>
      </a>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 0px 20px;
  display: flex;
  align-items: left;

  ${(p) => p.theme.media.mobile} {
    margin-top: 25px;
    padding: 0px;
    justify-content: center;
  }
`

const Icon = styled.div`
  width: 56px;
  height: 56px;
  border: 1px solid white;
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) => p.theme.media.mobile} {
    margin-right: 8px;
  }

  ${(p) => p.theme.media.mobile_xs} {
    width: 46px;
    height: 46px;
    margin-right: 8px;
  }
`

export default Menu
