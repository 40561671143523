import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content, Tab, SaveBtn } from 'components/display'
import Loading from 'components/loading'

import Filter from './Filter'
import Promotion from './Promotion'

const PromotionList = (props) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('active')
  const [filter, setFilter] = useState('')

  const [err, setErr] = useState('')

  const navigate = useNavigate()
  const { market_id } = useParams()

  const { promotion_list } = props.promotion.toJS()

  const getList = useCallback(
    async (id, s) => {
      try {
        setLoading(true)
        await props.promotion.getPromotionList({ market_id: id, status: s })
      } catch (e) {
        setErr(e.message)
      }
      setLoading(false)
    },
    [props.promotion]
  )

  useEffect(() => {
    getList(market_id, status)
  }, [getList, market_id, status])

  const onCreate = () => navigate(`/market/${market_id}/promotion/create`)
  const onBack = () => navigate(`/market/${market_id}`)
  const onCloseError = () => setErr('')
  const onFilter = (val) => setFilter(val)

  const onStatus = (val) => setStatus(val)
  const getFilterList = () => {
    if (filter === '') {
      return promotion_list
    }

    const keyword = filter.toLowerCase()
    return promotion_list.filter((it) => {
      const name = (it.name || '').toLowerCase()
      return name.includes(keyword)
    })
  }

  const list = getFilterList()

  const content = list.map((it, i) => {
    return <Promotion key={i} doc={it} />
  })

  return (
    <PageView>
      <Meta title="Market - รายการโปรโมชั่น" description="รายการโปรโมชั่น" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        <Menu>
          <Tab menu={menu} selected={status} onChange={onStatus} />
        </Menu>
        <Filter filter={filter} onFilter={onFilter} />
        <Loading loading={loading}>{content}</Loading>

        <ErrorDialog error={err} onClose={onCloseError} />
        <SaveBtn text={{ save: 'สร้างโปรโมชั่น' }} onClick={onCreate} />
      </Content>
    </PageView>
  )
}

const menu = [
  { label: 'พร้อมใช้งาน', value: 'active' },
  { label: 'หมดอายุการใช้งาน', value: 'expired' },
]

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const Menu = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 15px;
`

export default inject('promotion')(observer(PromotionList))
