import styled from 'styled-components'

import { Marget } from 'icon'
import { Link } from 'components/link'
import { helper } from 'utils'

const Market = (props) => {
  const { doc = {} } = props

  const img_url = helper.getUrl(doc.img_list)
  const img = img_url ? (
    <MarketImg src={img_url} />
  ) : (
    <NoMarketImg>
      <Marget size={0.6} />
    </NoMarketImg>
  )
  return (
    <Link to={`/market/${doc.market_id}`}>
      <Card>
        {img}
        <Info>
          <Name>{doc.name}</Name>
          <Available>
            ล็อกว่าง
            <Circle>{doc.available || 0}</Circle>
          </Available>

          <NewBooking>
            <Pin /> {doc.booking || 0} รายการจองใหม่
          </NewBooking>
        </Info>
      </Card>
    </Link>
  )
}

const Card = styled.div`
  width: 100%;
  min-width: 288px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  column-gap: 16px;
`

const Circle = styled.span`
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.color.blue};
  border-radius: 16px;
  padding: 2px 9px;
  margin-left: 6px;
`

const MarketImg = styled.img`
  width: 90px;
  height: 80px;
  border-radius: 8px;
`

const NoMarketImg = styled.div`
  width: 90px;
  height: 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Info = styled.div`
  width: calc(100% - 110px);
`

const Name = styled.div`
  font-size: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Available = styled.div`
  width: 100%;
`

const NewBooking = styled.div`
  width: 100%;
  margin-top: 8px;
  color: ${(props) => props.theme.color.green};

  display: flex;
  align-items: center;
  column-gap: 10px;
`

const Pin = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.color.green};
  border-radius: 50%;
`

export default Market
