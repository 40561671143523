/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = 'white' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.248 19.872V19.008C13.248 18.624 13.056 18.336 12.768 18.144C12.48 17.952 12.096 18.048 11.808 18.24L9.31198 20.064C9.02398 20.256 8.92798 20.544 8.92798 20.832C8.92798 21.12 9.11998 21.408 9.31198 21.6L11.808 23.424C12.48 23.904 13.344 23.424 13.344 22.656V21.888C18.144 21.216 21.984 17.088 21.984 12.096C21.984 9.312 20.832 6.624 18.816 4.8C18.24 4.32 17.664 4.416 17.28 4.8C16.896 5.184 16.992 5.76 17.376 6.144C19.008 7.68 19.968 9.792 19.968 12C19.968 15.936 17.088 19.2 13.248 19.872Z"
        fill={color}
      />
      <path
        d="M12.3841 0.671997C12.0961 0.479997 11.7121 0.479997 11.3281 0.575997C11.0401 0.767997 10.8481 1.056 10.8481 1.44V2.208C5.95206 2.784 2.11206 6.91199 2.11206 12C2.11206 14.592 3.16806 17.088 4.99206 18.912C5.37606 19.296 5.95206 19.296 6.33606 18.912C6.72006 18.528 6.72006 17.952 6.33606 17.568C4.80006 16.032 4.03206 14.112 4.03206 12C4.03206 7.96799 7.00806 4.704 10.8481 4.128V4.99199C10.8481 5.37599 11.0401 5.66399 11.3281 5.85599C11.5201 5.95199 11.6161 5.95199 11.8081 5.95199C12.0001 5.95199 12.1921 5.85599 12.3841 5.75999L14.7841 3.936C15.0721 3.744 15.1681 3.456 15.1681 3.168C15.1681 2.88 15.0721 2.592 14.7841 2.4L12.3841 0.671997Z"
        fill={color}
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
