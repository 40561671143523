import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  setting: {
    status: 'active',
    open_type: 'month',
    next_value: 0.5,
    event_round: 1,
    event_weeks: 1,
    approve_type: 'auto',
  },
}
let state
export class MarketSetting extends BaseStore {
  constructor() {
    super()
    this.observable({
      announce: {},
      setting: cloneDeep(original.setting),
    })
    state = this
  }

  async getAnnounce(market_id) {
    const url = `${config.api}/v1/user/market/announce/${market_id}`
    const res = await http.get(url)

    const data = res.body || {}
    runInAction(() => {
      state.announce = data
    })
  }

  async updateAnnounce(market_id, json) {
    const url = `${config.api}/v1/user/market/announce/${market_id}`

    await http.put(url, { json })
  }

  setAnnounce(data) {
    runInAction(() => {
      state.announce = data
    })
  }

  async getSetting(market_id, force = false) {
    const { setting } = this.toJS()
    if (setting.market_id === +market_id && !force) {
      return
    }
    const url = `${config.api}/v1/user/market/setting/sale/${market_id}`
    const res = await http.get(url)

    const data = res.body || {}
    runInAction(() => {
      state.setting = data
    })
  }

  async updateSetting(market_id, json) {
    const url = `${config.api}/v1/user/market/setting/sale/${market_id}`

    await http.put(url, { json })
  }

  setSetting(data) {
    runInAction(() => {
      state.setting = data
    })
  }
}

export default new MarketSetting()
