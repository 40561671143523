import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Title, Content } from 'components/display'
import { PrimaryBtn } from 'components/button'
import { validator } from 'utils'
import { AccountInfo } from 'components/account'

import rules from './rules'
import Consent from './Consent'

const Register = (props) => {
  const [loading, setLoading] = useState(false)
  const [isConsent, setIsConsent] = useState(false)
  const [validate, setValidate] = useState({})
  const [err, setErr] = useState('')

  const navigate = useNavigate()

  const { user } = props.account.toJS()
  const { province_list } = props.province.toJS()

  const onInit = useCallback(async () => {
    try {
      await Promise.all([
        props.account.getUserProfile(),
        props.consent.getPrivacy(),
        props.consent.getTerms(),
        props.province.getProvinceList(),
      ])
    } catch (e) {
      console.log('e', e.message)
    }
  }, [props.consent, props.province, props.account])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/')
  const onSave = async () => {
    try {
      const checker = validator.process(rules, user)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setLoading(true)

      await props.account.updateProfile(user)

      setLoading(false)
      navigate('/welcome')
    } catch (e) {
      setErr(e.message)
      setLoading(false)
    }
  }

  const onChange = (val, name) => {
    user[name] = val
    delete validate[name]

    props.account.setProfile(user)
    setValidate({ ...validate })
  }

  return (
    <PageView>
      <Meta title="Market - สมัครสมาชิก" description="สมัครสมาชิก marget" />
      <BackHeader onClick={onBack} />

      <Content isHeader={true}>
        <Title text="สร้างบัญชีผู้ใช้" />
        <AccountInfo
          user={user}
          validate={validate}
          province_list={province_list}
          onChange={onChange}
        />

        <Consent isConsent={isConsent} onChange={(v) => setIsConsent(v)} />

        <Button>
          <PrimaryBtn
            text="ดำเนินการต่อ"
            loading={loading}
            disabled={!isConsent || validator.isError(validate)}
            onClick={onSave}
          />
        </Button>
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
  text-align: center;
`

const Button = styled.div`
  width: 220px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
`

export default inject('province', 'consent', 'account')(observer(Register))
