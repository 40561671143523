import styled from 'styled-components'

import { Input, Checker, SelectList } from 'components/input'

import Label from './Label'
import ContentView from './ContentView'
import Line from './Line'

const Information = (props) => {
  const {
    doc = {},
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props
  const open_list = doc.open_list || []

  const onCh = (val, name) => {
    doc[name] = val

    onChange(doc)

    delete validate[name]
    onValidate(validate)
  }

  const onOpenMarket = (isCheck, name, tag) => {
    if (isCheck) {
      open_list.push({ name: tag })
    } else {
      const i = open_list.findIndex((obj) => obj.name === tag)
      if (i > -1) {
        open_list.splice(i, 1)
      }
    }

    doc.open_list = open_list
    onChange(doc, validate)
  }

  const getOpenDayList = () => {
    const open_list = doc.open_list || []

    const content = day_list.map((it, i) => {
      const found = open_list.find((obj) => obj.name === it.value)
      return (
        <Line key={i}>
          <Checker
            label={it.name}
            value={!!found}
            tag={it.value}
            onChange={onOpenMarket}
          />
        </Line>
      )
    })

    return content
  }

  const content = doc.open_status === 'specific' ? getOpenDayList() : undefined

  return (
    <PageView>
      <Label text="ข้อมูลทั่วไป" />
      <ContentView>
        <Line>
          <Input
            label="ชื่อตลาด"
            name="name"
            value={doc.name}
            onChange={onCh}
            invalid={validate['name']}
          />
        </Line>

        <Line>
          <SelectList
            placeholder="วันเปิด-ปิดตลาด"
            menu={list}
            name="open_status"
            value={doc.open_status}
            onChange={onCh}
          />
        </Line>

        {content}
      </ContentView>
    </PageView>
  )
}

const list = [
  { name: 'ทุกวัน (จันทร์-อาทิตย์)', value: 'everyday' },
  { name: 'ระบุวัน', value: 'specific' },
]

const day_list = [
  { name: 'จันทร์', value: 'monday' },
  { name: 'อังคาร', value: 'tuesday' },
  { name: 'พุธ', value: 'wednesday' },
  { name: 'พฤหัสบดี', value: 'thursday' },
  { name: 'ศุกร์', value: 'friday' },
  { name: 'เสาร์', value: 'saturday' },
  { name: 'อาทิตย์', value: 'sunday' },
]

const PageView = styled.div`
  width: 100%;
`

export default Information
