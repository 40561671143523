import styled from 'styled-components'

import MarketList from './MarketList'
import OrderList from './OrderList'

const MyMarket = (props) => {
  return (
    <PageView>
      <MarketList />
      <Space />
      <OrderList />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Space = styled.div`
  height: 16px;
`

export default MyMarket
