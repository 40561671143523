import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { format, market } from 'utils'
import { Check } from 'icon'

import BookingType from './BookingType'

const Product = (props) => {
  const { isReadonly, product = {}, doc = {}, isLast = false } = props

  const { product_id } = product
  const { is_all } = doc
  const product_list = doc.product_list || []

  const time = market.toOpenTimeItem(product)
  const productType = market.toProductType(product, { ch: ' ' })
  const bookingType = market.getBookingType(product)

  const dot = isLast ? undefined : <Dot />
  const index = product_list.findIndex((it) => it.product_id === product_id)
  const isCheck = index > -1
  const color = is_all || isCheck ? undefined : 'white'

  const onCheck = () => {
    console.log('isCheck', isCheck)
    if (isCheck) {
      // remove from list
      product_list.splice(index, 1)
    } else {
      // add to list
      product_list.push({ product_id })
    }

    doc.product_list = product_list
    console.log('product_list:', doc.product_list)
    console.log('props:', props.promotion)
    props.promotion.setPromotion(doc)
  }

  return (
    <PageView>
      <ProductLine>
        <Checker onClick={isReadonly ? undefined : onCheck}>
          <Check color={color} />
        </Checker>
        <Detail>
          <Row align="middle">
            <Col span={16}>
              <Time>{time}</Time>
              <Info>{productType}</Info>
            </Col>
            <Col span={8}>
              <Price>{format.toDigi(product.price)}</Price>
              <BookingUnit>{bookingType}</BookingUnit>
            </Col>
          </Row>

          <BookingType booking_type={product.booking_type} />
        </Detail>
      </ProductLine>
      {dot}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const ProductLine = styled.div`
  width: 100%;
  display: flex;
`

const Checker = styled.div`
  width: 40px;
  padding-top: 8px;

  svg {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }
`

const Detail = styled.div`
  width: calc(100% - 40px);
`

const Time = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.color_level.grey.high};
  line-height: 1.2;
`

const Info = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  line-height: 1.2;
`

const Price = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.color.red};
  text-align: right;
  line-height: 1.2;
`

const BookingUnit = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color.red};
  text-align: right;
  line-height: 1.2;
`

const Dot = styled.div`
  width: 100%;
  border: 0px dashed ${(p) => p.theme.color_level.grey.low};
  border-bottom-width: 1px;
  margin: 16px 0px;
`

export default inject('promotion')(observer(Product))
