import styled from 'styled-components'

import { Marget, Back } from 'icon'

const BackHeader = (props) => {
  const { onClick, children, title, color } = props

  const backgroundColor = color
  const content = children ? <Component>{children}</Component> : undefined
  const titleContent = title ? <Title>{title}</Title> : <Marget size={0.8} />
  return (
    <PageView style={{ backgroundColor }}>
      <ContentBody>
        <ContentView>
          <Btn onClick={onClick}>
            <Back />
          </Btn>

          <Content>{titleContent}</Content>
        </ContentView>

        {content}
      </ContentBody>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 65px;
  padding: 10px 24px;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.color.backgroundColor};
`

const ContentBody = styled.div`
  width: 100%;
`

const ContentView = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 5px 5px 5px 0px;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Component = styled.div`
  width: 100%;
  margin-top: 16px;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
`

export default BackHeader
