import styled from 'styled-components'

import { Link } from 'components/link'
import { Marget } from 'icon'

const Information = () => {
  return (
    <PageView>
      <Link to="/">
        <Marget size={1.4} color="white" />
      </Link>
      <Info>
        เรามุ่งมั่นที่จะสร้างนวัตกรรมการจองพื้นที่ให้เป็นเรื่องง่าย
        สะดวกทั้งผู้ปล่อยเช่าและผู้มาเช่า
      </Info>
    </PageView>
  )
}

const PageView = styled.div`
  width: 90%;

  ${(p) => p.theme.media.tablet} {
    text-align: center;
    width: 100%;
    margin-top: 25px;
  }
`

const Info = styled.div`
  padding-top: 10px;
`

export default Information
