import { useState } from 'react'
import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

import Layout from './components/Layout'

const Floorplan = (props) => {
  const [position, setPosition] = useState({})
  const {
    visible = true,
    img_url,
    init = {},
    pin_list = [],
    onSelected,
    onSave = () => {},
    mode = 'create',
  } = props

  const onSavePosition = () => onSave(position)

  return (
    <PageContent>
      <FloorplanView>
        <Layout
          visible={visible}
          img_url={img_url}
          init={init}
          size={20}
          pin_list={pin_list}
          onSelected={onSelected}
          onPosition={(val) => setPosition(val)}
          viewMode={mode}
        />
      </FloorplanView>

      <BottomSection>
        <Button>
          <PrimaryBtn text="ตกลง" onClick={onSavePosition} />
        </Button>
      </BottomSection>
    </PageContent>
  )
}

const PageContent = styled.div`
  width: 100%;
`

const FloorplanView = styled.div`
  width: 100%;
  max-width: 800px;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.color.disable};

  ${(p) => p.theme.media.tablet} {
    width: 100%;
  }
`

const BottomSection = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 24px;
`

const Button = styled.div`
  width: 260px;
  margin: 0 auto;
`

export default Floorplan
