import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  interesting: validator.getRuleString('จุดเด่น/กลุ่มลูกค้า'),
  equipment: validator.getRuleString('อุปกรณ์ที่มีให้'),
}).options({ allowUnknown: true })

export default role
