import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Input, InputArea, SelectList } from 'components/input'

import Label from './Label'
import ContentView from './ContentView'

const MarketAddress = (props) => {
  const {
    doc = {},
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props

  const { province_list = [] } = props.province.toJS()

  const onCh = (val, name) => {
    doc[name] = val
    onChange(doc)

    delete validate[name]
    onValidate(validate)
  }

  const getProvince = (code) => {
    for (const province of province_list) {
      const postcode_list = province.tag.postcode_list || []

      for (const tag of postcode_list) {
        if (tag.postcode === code) {
          tag.province_name = province.value
          return tag
        }
      }
    }

    return null
  }

  const onPostcode = (postcode) => {
    doc.postcode = postcode

    if (postcode.length === 5) {
      const province = getProvince(postcode)
      if (province) {
        doc.province = province.province_name
        doc.district = province.district_name
        doc.sub_district = null
      }
    }

    onChange(doc)
  }

  const onProvince = (val) => {
    doc.province = val
    doc.district = null
    doc.sub_district = null
    doc.postcode = ''
    onChange(doc)
  }

  const onDistrict = (val) => {
    doc.district = val
    doc.sub_district = null
    doc.postcode = ''
    onChange(doc)
  }

  const onSubDistrict = (val, name, tag) => {
    doc.sub_district = val
    doc.postcode = tag ? tag.postcode || '' : ''
    onChange(doc)
  }

  const getRow = (c1, c2) => {
    return (
      <Row>
        <Col {...col} position="left-responsive">
          {c1}
        </Col>

        <Col {...col} position="right-responsive">
          {c2}
        </Col>
      </Row>
    )
  }

  const getDistrictList = () => {
    const province = province_list.find((it) => it.value === doc.province)

    let district_list = []
    let tumbon_list = []
    if (province) {
      const province_tag = province.tag || {}
      district_list = province_tag.district_list || []

      const district = district_list.find((it) => it.value === doc.district)

      if (district) {
        const district_tag = district.tag || {}
        tumbon_list = district_tag.tumbon_list || []
      }
    }
    return { district_list, tumbon_list }
  }

  const { district_list, tumbon_list } = getDistrictList()

  return (
    <PageView>
      <Label text="สถานที่ตั้ง" />
      <ContentView>
        <Address>
          <InputArea
            label="ที่อยู่"
            name="address"
            value={doc.address}
            onChange={onCh}
            invalid={validate['address']}
          />
        </Address>
        {getRow(
          <SelectList
            placeholder="จังหวัด"
            menu={province_list}
            name="province"
            value={doc.province}
            onChange={onProvince}
            filter={true}
            clear={false}
            invalid={validate['province']}
          />,
          <SelectList
            placeholder="เขต/อำเภอ"
            menu={district_list}
            name="district"
            value={doc.district}
            disabled={district_list.length === 0}
            onChange={onDistrict}
            filter={true}
            clear={false}
            invalid={validate['district']}
          />
        )}

        {getRow(
          <SelectList
            placeholder="แขวง/ตำบล"
            menu={tumbon_list}
            name="sub_district"
            value={doc.sub_district}
            disabled={tumbon_list.length === 0}
            onChange={onSubDistrict}
            clear={false}
          />,
          <Input
            label="รหัสไปรษณีย์"
            name="postcode"
            value={doc.postcode}
            onChange={onPostcode}
            isNumber={true}
          />
        )}

        {getRow(
          <Input
            label="ลิงค์แผนที่ตลาด"
            name="map_link"
            value={doc.map_link}
            onChange={onCh}
          />
        )}
      </ContentView>
    </PageView>
  )
}

const col = {
  lg: 12,
  md: 12,
  xs: 24,
}

const PageView = styled.div`
  width: 100%;
`

const Address = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export default inject('province')(observer(MarketAddress))
