import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'

import { HtmlContent } from 'components/display'
import { Nearby, Shopper, Equipment, Ban, Customer, Packing } from 'icon'

import { Label } from './components'

const MarketInfo = (props) => {
  const navigate = useNavigate()
  const { doc = {} } = props
  const {
    market_id,
    parking_status,
    parking_info,
    shopper,
    nearby,
    nearby_other,
    interesting,
    equipment,
    sale_condition,
  } = doc

  const getText = (text = '') => {
    return text ? `(${text})` : ''
  }

  const getNearby = () => {
    const nearby_list = doc.nearby_list || []
    if (nearby_other === 'yes') {
      nearby_list.push(nearby)
    }

    return nearby_list.join(' ,')
  }

  const display = (icon, text, title) => {
    return (
      <Line>
        <Row>
          <Col lg={1} md={2} xs={3}>
            {icon}
          </Col>
          <Col lg={23} md={22} xs={21}>
            <Title>{title}</Title>
            <Info>{text}</Info>
          </Col>
        </Row>
      </Line>
    )
  }

  const onEdit = () => navigate(`/market/${market_id}/editor-other`)

  const parking =
    parking_status === 'yes'
      ? 'มี ' + getText(parking_info)
      : 'ไม่มี ' + getText(parking_info)

  const shopper_content =
    shopper === '' ? undefined : display(<Shopper />, shopper, 'คนเดิน/วัน')

  return (
    <PageView>
      <Label text="ข้อมูลอื่นๆ" onEdit={onEdit} />
      {display(<Nearby />, getNearby(), 'แหล่งใกล้เคียง')}
      {shopper_content}
      {display(<Customer />, interesting, 'จุดเด่น,กลุ่มลูกค้า')}
      {display(<Packing />, parking, 'ที่จอดรถ')}
      {display(<Equipment />, equipment, 'อุปกรณ์ที่มีให้')}
      {display(<Ban />, sale_condition, 'เงื่อนไข ข้อห้าม')}

      <Title>รายละเอียดเพิ่มเติม</Title>
      <HtmlContent content={doc.detail} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 25px 0px;
`

const Line = styled.div`
  font-size: 18px;
  margin-bottom: 22px;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Title = styled.div`
  font-size: 18px;
  color: ${(p) => p.theme.color_level.grey.high};
  font-weight: bold;
  line-height: 1.4;
`

const Info = styled.div`
  line-height: 1.4;
`

export default MarketInfo
