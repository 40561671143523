import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import member from './Member'
import BaseStore from './BaseStore'

const original = {
  email: '',
  name: '',
  surname: '',
}

let state
export class Register extends BaseStore {
  constructor() {
    super()
    this.observable({
      user: cloneDeep(original),
    })

    state = this
  }

  async getUserProfile() {
    const url = `${config.api}/v1/user/profile`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.user = data
    })
  }

  async updateProfile(json = {}) {
    const url = `${config.api}/v1/user/profile`

    const res = await http.put(url, { json })
    const user = res.body || {}

    member.setProfile(user)
    runInAction(() => {
      state.user = user
    })
  }

  setProfile(doc) {
    runInAction(() => {
      state.user = doc
    })
  }
}

export default new Register()
