import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(10 * size)}`
  const height = `${Math.ceil(18 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.06741 18V9.78996H8.82207L9.23536 6.58941H6.06741V4.54632C6.06741 3.61998 6.32359 2.98869 7.65347 2.98869L9.34686 2.98799V0.125307C9.05401 0.0872508 8.04877 0 6.87877 0C4.43564 0 2.76302 1.49127 2.76302 4.22934V6.58941H0V9.78996H2.76302V18H6.06741Z"
        fill="white"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
