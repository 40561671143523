import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Meta, BackHeader, Content } from 'components/display'
import {
  MarketStep,
  MarketGeneral,
  MarketInfo,
  MarketBanking,
} from 'components/market'
import { PrimaryBtn, Btn } from 'components/button'

const NewMarket = (props) => {
  const [index, setIndex] = useState(1)
  const navigate = useNavigate()
  const onBack = () => {
    if (index === 1) {
      navigate('/dashboard')
    } else {
      setIndex(index - 1)
    }
  }

  const onNext = () => {
    setIndex(index === 3 ? 1 : index + 1)
  }

  let page
  let back = <Btn text="ย้อนกลับ" onClick={onBack} />
  switch (index) {
    case 2:
      page = <MarketInfo />
      break
    case 3:
      page = <MarketBanking />
      break
    default:
      page = <MarketGeneral />
      back = undefined
  }
  return (
    <PageView>
      <Meta title="Market - สร้างตลาดใหม่" description="สร้างตลาดใหม่" />
      <BackHeader onClick={onBack} />
      <Content isHeader={true}>
        <PageContent>
          <MarketStep active={index} />
          <Space />

          {page}

          <Button>
            <PrimaryBtn text="ถัดไป" onClick={onNext} disabled={true} />
            {back}
          </Button>
        </PageContent>
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 80vh;
`

const PageContent = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

const Space = styled.div`
  width: 100%;
  padding-top: 20px;
`

const Button = styled.div`
  width: 215px;
  margin: 0 auto;
  padding: 16px 0px;
  button {
    margin: 8px 0px;
  }
`

export default NewMarket
