import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.69178 5.21177C9.44081 1.46274 15.5192 1.46274 19.2682 5.21177C23.0173 8.9608 23.0173 15.0392 19.2682 18.7882C15.5192 22.5372 9.44081 22.5372 5.69178 18.7882C1.94275 15.0392 1.94275 8.9608 5.69178 5.21177ZM18.548 7.29107L7.77108 18.068C10.7839 20.4121 15.1415 20.1997 17.9106 17.4306C20.6797 14.6615 20.8921 10.3039 18.548 7.29107ZM17.1889 5.93197C14.1761 3.58785 9.81851 3.80033 7.04942 6.56941C4.28034 9.3385 4.06786 13.696 6.41198 16.7089L17.1889 5.93197Z"
        fill="#494949"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
