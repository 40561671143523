import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Card, Dot } from 'components/display'
import UnitDrawer from 'pages/unitList/drawer'
import { timer } from 'utils'

import Header from './Header'
import MarketName from './MarketName'
import Product from './Product'
import Contact from './Contact'
import Total from './Total'
import Note from './Note'
import Invoice from './Invoice'
import Receive from './Receive'
import QrCodeBot from './QrCodeBot'

const OrderInfo = (props) => {
  const [visible, setVisible] = useState(false)

  const { isReceive, onOffer } = props

  const { doc } = props.order.toJS()
  const { status, market_id, market_name, total, offer_total, start_at } = doc

  const onAvailable = () => setVisible(true)
  const onClose = () => setVisible(false)

  const list = status === 'offer' ? doc.offer_list : doc.list || []
  const price_total = status === 'offer' ? offer_total : total
  const last = list.length - 1
  const content = list.map((it, i) => {
    return <Product key={i} item={it} isDot={i !== last} />
  })

  const receive = isReceive ? (
    <>
      <Receive order={doc} />
      <Dot />
    </>
  ) : undefined

  return (
    <PageView>
      <Header order={doc} />

      <Card>
        <MarketName
          market_name={market_name}
          status={status}
          onOffer={onOffer}
        />
        {content}
        <Available onClick={onAvailable}>ดูสถานะล็อกทั้งหมด</Available>
        <Dot />
        <Contact order={doc} />
        <Dot />
        <Note order={doc} />
        <Dot />
        <Invoice order={doc} />
        <Dot />
        {receive}
        <Total total={price_total} />
      </Card>

      <Space />
      <Card>
        <QrCodeBot />
      </Card>

      <UnitDrawer
        visible={visible}
        market_id={market_id}
        init={timer.get(start_at)}
        onClose={onClose}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Available = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};
  text-align: right;
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: underline;
`

const Space = styled.div`
  padding: 8px 0px;
`

export default inject('order')(observer(OrderInfo))
