import { inject, observer } from 'mobx-react'
import { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorDialog } from 'dialog'
import Loading from 'components/loading'
import {
  Meta,
  BackHeader,
  Content,
  Title,
  HtmlContent,
} from 'components/display'

const Consent = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const navigate = useNavigate()
  const { typ } = useParams()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        props.consent.getMyPrivacy(),
        props.consent.getCookie(),
        props.consent.getMyTerms(),
      ])
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.consent])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onCloseError = () => setErr('')
  const onBack = () => navigate('/account')

  const { my_privacy, my_terms, cookie } = props.consent.toJS()

  let title = ''
  let header = ''
  let content = ''
  switch (typ) {
    case 'privacy':
      title = 'นโยบายความเป็นส่วนตัว'
      header = my_privacy.title
      content = my_privacy.content
      break
    case 'terms':
      title = 'เงื่อนไขการให้บริการ'
      header = my_terms.title
      content = my_terms.content
      break
    default:
      title = 'Cookie policy'
      header = cookie.title
      content = cookie.content
  }

  return (
    <PageView>
      <Meta title={title} description={'รายการ consent ของผู้ใช้งาน'} />
      <BackHeader onClick={onBack} />
      <Content isHeader={true}>
        <Title text={header} />
        <Loading loading={loading}>
          <HtmlContent content={content} />
        </Loading>
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  min-height: 85vh;
  margin: 0 auto;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
`

export default inject('consent')(observer(Consent))
