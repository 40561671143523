import ReactLoading from 'react-loading'
import styled from 'styled-components'

const Loading = (props) => {
  const { loading = false, size = 1, top = '60px', children } = props

  if (loading === false) return children || <div />

  const width = size * 160
  const height = size * 200
  return (
    <Display style={{ marginTop: top }}>
      <ReactLoading
        type={'spinningBubbles'}
        color={'#305FED'}
        height={height}
        width={width}
      />
    </Display>
  )
}

const Display = styled.div`
  div {
    margin: 0 auto;
  }
`

export default Loading
