import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { BaseDrawer } from 'drawer'
import { Checker } from 'components/input'
import { PrimaryBtn } from 'components/button'

const Setting = (props) => {
  const [data, setData] = useState({})

  const { visible, setting = {}, onClose, onSave = () => {} } = props

  useEffect(() => {
    if (visible) {
      setData(setting || {})
    }
  }, [visible, setting])

  const onChecker = (val, name) => {
    data[name] = val ? 'yes' : 'no'
    setData({ ...data })
  }

  const onClick = () => onSave(data)

  const content = menu.map((it, i) => {
    const { name, value } = it
    const isCheck = data[value] === 'yes'
    return (
      <Line key={i}>
        <Checker
          label={name}
          name={value}
          value={isCheck}
          onChange={onChecker}
        />
      </Line>
    )
  })

  return (
    <BaseDrawer
      visible={visible}
      title="ตั้งค่าเสนอใหม่"
      height="400px"
      onClose={onClose}
    >
      <PageView>
        {content}

        <Button>
          <PrimaryBtn text="ตกลง" onClick={onClick} />
        </Button>
      </PageView>
    </BaseDrawer>
  )
}

const menu = [{ name: 'ต้องเลือกรายการทั้งหมด', value: 'force_selected' }]

const PageView = styled.div`
  width: 100%;
`

const Line = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Button = styled.div`
  width: 100%;
  max-width: 214px;
  margin: 0 auto;
  padding-top: 16px;
`

export default Setting
