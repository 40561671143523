import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'

const SaveBtn = (props) => {
  const { loading, onClick = () => {}, deleting, onDelete } = props

  if (onDelete) {
    return (
      <PageView>
        <BothButton>
          <Row>
            <Col span={12} position="left">
              <Btn loading={deleting} text={'ลบรอบนี้'} onClick={onDelete} />
            </Col>

            <Col span={12} position="right">
              <PrimaryBtn loading={loading} text={'บันทึก'} onClick={onClick} />
            </Col>
          </Row>
        </BothButton>
      </PageView>
    )
  }

  return (
    <PageView>
      <Button>
        <PrimaryBtn loading={loading} text="บันทึก" onClick={onClick} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: white;
  padding: 20px 0px;
  z-index: 1;

  ${(p) => p.theme.media.mobile} {
    padding: 20px;
  }
`

const Button = styled.div`
  width: 214px;
  margin: 0 auto;
`

const BothButton = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`

export default SaveBtn
