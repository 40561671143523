import styled from 'styled-components'

import { Line } from 'components/display'
import { FindInput } from 'components/input'
import { Filter } from 'icon'

import Daily from './Daily'
import Monthly from './Monthly'
import Round from './Round'

const FilterSection = (props) => {
  const {
    find,
    filter = {},
    bookingType,
    roundMenu,
    onFilter = () => {},
    onFind = () => {},
    onSetting,
  } = props

  const getChoice = () => {
    switch (bookingType) {
      case 'daily':
        return <Daily filter={filter} onFilter={onFilter} />
      case 'round':
        return (
          <Round filter={filter} roundMenu={roundMenu} onFilter={onFilter} />
        )
      default:
        return <Monthly filter={filter} onFilter={onFilter} />
    }
  }

  const booking = tag[bookingType] || tag.daily
  return (
    <PageView>
      <Title>
        <RoundText style={{ backgroundColor: booking.color }}>
          {booking.text}
        </RoundText>
      </Title>
      <Line>{getChoice()}</Line>

      <FindLine>
        <Find>
          <FindInput
            placeholder="ค้นหาหมายเลขล็อก"
            value={find}
            onChange={onFind}
          />
        </Find>
        <Btn onClick={onSetting}>
          <Filter />
        </Btn>
      </FindLine>
    </PageView>
  )
}

const tag = {
  daily: { text: 'รายวัน', color: '#F55759' },
  monthly: { text: 'รายเดือน', color: '#577AF5' },
  round: { text: 'บูธอีเวนท์', color: '#00C767' },
}

const PageView = styled.div`
  width: 100%;
  padding-top: 8px;
`

const Title = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const RoundText = styled.span`
  font-size: 14px;
  color: white;
  background-color: #f55759;
  border-radius: 4px;
  padding: 2px 8px;
`

const FindLine = styled.div`
  width: 100%;
  display: flex;
`

const Find = styled.div`
  width: calc(100% - 50px);
`

const Btn = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default FilterSection
