import React from 'react'
import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

const Button = (props) => {
  const { loading, text, onClick } = props

  return (
    <ButtonSection>
      <Center>
        <PrimaryBtn loading={loading} text={text} onClick={onClick} />
      </Center>
    </ButtonSection>
  )
}
/*
const ButtonSection = styled.div`
  width: calc(100% - 48px);
  max-width: 1396px;
  position: absolute;
  bottom: 20%;
  ${(p) => p.theme.media.mobile} {
    bottom: 18%;
  }
`
*/
const ButtonSection = styled.div`
  width: 100%;
  padding-top: 24px;
  ${(p) => p.theme.media.mobile} {
    bottom: 18%;
  }
`

const Center = styled.div`
  width: 213px;
  margin: 0 auto;
`

export default Button
