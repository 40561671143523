import { useState } from 'react'
import styled from 'styled-components'

import { ImgDialog } from 'dialog'

import Title from './Title'

const Receive = (props) => {
  const [visible, setVisible] = useState(false)

  const { order = {} } = props
  const { payment_url } = order

  const onClose = () => setVisible(false)
  const onOpen = () => setVisible(true)

  return (
    <PageView>
      <Title text="ใบเสร็จการโอนเงิน" />
      <Image src={payment_url} onClick={onOpen} />
      <ImgDialog visible={visible} img={payment_url} onClose={onClose} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px 0px 10px 0px;
`

const Image = styled.img`
  width: auto;
  height: 100px;
`

export default Receive
