import Swal from 'sweetalert2'

const timer = 2000
export class Message {
  async error({ message = 'ขออภัยพบปัญหาบ้างอย่าง' } = {}) {}

  async warning(title) {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title,
      showConfirmButton: false,
      timer,
    })
  }

  async success(title = 'บันทึกสำเร็จ') {
    Swal.fire({
      customClass: 'success-popup',
      position: 'center',
      icon: 'success',
      title,
      showConfirmButton: false,
      timer,
    })
  }

  confirm({ title }, onOk = () => {}) {}
}

export const message = new Message()
export default message
