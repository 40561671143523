import styled from 'styled-components'

import {
  Information,
  OpenTimeList,
  MarketType,
  BookingType,
  MarketAddress,
  MarketImage,
  FloorplanImg,
  Space,
} from 'components/market/components'

const General = (props) => {
  const {
    doc,
    validate = {},
    onChange = () => {},
    onValidate = () => {},
  } = props
  return (
    <PageContent>
      <Information
        doc={doc}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate}
      />
      <Space />

      <OpenTimeList doc={doc} validate={validate} onChange={onChange} />
      <Space />

      <MarketType
        doc={doc}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate}
      />
      <Space />

      <BookingType
        doc={doc}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate}
      />
      <Space />

      <MarketAddress
        doc={doc}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate}
      />
      <Space />

      <FloorplanImg doc={doc} validate={validate} onChange={onChange} />
      <Space />

      <MarketImage doc={doc} validate={validate} onChange={onChange} />
    </PageContent>
  )
}

const PageContent = styled.div`
  width: 100%;
`

export default General
