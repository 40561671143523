import styled from 'styled-components'

import Label from './Label'
import ContentView from './ContentView'

const InputLine = (props) => {
  const { label, children } = props

  return (
    <PageView>
      <Label text={label} />
      <ContentView>{children}</ContentView>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

export default InputLine
