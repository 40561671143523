import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import Label from './Label'
import Card from './Card'

const NoMarket = (props) => {
  const { user } = props.member.toJS()
  return (
    <PageView>
      <Title>ยินดีต้อนรับ</Title>
      <Name>{user.name || ''}</Name>

      <Label text="เริ่มสร้างตลาด" />
      <Card title="สร้างตลาดใหม่" detail="Coming Soon" link="/new-market" />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 0 24px;
`

const Title = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 18px;
`

const Name = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
`

/*
<Label text="สร้างหน้าตลาดต่อให้เสร็จ" />
<Card
  title="เริ่มสร้างหน้าตลาดไว้เมื่อ"
  detail="8 ม.ค. 2565"
  link="/account/market/1"
/>
<Space />
*/
export default inject('member')(observer(NoMarket))
