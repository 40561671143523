import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, error } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
  axis_x: 0,
  axis_y: 0,

  be_tall: '',
  be_wide: '',
  be_long: '',
  product_list: [],
}

let state
export class Unit extends BaseStore {
  constructor() {
    super()
    this.observable({
      have_unit: false,
      floorplan_id: undefined,
      unit_list: [],
      unit: cloneDeep(original),
      product_list: [],
    })
    state = this
  }

  async getUnitList(query = {}) {
    const { floorplan_id } = query
    const store = this.toJS()
    // console.log('get unit list:', store.floorplan_id, floorplan_id)
    if (store.floorplan_id === floorplan_id) {
      return
    }

    this.setUnitList(floorplan_id, [])

    if (!floorplan_id) {
      return
    }

    const url = `${config.api}/v1/user/market/unit/${floorplan_id}/floorplan`
    const res = await http.get(url)

    const { unit_list } = res.body || {}
    this.setUnitList(floorplan_id, unit_list)
  }

  setUnitList(floorplan_id, list) {
    // console.log('set unit list:', floorplan_id, list)
    runInAction(() => {
      state.floorplan_id = floorplan_id
      state.unit_list = list || []
    })
  }

  async checkFirstUnit(query = {}) {
    const { market_id } = query
    const url = `${config.api}/v1/user/market/unit/${market_id}/market`
    const res = await http.get(url)

    const { unit_list = [] } = res.body || {}
    runInAction(() => {
      state.have_unit = unit_list.length > 0
    })
  }

  async getUnitInfo(query = {}) {
    const { unit_id } = query
    if (!unit_id) {
      runInAction(() => {
        state.unit = cloneDeep(original)
        state.product_list = []
      })

      return
    }

    const doc = this.toJS().unit
    if (doc.unit_id === +unit_id && doc.isEdit !== true) {
      return
    }

    const url = `${config.api}/v1/user/market/unit/${unit_id}`
    const res = await http.get(url)

    const data = res.body || {}
    const { unit, product_list } = data
    runInAction(() => {
      state.unit = unit || cloneDeep(original)
      state.product_list = product_list || []
    })
  }

  async updateUnit({ market_id, floorplan_id, unit_id }, json) {
    if (unit_id) {
      const url = `${config.api}/v1/user/market/unit/${unit_id}`
      await http.put(url, { json })
    } else {
      const url = `${config.api}/v1/user/market/unit/${market_id}/${floorplan_id}`
      const res = await http.post(url, { json })
      json = res.body
    }

    runInAction(() => {
      state.unit = json
    })

    return json
  }

  async deleteUnit({ unit_id }) {
    const url = `${config.api}/v1/user/market/unit/${unit_id}/delete`
    await http.put(url, {})

    const unit_list = this.toJS().unit_list
    const i = unit_list.findIndex((it) => it.unit_id === unit_id)
    if (i !== -1) {
      unit_list.splice(i, 1)
    }

    runInAction(() => {
      state.unit_list = unit_list
      state.unit = cloneDeep(original)
      state.product_list = []
    })
  }

  async cloneUnit({ unit_id }, json) {
    const url = `${config.api}/v1/user/market/unit/${unit_id}/clone`
    const res = await http.put(url, { json })

    const data = res.body
    const { unit, product_list } = data
    runInAction(() => {
      state.unit = unit || cloneDeep(original)
      state.product_list = product_list || []
    })

    return unit
  }

  async updateOrderUnit({ index_a, index_b }) {
    const list = this.toJS().unit_list
    const unit_a = { ...list[index_a - 1] }
    const unit_b = { ...list[index_b - 1] }

    unit_a.seq = index_b
    unit_b.seq = index_a
    list[index_a - 1] = unit_b
    list[index_b - 1] = unit_a

    const len = list.length
    let allUpdate = false
    for (let i = 0; i < len; i++) {
      const index = i + 1
      if (list[i].seq !== index) {
        allUpdate = true
      }

      list[i].seq = index
    }

    let unit_list = []
    if (allUpdate) {
      unit_list = list.map((it) => {
        const { unit_id, seq } = it
        return {
          unit_id,
          seq,
        }
      })
    } else {
      unit_list.push({ unit_id: unit_a.unit_id, seq: unit_a.seq })
      unit_list.push({ unit_id: unit_b.unit_id, seq: unit_b.seq })
    }

    const url = `${config.api}/v1/user/market/unit/update/order`
    await http.put(url, { json: { unit_list } })

    runInAction(() => {
      state.unit_list = list
    })
  }

  setUnit(data) {
    data.isEdit = true
    runInAction(() => {
      state.unit = data
    })
  }

  async updateProduct({ market_id, unit_id, product_id }, json) {
    const product_list = this.toJS().product_list || []

    const { market_open_id, booking_type } = json
    const index = product_list.findIndex((it) => {
      return (
        it.booking_type === booking_type &&
        it.market_open_id === market_open_id &&
        it.product_id !== product_id
      )
    })

    error.isError(index !== -1, 'รอบเวลาและประเภทการเช่ามีอยู่แล้ว')

    if (product_id) {
      const url = `${config.api}/v1/user/market/product/${product_id}`
      const res = await http.put(url, { json })
      const product = res.body
      const i = product_list.findIndex((it) => it.product_id === product_id)

      if (i > -1) {
        product_list[i] = product
      }
    } else {
      const url = `${config.api}/v1/user/market/product/${market_id}/${unit_id}`
      const res = await http.post(url, { json })
      const product = res.body

      product_list.splice(0, 0, product)
    }

    runInAction(() => {
      state.product_list = product_list
    })
  }

  async deleteProduct({ product_id }) {
    const product_list = this.toJS().product_list || []

    const url = `${config.api}/v1/user/market/product/${product_id}/delete`
    await http.put(url, {})

    const i = product_list.findIndex((it) => it.product_id === product_id)

    if (i > -1) {
      product_list.splice(i, 1)
      runInAction(() => {
        state.product_list = product_list
      })
    }
  }
}

export default new Unit()
