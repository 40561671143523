import styled from 'styled-components'

import { DropdownList } from 'components/input'
import { RangeDatePicker } from 'components/date'

const Filter = (props) => {
  const {
    filter = {},
    bookingType,
    bookingMenu,
    disabled,
    onBookingType,
    onFilter = () => {},
  } = props

  const toDate = (val) => {
    return val ? val.startOf('day') : val
  }
  const onDate = (val) => {
    filter.start_at = toDate(val.start_at)
    filter.finish_at = toDate(val.finish_at)

    onFilter(filter)
  }

  const { start_at, finish_at, limit = {} } = filter
  return (
    <PageView>
      <RangeDatePicker
        placeholder="วันที่เปิดขาย"
        start_at={start_at}
        finish_at={finish_at}
        onChange={onDate}
        disabled={start_at === undefined}
        min={limit.min}
        max={limit.max}
        days_list={limit.days_list}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default Filter
