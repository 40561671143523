import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ErrorDialog } from 'dialog'
import { Meta, BackHeader, Content } from 'components/display'
import { FindInput } from 'components/input'

import NoMarket from './NoMarket'
import Card from './Card'

const MarketList = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState('')
  const [filter, setFilter] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  const { market_list = [] } = props.market.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await props.market.getMyMarketList()
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }, [props.market])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onCloseError = () => setErr('')
  const onBack = () => {
    const { source } = queryString.parse(location.search)
    const path = source ? source : '/dashboard'
    navigate(path)
  }
  const onFilter = (val) => setFilter(val)

  const getFilterList = () => {
    if (filter === '') {
      return market_list
    }

    return market_list.filter((it) => {
      const name = it.name || ''
      return name.includes(filter)
    })
  }

  const getMarketList = () => {
    const list = getFilterList()

    const content = list.map((it, i) => {
      return <Card key={i} item={it} />
    })

    return content
  }

  const len = market_list.length
  const content = len === 0 ? <NoMarket /> : getMarketList()
  const find =
    len >= 5 ? (
      <Filter>
        <FindInput placeholder="ค้นหาตลาด" value={filter} onChange={onFilter} />
      </Filter>
    ) : undefined
  return (
    <PageView>
      <Meta title="Market - ตลาดทั้งหมด" description="ตลาดทั้งหมด" />

      <BackHeader onClick={onBack} />
      <Content loading={loading} isHeader={true}>
        {find}
        {content}
      </Content>
      <ErrorDialog error={err} onClose={onCloseError} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  min-height: 90vh;
`

const Filter = styled.div`
  margin-bottom: 24px;
`

export default inject('market')(observer(MarketList))
