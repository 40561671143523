import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Marget } from 'icon'

const Header = (props) => {
  const user = props.member.toJS().user || {}
  const { name, surname } = user
  const text = `${name || ''} ${surname || ''}`
  return (
    <PageView>
      <Marget />
      <Name>{text}</Name>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
`
/*
const Image = styled.img`
  width: 98px;
  height: 98px;
  border-radius: 50%;
  object-fit: cover;
  display: none;
`
*/

const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  color: ${(p) => p.theme.color_level.grey.high};
`

export default inject('member')(observer(Header))
