import Joi from 'joi'

import { validator } from 'utils'

const setRulePrice = (obj, name, condition = true) => {
  if (condition) {
    obj[name] = validator.getRuleNumber('ราคา')
  }
}

export const getRule = (options = {}) => {
  const {
    price_daily = false,
    price_monthly = false,
    price_round = false,
    deposit = false,
    product_type = false,
  } = options

  const obj = {
    market_open_id: validator.getRuleNumber('รอบเวลา'),
  }

  if (product_type) {
    obj.type_food = validator.getRuleLimitNumber('ประเภทสินค้า', 1, 1)
  }
  if (price_daily === 'specific') {
    setRulePrice(obj, 'price_monday', true)
    setRulePrice(obj, 'price_tuesday', true)
    setRulePrice(obj, 'price_wednesday', true)
    setRulePrice(obj, 'price_thursday', true)
    setRulePrice(obj, 'price_friday', true)
    setRulePrice(obj, 'price_saturday', true)
    setRulePrice(obj, 'price_sunday', true)
  } else if (price_daily === 'none') {
    setRulePrice(obj, 'price_daily', true)
  }

  setRulePrice(obj, 'price_monthly', price_monthly)
  setRulePrice(obj, 'price_round', price_round)
  setRulePrice(obj, 'deposit', deposit)

  const rule = Joi.object(obj).options({ allowUnknown: true })

  return rule
}
