import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

import BaseDialog from './BaseDialog'

const MsgDialog = (props) => {
  const { message = '', message_list = [], onClose } = props

  const content = message_list.map((m, i) => {
    return <Detail key={i}>{m}</Detail>
  })

  return (
    <BaseDialog isHeader={false} visible={message !== ''} onClose={onClose}>
      <Content>
        <Body>
          <Title>{message}</Title>
          {content}
        </Body>
        <PrimaryBtn text="ตกลง" onClick={onClose} />
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  max-width: 330px;
  padding: 20px;
`

const Body = styled.div`
  width: 90%;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 24px;
`

const Title = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`

const Detail = styled.div`
  color: ${(p) => p.theme.color_level.grey.high};
  font-size: 18px;
`

export default MsgDialog
