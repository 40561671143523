import { useState } from 'react'
import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Checker } from 'components/input'
import Loading from 'components/loading'
import { market } from 'utils'
import { BookingTypeName } from 'components/market/components'

import { col } from './col'

const Product = (props) => {
  const [loading, setLoading] = useState(false)
  const { doc = {}, onChange } = props

  const openTime = market.toOpenTimeItem(doc)
  const productType = market.toProductType(doc)
  const isCheck = doc.status === 'active'

  const onCheck = async () => {
    try {
      setLoading(true)
      await onChange(!isCheck, doc)
    } catch (e) {
      console.log('e', e.message)
    }

    setLoading(false)
  }

  return (
    <PageView>
      <Row>
        <Col {...col.checked}>
          <Loading loading={loading}>
            <Checker value={isCheck} onChange={onCheck} />
          </Loading>
        </Col>
        <Col {...col.name} position="center">
          <Name>{doc.unit_name}</Name>
        </Col>
        <Col {...col.detail} position="center">
          <Time>{openTime}</Time>
          <Detail>{productType}</Detail>
        </Col>
        <Col {...col.type} position="right">
          <BookingTypeName booking_type={doc.booking_type} />
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Name = styled.div`
  color: white;
  background-color: ${(props) => props.theme.color.green};
  font-size: 14px;
  border-radius: 12px;
  padding: 1px 8px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
`

const Time = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  font-weight: bold;
`

const Detail = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
`

export default Product
