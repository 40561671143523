import styled from 'styled-components'

import { Link } from 'components/link'
import { Next } from 'icon'

const Menu = (props) => {
  const { title = '', menu = [] } = props

  const header = title !== '' ? <Title>{title}</Title> : undefined
  const content = menu.map((it, i) => {
    const { link, label, disable } = it
    const borderTopWidth = i !== 0 ? '1px' : undefined
    const css = { borderTopWidth }
    if (disable) {
      return (
        <Line style={css} key={i}>
          <DisableLine>
            {label}
            <span>(เร็ว ๆ นี้)</span>
          </DisableLine>
          <Next color="#BBBBBB" />
        </Line>
      )
    } else {
      return (
        <Link to={link} key={i}>
          <Line style={css}>
            {label}
            <Next />
          </Line>
        </Link>
      )
    }
  })

  return (
    <PageView>
      {header}
      <Body>{content}</Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  color: ${(p) => p.theme.color_level.grey.high};
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${(p) => p.theme.color_level.grey.high};
  margin-bottom: 17px;
`

const Body = styled.div`
  margin-bottom: 32px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 4px 20px;
`

const Line = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border: 0px dashed #bbbbbb;

  svg {
    margin-left: auto;
    order: 2;
  }
`

const DisableLine = styled.label`
  color: ${(p) => p.theme.color_level.grey.low};

  span {
    margin-left: 6px;
  }
`

export default Menu
