import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { Row, Col, Carousel } from 'components/display'
import { Link } from 'components/link'
import { Next } from 'icon'

import Market from './Market'

const MarketList = (props) => {
  const { dashboard } = props.market.toJS()
  const market_list = dashboard.list || []
  const len = market_list.length
  const isOneMarket = len === 1

  const getOneMarket = () => {
    const market = len > 0 ? market_list[0] : {}
    return (
      <FullCard>
        <Market doc={market} isFull={true} />
      </FullCard>
    )
  }

  const getMarketList = () => {
    const list = market_list.map((it, i) => {
      return (
        <Card key={i}>
          <Market doc={it} />
        </Card>
      )
    })

    const btn = dashboard.isMore ? (
      <Card>
        <NextBtn>
          <Link to="/market">
            <Next />
          </Link>
        </NextBtn>
      </Card>
    ) : undefined

    return (
      <Carousel>
        {list}

        {btn}
      </Carousel>
    )
  }

  const content = isOneMarket ? getOneMarket() : getMarketList()

  return (
    <>
      <PageView>
        <Row align="middle">
          <Col span={16}>
            <Title>
              ตลาดของฉัน
              <Total>{dashboard.total || 0}</Total>
            </Title>
          </Col>
          <Col span={8}>
            <LinkAll>
              <Link to="/market">ดูทั้งหมด</Link>
            </LinkAll>
          </Col>
        </Row>
      </PageView>
      {content}
    </>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 0px 24px;
`

const Title = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 6px;
`

const LinkAll = styled.div`
  color: ${(props) => props.theme.color_level.grey.high};
  font-size: 14px;
  text-align: right;
  text-decoration: underline;
`

const Total = styled.div`
  width: 32px;
  height: 32px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  background-color: ${(props) => props.theme.color.blue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FullCard = styled.div`
  width: 100%;
  padding: 0px 24px;
`

const Card = styled.div`
  width: 288px;
`

const NextBtn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 8px 2px 8px 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
`

export default inject('market')(observer(MarketList))
